@import 'theme/animate.css';
@import 'theme/animated-text.css';
@import 'theme/meanmenu.min.css';
@import 'theme/nivo-slider.css';
@import 'theme/owl.carousel.min.css';
@import 'theme/owl.transitions.css';
@import 'theme/responsive.css';
@import 'theme/settings.css';
@import 'theme/theme-default.css';
@import 'theme/widget.css';


:root {
    --primary: #005884;
    --secondary: #f7941d;
}

/*===============================================

Template Name: techno - IT Solutions & Services HTML5 Template
Version: 0.1

================================================*/

/*TABLE OF CONTENTS*/
/*=====================
01. techno	Main Menu Area Css
02. techno Slider Area Css
03. techno Shape Images Css
04. techno Breatcome Area Css
05. techno Feature Area Css
06. techno Service Area Css
07. techno Section TitleNAV
08. techno About  Area  Css
09. techno Counter Area  Css
10. techno Feature Box Area  Css
11. techno Team Area  Css
12. techno Pricing Area  Css
13. techno Portfolio Area  Css
14. techno Testimonial Area  Css
15. techno Call Do Action Area  Css
16. techno Blog Area  Css
17. techno Subscribe Area CSS
18. techno Subscribe Area CSS
19. techno Contact Now Area Css
20. techno Contact Form Area Css
21. techno GOOGLE MAP AREA CSS
22. techno Middle Footer CSS
23. techno Footer Bottom Area Css
24. techno BLOG DETAILS AREA CSS
25. techno Team Details Area Css
26. techno Skill Area Css
27. techno Error Page Area Css
25. techno Team Details Area Css

=======================*/
/*logo*/
.sticky img.down {
    display: none;
}
img.main_sticky {
    display: none !important;
}
.sticky img.main_sticky {
    display: inherit !important;
}
/*logo end*/
.menu {
    width: 100%;
}
.menu a.logo {
    display: inline-block;
    padding: 25px 0 0;
    float: left;
    position: relative;
    z-index: 1;
}
.menu img {
    max-width: 100%;
}
.menu-mobile {
    display: none;
    padding: 20px;
}

.menu > ul {
    list-style: none;
    position: relative;
}
.menu > ul:before,
.menu > ul:after {
    content: "";
    display: table;
}
.menu > ul:after {
    clear: both;
}
.menu > ul > li {
    display: inline-block;
}
.menu > ul > li a {
    text-decoration: none;
    padding: 30px 28px 32px 0;
    display: block;
    color: #232323;
    font-size: 17px;
    font-weight: 600;
}
.menu > ul > li > ul {
    display: none;
    width: 100%;
    background: #fff;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
    padding: 0 20px 0 0;
    position: absolute;
    z-index: 99;
    left: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
}
.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
    content: "";
    display: table;
}
.menu > ul > li > ul:after {
    clear: both;
}
.menu > ul > li > ul > li {
    margin: 0;
    padding-bottom: 0;
    list-style: none;
    width: 25%;
    background: none;
    float: left;
}
.menu > ul > li > ul > li a:hover {
    color: #fff;
    background: var(--primary);
}
.menu > ul > li > ul > li > ul {
    display: block;
    padding: 0;
    margin: 10px 0 0;
    list-style: none;
    box-sizing: border-box;
}
.menu > ul > li > ul > li > ul:before,
.menu > ul > li > ul > li > ul:after {
    content: "";
    display: table;
}
.menu > ul > li > ul > li > ul:after {
    clear: both;
}
.menu > ul > li > ul > li > ul > li {
    float: left;
    width: 100%;
    padding: 2px 0;
    margin: 0;
}
.menu > ul > li > ul > li > ul > li a {
    border: 0;
    display: block;
    padding: 12px 20px;
    margin: 0;
    line-height: 1.3;
    letter-spacing: normal;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    -webkit-transition: .1s;
    transition: .1s;
    color: #333 !important;
    text-align: left;
}
.menu > ul > li > ul.normal-sub {
    width: 265px;
    left: auto;
}
.menu > ul > li > ul.normal-sub > li {
    width: 100%;
}
.menu > ul > li > ul.normal-sub > li a {
    border: 0;
    padding: 10px 20px;
    font-size: 15px;
    text-align: left;
    color: #333 !important;
}
.menu > ul > li > ul > li > ul > li a span {
    background: var(--primary);
    font-size: 12px;
    color: #fff;
    padding: 3px 5px 2px;
    border-radius: 5px;
}
.menu > ul > li > ul.normal-sub > li a:hover {
    color: #fff !important;
}
.menu > ul > li > ul > li > ul > li a:hover{
    color: #fff !important;
}
/*sticky*/
.sticky .menu > ul > li a {
    color: #fff;
}
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (max-width: 959px) {
    .menu-container {
        width: 100%;
    }
    .menu-container .menu{
        display:inline-block;
    }
    .menu-mobile {
        display: block;
    }
    .menu-dropdown-icon:before {
        display: block;
    }
    .menu > ul {
        display: none;
        width:100%;
    }
    .menu > ul > li {
        width: 100%;
        float: none;
        display: block;
    }
    .menu > ul > li a {
        padding: 1.5em;
        width: 100%;
        display: block;
    }
    .menu > ul > li > ul {
        position: relative;
        padding: 0 40px;
    }
    .menu > ul > li > ul.normal-sub {
        width: 100%;
    }
    .menu > ul > li > ul > li {
        float: none;
        width: 100%;
        margin-top: 20px;
    }
    .menu > ul > li > ul > li:first-child {
        margin: 0;
    }
    .menu > ul > li > ul > li > ul {
        position: relative;
    }
    .menu > ul > li > ul > li > ul > li {
        float: none;
    }
    .menu .show-on-mobile {
        display: block;
    }
}



html{
    scroll-behavior: smooth;
}


/*============================
 techno	Main Menu Area Css
==============================*/
.techno_nav_manu {
    background: #fff;
    transition: .5s;
    margin-bottom: 0;
    z-index: 999;
    position: relative;
}
.techno_nav_manu.transparent_menu {
    background: transparent;
    margin-bottom: -87px;
    position: relative;
}
.transparent_menu .menu > ul > li a {
    color: #fff;
}
.transparent_menu.up .menu > ul > li a {
    color: #232323;
}
.sticky {
    left: 0;
    margin: auto;
    position: fixed !important;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    background: #00247e !important;
    transition: .5s;
    z-index: 999;
    -webkit-animation: 300ms running fadeInDown;
    animation: 500ms running fadeInUp;
    animation-name: slideInDown;
}
/*style two*/
.style-two.techno_nav_manu {
    background: transparent;
    transition: 0.5s;
    margin-bottom: -87px;
    z-index: 999;
    position: relative;
}
.style-two .techno_menu > ul > li > a {
    color: #fff;
    font-size: 16px;
}

.style-two .donate-btn-header {
    display: inline-block;
}
.style-two a.dtbtn {
    -moz-user-select: none;
    background: transparent;
    color: var(--primary);
    font-size: 16px;
}
/*upper*/
.upper.style-two .donate-btn-header {
    display: inline-block;
}
.upper.style-two .donate-btn-header a.seo-button {
    background: #fff;
    color: var(--primary);
}
.upper.style-two .donate-btn-header a.seo-button:hover{
    color: #fff;
}
/*style three*/
.style-three .techno_menu > ul > li > a {
    color: #616161;
    font-size: 16px;
    font-weight: 400;
}
/*style four*/
.style-four.techno_nav_manu {
    background: #1137C8;
    height: 97px;
    z-index: 1;
}
.style-four .techno_menu > ul > li > a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}
/*style end*/

a.seo-button {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
    background: var(--primary);
    color: #fff;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}
a.seo-button:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    background: #00247E;
    transform: scale(0);
    transition: .5s;
}
a.seo-button:hover:before {
    transform: scale(1);
}
a.seo-button:hover {
    color: #fff;
}
/* techno Menu Css*/
.techno_menu {
    text-align: right;
}
.techno_menu ul {
    text-align: right;
    list-style: none;
    display: inline-block;
}
.techno_menu>ul>li {
    display: inline-block;
    position: relative;
}
.techno_menu > ul > li > a {
    display: block;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    position: relative;
    color: #232323;
    font-weight: 600;
    text-transform: capitalize;
    margin: 30px 10px;
    font-size: 17px;
}
.white .techno_menu > ul > li > a {
    color: #fff;
}
.techno_menu ul li:last-child a {
    margin-right: 0px;
}
.techno_menu>ul>li.current>a {
    color: #00247E;
}
.white .techno_menu>ul>li.current>a {
    color: #fff;
}
/* sub menu style */
.techno_menu ul .sub-menu {
    position: absolute;
    left: 0;
    top: 130%;
    width: 217px;
    text-align: left;
    background: #fff;
    margin: 0;
    z-index: 1;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    border-top: 2px solid var(--primary);
}
.techno_menu ul li:hover>.sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 100%;
    z-index: 9;
}
.techno_menu ul .sub-menu li {
    position: relative;
}
.techno_menu ul .sub-menu li a {
    display: block;
    padding: 12px 20px;
    margin: 0;
    line-height: 1.3;
    letter-spacing: normal;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    visibility: inherit !important;
    color: #333 !important;
}
.techno_menu ul .sub-menu li:hover>a,
.techno_menu ul .sub-menu .sub-menu li:hover>a,
.techno_menu ul .sub-menu .sub-menu .sub-menu li:hover>a,
.techno_menu ul .sub-menu .sub-menu .sub-menu .sub-menu li:hover>a {
    background:var(--primary);
    color: #fff !important;
}
.techno_menu ul .sub-menu li a span{
    background: var(--primary);
    font-size: 12px;
    color: #fff;
    padding: 3px 5px 2px;
    border-radius: 5px;
}
.techno_menu ul .sub-menu li:hover>a span{
    background: #fff;
    color: var(--primary);
}


/* sub menu 2 */
.techno_menu ul .sub-menu .sub-menu {
    left: 100%;
    top: 130%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
}
.techno_menu ul .sub-menu li:hover>.sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 0%;
}

/* sub menu 3 */
.techno_menu ul .sub-menu .sub-menu li {
    position: relative;
}
.techno_menu ul .sub-menu .sub-menu .sub-menu {
    right: 100%;
    left: auto;
    top: 130%;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
}
.techno_menu ul .sub-menu .sub-menu li:hover>.sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 0%;
}

/* sub menu 4 */
.techno_menu ul .sub-menu .sub-menu .sub-menu li {
    position: relative;
}
.techno_menu ul .sub-menu .sub-menu .sub-menu .sub-menu {
}
.techno_menu ul .sub-menu .sub-menu .sub-menu li:hover>.sub-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 0%;
}

.donate-btn-header {
    display: inline-block;
}
a.dtbtn {
    -moz-user-select: none;
    background: var(--primary);
    border: medium none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    margin-bottom: 0;
    padding: 10px 30px;
    text-align: center;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.5s ease 0s;
    vertical-align: middle;
    border-radius: 5px;
    border: 2px solid var(--primary);
    font-weight: 600;
}
a.dtbtn:hover, a.dtbtn.active {
    border: 2px solid #00247E;
    background: #00247E;
    color: #fff;
}
a.dtbtn.up:hover {
    border: 2px solid #00247E;
    background: linear-gradient(to left, #2475FC, #1129B9 100%);
    color: #fff;
}
.techno_menu li a:hover:before{
    width:101%;
}
.techno_nav_manu.sticky .logo_img {
    display: none;
}
.main_sticky {
    display: none;
}
.techno_nav_manu.sticky .main_sticky {
    display: inherit;
}
.techno_nav_manu.sticky .techno_menu li a {
    color: #fff;
}
.techno_nav_manu.sticky a.dtbtn {
    color: var(--primary);
    background:#fff;
    border: 2px solid #fff;
}
.techno_nav_manu.sticky a.dtbtn:hover {
    color: var(--primary);
}
.mobile-menu.mean-container {
    overflow: hidden;
}

/*====================================================
--<	Techno Header Top Menu Area Css -->
======================================================*/
.header_top_menu_address_inner ul {
    list-style: none;
}
.header_top_menu_address_inner ul li {
    display: inline-block;
}
.header_top_menu_icon_inner ul {
    list-style: none;
    text-align:right;
}
.header_top_menu_icon_inner ul li {
    display: inline-block;
}
.header_top_menu_address_inner ul li a i {
    font-size: 16px;
    margin-right: 10px;
    color: #fff;
}
.header_top_menu_address_inner ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-right:15px;
}
.header_top_menu_icon_inner ul li a{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin:0 5px;
}
/*====================================================
--<	Techno Slider Area Css -->
======================================================*/
.slider1 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.slider2 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.slider3 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.slider4 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.slider5 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.slider6 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}
.slider7 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}
.slider8 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}
.slider9 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}
.slider9 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}
.slider10 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 1050px;
    position:relative;
}
.slider11 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 800px;
    position:relative;
}
.slider12 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 800px;
    position:relative;
}
.slider13 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}
.slider14 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
    position:relative;
}

.slider15 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 950px;
    position: relative;
}
.slider16 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 950px;
    position: relative;
}
.slider17 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 1050px;
    position: relative;
}
.slider18 {

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 950px;
    position: relative;
}

/*hero thumb*/
.hero_section_thumb {
    position: relative;
    z-index: 1;
}
.em-hero-inner-thumb {
    position: absolute;
    top: 65px;
    left: 50px;
}
.em-hero-icon-thumb {
    position: absolute;
    top: 122px;
    left: 104px;
}
.em-hero-inner-thumb1 {
    position: absolute;
    top: 0px;
    right: 155px;
}
.em-hero-inner-thumb2 {
    position: absolute;
    top: 50px;
    right: -60px;
}
.em-hero-inner-thumb3 {
    position: absolute;
    top: 286px;
    right: -174px;
}
.em-hero-inner-thumb4 {
    position: absolute;
    bottom: 215px;
    left: 0;
}
/*hero thumb style two slider16*/
.slider16 .em-hero-inner-thumb {
    position: absolute;
    top: 115px;
    left: 19px;
}
.slider16 .em-hero-inner-thumb2 {
    position: absolute;
    top: 55px;
    right: -218px;
    animation: moveLeftBounce 3s ease-in-out infinite;
}
.bounce_left_right {
    animation-delay: 1.6s;
    animation: moveLeftBounce 3.2s ease-in-out infinite;
}
.bounce_left_right img {
    filter: drop-shadow(0 0 4px rgba(30, 56, 143, 0.05));
}
.slider16 .em-hero-inner-thumb3 {
    position: absolute;
    top: 80px;
    left: 120px;
}
.slider16 .em-hero-inner-shape {
    position: absolute;
    top: -100px;
    left: inherit;
    right: 190px;
}
.slider16 .em-hero-inner-shape1 {
    position: absolute;
    right: 75px;
    bottom: -100px;
}
.slider16 .em-hero-inner-shape2 {
    position: absolute;
    left: -260px;
    bottom: 105px;
}
/*wood animation*/
@keyframes wooo{
    0%{
        transform: rotate3d(0, 0, 0)
    }
    50%{
        transform: rotate3d(0, 1, 0, 180deg)
    }
    100%{
        transform: rotate3d(0, 1, 0, 359deg)
    }
}
/*slider17*/
.slider17 .em-hero-inner-thumb {
    position: absolute;
    top: 56px;
    left: 411px;
    z-index: -1;
}
.slider17 .em-hero-inner-shape {
    position: absolute;
    top: -272px;
    left: -330px;
    animation: moveLeftBounce 3s linear infinite;
}
/*hero thumb End*/

.slider_text_inner {
    display: inline-block;
}
.slider_text_inner h1  {
    font-size: 65px;
    text-transform: none;
    font-weight: 800;
    color: #fff;
    margin: 0;
    margin: 0;
    line-height: 1.1;
}
.slider_text_inner h1 span{
    color: var(--primary);
}
.slider_text_inner h5  {
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0 0 24px;
    letter-spacing: 1px;
}

.slider_text_inner p {
    width: 70%;
    font-size: 16px;
    padding: 29px 0 22px;
    color: #fff;
}
.slider_text_desc p {
    color: #fff;
    width: 62%;
    font-size: 18px;
}
.text_center .slider_text_desc p {
    margin: auto;
}
/*slider style two*/
.slider15 .single_slider {
    position: relative;
    z-index: 1;
    top: 184px;
}
.em-hero-inner-shape1 {
    position: absolute;
    right: 75px;
    bottom: -160px;
}
.em-hero-inner-shape {
    position: absolute;
    top: -125px;
    left: -126px;
}
.slider15 .slider_text_inner {
    display: inline-block;
}
.slider15 .slider_text_inner h1 {
    font-size: 53px;
    text-transform: none;
    font-weight: 800;
    color: #fff;
    margin: 0;
    line-height: 1.3;
    padding: 0 0 0 22px;
    border-left: 5px solid #37BBFF;
}
.slider15 .slider_text_inner h5 {
    font-size: 20px;
    text-transform: none;
    color: #fff;
    font-weight: 600;
    margin: 0 0 24px 44px;
    letter-spacing: 0;
    position: relative;
    z-index: 1;
}
.slider15 .slider_text_inner h5:before {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    left: -42px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}
.slider15 .slider_text_inner p {
    width: 100%;
    font-size: 18px;
    padding: 22px 0 0;
    color: #fff;
}
.slider15 .button a {
    background: #33BAFF;
    padding: 15px 35px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
    margin-top: 4px;
}
/*slider 16*/

.slider16 .slider_text_inner h5 {
    font-size: 18px;
    text-transform: uppercase;
    color: #0C59DB;
    font-weight: 700;
    margin: 0 0 18px 50px;
    letter-spacing: 0;
    position: relative;
    z-index: 1;
}
.slider16 .slider_text_inner h5:before {
    position: absolute;
    content: "";
    left: -48px;
    top: 10px;
    width: 38px;
    height: 1px;
    background: #0C59DB;
}
.slider16 .slider_text_inner h1 {
    font-size: 4.5rem;
    font-weight: 700;
    text-transform: none;
    margin: 0;
    line-height: 1.25;
    color: var(--primary);
}
.slider16 .slider_text_inner p {
    width: 100%;
    font-size: 17px;
    padding: 15px 0 20px;
    color: #616161;
}
/*slider17*/

.slider17 .slider_text_inner h5 {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    margin: 0 0 24px;
    letter-spacing: 0;
    display: inline-block;
    position: relative;
    z-index: 1;
}
.slider17 .slider_text_inner h5:before {
    position: absolute;
    content: "";
    z-index: -1;
    top: -2px;
    right: -10px;
    width: 30px;
    height: 27px;
    background: #6989CA;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 65% 100%);
    transition: .5s;
}
.slider17 .slider_text_inner h1 {
    font-size: 58px;
    text-transform: none;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 1.2;
}
.slider17 .slider_text_inner p {
    width: 100%;
    padding: 15px 0 10px;
}
.slider17 .button a {
    background: #fff;
    padding: 13px 42px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: var(--primary);
}
.slider17 .upper.button a {
    background: inherit;
    padding: 12px 42px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
    margin-right: 15px;
    border: 1px solid #ffffff;
}
.slider17 .slider_button span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 13px 19px 0;
}
/*slider17 all hover*/
.slider17 .slider_text_inner h5:hover:before {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    width: 110%;
}
/* Particles Js Slider */
.effective_slider .slider_text > h1 {
    margin-bottom: 13px;
}
.particles-js-canvas-el {
    position: absolute;
    top: 0;
}
/*Srart slider18*/

.slider18 .slider_text_inner h5 {
    font-size: 20px;
    text-transform: inherit;
    color: #fff;
    font-weight: 600;
    margin: 0 0 24px;
    letter-spacing: 0;
    padding: 0 0 0 36px;
    position: relative;
    z-index: 1;
}
.slider18 .slider_text_inner h5:before {
    position: absolute;
    content: "";
    top: 6px;
    left: 11px;
    width: 15px;
    height: 15px;
    background: #33BAFF;
    border-radius: 15px;
}
.slider18 .slider_text_inner h5:after {
    position: absolute;
    content: "";
    top: -2px;
    left: -1px;
    width: 20px;
    height: 20px;
    background: rgba(255,108,11,0.7019607843137254);
    border-radius: 15px;
}
.slider18 .slider_text_inner h1 {
    font-size: 56px;
    text-transform: none;
    font-weight: 800;
    color: #fff;
    margin: 0;
    line-height: 1.2;
}
.slider18 .slider_text_inner p {
    width: 100%;
    font-size: 18px;
    padding: 23px 0 7px;
    color: #fff;
}
/*hero button*/
.slider18 .slider_button {
    display: inline-block !important;
    padding-right: 30px;
}
.slider18 .button a {
    background: #33BAFF;
    padding: 14px 30px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
}
.slider18 .button a i {
    margin-left: 5px;
    position: relative;
    top: 2px;
    font-size: 18px;
}
/*hero icon*/
.hero-call-icon {
    display: inline-block;
    position: relative;
    top: -6px;
}
.slider18 .call-icon {
    float: left;
    margin-right: 20px;
}
.slider18 .call-icon i {
    font-size: 25px;
    width: 58px;
    height: 58px;
    line-height: 58px;
    border: 1px solid #fff;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
}
/*call title*/
.slider18 .call-title {
    overflow: hidden;
}
.slider18 .call-title p {
    color: #fff;
    margin: 0;
}
.slider18 .call-title h6 {
    color: #fff;
    margin-top: 4px;
}
/*hero thumb section*/
.slider18  .hero_section_thumb {
    position: relative;
    z-index: 1;
    left: 70px;
}
.slider18 .em-hero-inner-thumb4 {
    position: absolute;
    bottom: 282px;
    left: 59px;
}
.slider18 .em-hero-icon-thumb {
    position: absolute;
    top: 240px;
    left: 77px;
}
.slider18 .em-hero-inner-thumb3 {
    position: absolute;
    top: 520px;
    right: -151px;
}
.slider18 .em-hero-inner-thumb2 {
    position: absolute;
    top: 92px;
    right: -60px;
}
.slider18 .em-hero-inner-shape1 {
    position: absolute;
    right: -108px;
    top: -54px;
    animation: moveLeftBounce 3s linear infinite;
}
.slider18 .em-hero-inner-shape {
    position: absolute;
    top: inherit;
    bottom: -240px;
    left: inherit;
    right: -74px;
}
/*end slider area*/

/* Color Black */
.color_black .slider_text_inner h1 {
    color: #232323;
}
.color_black .slider_text_desc p {
    color: #616161;
}
.color_black .slider_text_inner h5 {
    color: var(--primary);
}
/* Button Css */
.button a {
    background: var(--primary);
    padding: 16px 32px;
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
}
.button.color_two a {
    background: #00247E;
}
.button.color_two a:before {
    background: var(--primary);
}
.button.two a {
    padding: 14px 35px;
}
.button.three a {
    background: #fff;
    padding: 14px 35px;
    color: var(--primary);
}
.button.style-four a {
    background: transparent;
    padding: 10px 30px;
    border-radius: 30px;
    color: var(--primary);
    border: 2px solid var(--primary);
}
.button.style-four a:before {
    background: var(--primary);
}
.button .active {
    background: #00247E;
    margin-left: 25px;
}
.button .active:before {
    background: var(--primary);
}
.button a i {
    margin-left: 5px;
    position: relative;
    top: 2px;
}
.button a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background: #00247E;
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
}
.button a:hover {
    color: #fff;
}
.button a:hover:before {
    width: 180%;
}
.slider12 .button a i {
    font-size: 14px;
}
.slider12 .button a {
    font-size: 17px;
}
.slider12 .button .active {
    background: transparent;
    border: 1px solid #fff;
}
.slider12 .button .active:hover{
    border-color:var(--primary);
}
.slider13 .slider_text_inner h5, .slider14 .slider_text_inner h5 {
    font-size: 20px;
    color: var(--primary);
    margin: 0 0 16px;
}
.slider13 .slider_text_inner h1,
.slider14 .slider_text_inner h1{
    font-size: 60px;
    color: #232323;
}

.slider13 .slider_text_inner p,
.slider14 .slider_text_inner p {
    width: 67%;
    font-size: 18px;
    padding: 23px 0 11px;
    color: #616161;
    margin: 0;
}
.slider13 .button a,
.slider14 .button a{
    padding: 13px 32px;
    font-size: 18px;
}


/* Video Css */
.slider-video {
    position: relative;
}
.slider-video .video-icon {
    position: absolute;
    right: 0;
    top: -165px;
}
.slider-video.two .video-icon {
    left: 262px;
    top: -74px;
}
.appointment .slider-video.two .video-icon {
    left: 0;
    top: 0;
}
.single-video2 .video-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.video-icon a {
    width: 75px;
    height: 75px;
    line-height: 75px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    position: relative;
    color: var(--primary);
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-blue 1s linear infinite;
    -webkit-transition: .5s;
    background: #fff;
    font-size: 28px;
    z-index: 44;
}
.slider10 .video-icon a {
    border-radius: 0;
}
@-webkit-keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0)
    }
}

@keyframes ripple-blue {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.3), 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0);
        box-shadow: 0 0 0 10px rgba(255,255,255, 0.3), 0 0 0 20px rgba(255,255,255, 0.3), 0 0 0 30px rgba(255,255,255, 0)
    }
}

/* Owl Nav Css */
.owl-prev {
    position: absolute;
    left: -60px;
    top: 50%;
    transform:translateY(-50%);
    opacity:1;
    transition:.3s;
}
.owl-next {
    position: absolute;
    right: -60px;
    top: 50%;
    transform:translateY(-50%);
    opacity:1;
    transition:.3s;
}
/*.owl-carousel:hover .owl-prev{
 left:30px;
}
.owl-carousel:hover .owl-next{
right:30px;
}*/
.owl-next i,
.owl-prev i{
    background-color: transparent;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 60px;
    width: 60px;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 55px;
    color: #fff;
    border: 2px solid #FFF;
    text-align:center;
}
.owl-next i:hover,
.owl-prev i:hover{
    background-color: rgba(255, 255, 255, 0.2);
}
/*====================================================
--<	Techno Banner Area Css -->
======================================================*/
.banner1 {
    height: 800px;
}
.banner_text_content h1 {
    font-size: 60px;
    font-weight: 800;
    margin: 0;
}
.banner1 .cd-headline.clip span b {
    color: #232323;
    text-transform:capitalize;
    font-weight:800;
}

.data_science_video {
    position: relative;
}
.banner_area .data_science_video_inner {
    position: absolute;
    left: 40%;
    bottom: -16px;
}
.data_science_video_inner a i {
    position: absolute;
    left: 52px;
    font-size: 25px;
    color: var(--primary);
    top: 50%;
    transform: translate(-50% , -50%);
    text-align: center;
}
.single_banner_thumb_inner img {
    width: 100%;
}
.banner_shape {
    position: relative;
}
.banner_shape_inner1 {
    position: absolute;
    top: 156px;
    left: 24%;
}
.section-inner-content p {
    font-size: 18px;
    margin: 0;
}
.banner_shape_inner2 {
    position: absolute;
    top: 220px;
    left: 56%;
}
.banner_shape_inner3 {
    position: absolute;
    top: 162px;
    left: 92%;
}
.banner_shape_inner4 {
    position: absolute;
    top: 371px;
    left: 190px;
}
.banner_shape_inner5 {
    position: absolute;
    right: -140px;
    top: 342px;
}
.banner_shape_inner6 {
    position: absolute;
    right: 55px;
    bottom: -77px;
}
/* Banner Home Two Shape  */
.machine_banner_shape{
    position:relative;
}
.machine_banner_shape_inner1 {
    position: absolute;
    top: -100px;
    left: 115px;
}
.machine_banner_shape_inner2 {
    position: absolute;
    left: 6px;
    top: 60px;
}
.machine_banner_shape_inner3 {
    position: absolute;
    left: 184px;
    top: 100px;
}
.machine_banner_shape_inner4 {
    position: absolute;
    left: 265px;
    top: 70px;
}
.machine_banner_shape_inner5 {
    position: absolute;
    right: -45px;
    top: -92px;
}
.machine_banner_shape_inner6 {
    position: absolute;
    top: 100px;
    right: -20px;
}
.machine_banner_shape_inner7 {
    position: absolute;
    top: 60px;
    right: -195px;
}
.machine_banner_shape_inner8 {
    position: absolute;
    top: 210px;
    left: -10px;
}
.machine_banner_shape_inner9 {
    position: absolute;
    top: 340px;
    left: 230px;
}
.machine_banner_shape_inner10 {
    position: absolute;
    top: 225px;
    right: -165px;
}
.machine_banner_shape_inner11 {
    position: absolute;
    right: 30px;
    top: 345px;
}
/* Banner Style Two */
.banner2{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    height:900px;
}
.banner2 .banner_text_content h1 {
    font-size: 45px;
}
/* Banner Style Three */
.banner3{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    height:850px;
}
.banner3 .banner_text_content h1 {
    font-size: 55px;
}
.affiliate_banner_shape {
    position: relative;
}
.affiliate_banner_shape_inner1 {
    position: absolute;
    top: 170px;
    left: 0;
}
.affiliate_banner_shape_inner2 {
    position: absolute;
    top: 170px;
    left: 0;
}
.affiliate_banner_shape_inner3 {
    position: absolute;
    top: 45px;
    left: 126px;
}
.affiliate_banner_shape_inner4 {
    position: absolute;
    top: -30px;
    left: 170px;
}
.affiliate_banner_shape_inner5 {
    position: absolute;
    right: -245px;
    top: 50px;
}
.affiliate_banner_shape_inner5 img {
    width: 80%;
}
.affiliate_banner_shape_inner6 {
    position: absolute;
    right: -135px;
    top: 190px;
}
.affiliate_banner_shape_inner7 {
    position: absolute;
    right: -163px;
    top: -45px;
}



/*====================================================
--<	Techno Lines Css -->
======================================================*/
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
}

.lines .line {
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    -webkit-animation: moveLeftBounces-one 20s linear infinite;
    animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line:nth-child(1) {
    margin-left: -25%;
}

.lines .line:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.lines .line:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.lines .line:nth-child(3) {
    margin-left: 25%;
}

.lines .line-two {
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.lines .line-two::after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #d80650;
    -webkit-animation: moveLeftBounces-two 20s linear infinite;
    animation: moveLeftBounces-two 20s linear infinite;
}

.lines .line-two:nth-child(1) {
    margin-left: -25%;
}

.lines .line-two:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.lines .line-two:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.lines .line-two:nth-child(3) {
    margin-left: 25%;
}

.lines .line-three {
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.lines .line-three::after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #d80650;
    -webkit-animation: moveLeftBounces-one 20s linear infinite;
    animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line-three:nth-child(1) {
    margin-left: -25%;
}

.lines .line-three:nth-child(1)::after {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.lines .line-three:nth-child(3)::after {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

.lines .line-three:nth-child(3) {
    margin-left: 25%;
}

@-webkit-keyframes moveLeftBounces-one {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes moveLeftBounces-one {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
/*====================================================
--<	Techno Nivo Slider Area Css -->
======================================================*/
.nivo_slider_area {
    position: relative;
}
.nivo-caption {
    background: rgba(0, 0, 0, 0.0);
    height: 100%;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.em_slider_inner {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999;
}

/* slider title */
.em-slider-title {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 23px;
    text-transform: uppercase;
}
.em-slider-main-title {
    color: #fff;
    font-size: 120px;
    font-weight: 800;
}
.em-slider-sub-title {
    color: #fff;
    font-size: 33px;
    font-weight: 400;
}
.em-nav-slider-title-center {
    color: #fff;
    font-size: 55px;
    font-weight: 800;

}
.em-nav-slider-title-centerspan{
    color: var(--primary);
}
.em-slider-up-title {
    font-size: 38px;
    color: #fff;
    font-weight: 300;
    padding-bottom: 8px;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.em-slider-sub-em-title {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
}
.em-slider-descript {
    font-size: 17px;
    margin-bottom: 20px;
    width: 59%;
    z-index: 999;
    font-weight: 400;
    color: #ddd;
    margin-top: 10px;
}
.text-left .em-slider-descript {
    margin-left: 0;
    margin-right: auto;
}

.text-center .em-slider-descript {
    margin: 11px auto 28px;
}
.text-right .em-slider-descript {
    margin-right: 0;
    margin-left: auto;
}
.text-center {
    text-align: center;
}
/* slider button */
.em-slider-button.wow.bounceInUp.em-button-button-area.animated {
    padding-top: 19px;
}
.em-button-button-area a {
    border: 1px solid #ccc;
    color: #fff;
    display: inline-block;
    font-size: 15px;
    margin-right: 10px;
    margin-top: 12px;
    padding: 15px 41px;
    position: relative;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    z-index: 999;
    letter-spacing: 1px;
    border-radius: 5px;
}
a.em-active-button {
    background: var(--primary);
    border-color: var(--primary);
}

.em-button-button-area a:hover {
    color: #fff;
    background: var(--primary);
    border-color: var(--primary);
}

a.em-active-button:hover {
    background: transparent;
    border-color: #fff;
}

.em-slider-half-width {
    width: 50%;
}

.em-slider-left {
    padding-right: 30px;
}

.em-slider-right {
    padding-left: 30px;
}

.em-slider-full-width {
    width: 85%;
}

.em-slider-half-width .em-slider-descript {
    width: 100%;
}

/* directionNav */
.em-nivo-slider-wrapper .nivo-directionNav a {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 75px;
    width: 50px;
    line-height: 68px;
    text-align: center;
    display: block;
    border-radius: 50%;
    color: var(--primary);
    font-size: 40px;
    left: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    border-radius: 0;
    background: #fff;
    margin-right: 30px;
}
.em-nivo-slider-wrapper .nivo-directionNav .nivo-prevNav {
    margin-left: 30px;
}
.em-nivo-slider-wrapper .nivo-directionNav .nivo-nextNav {
    left: auto;
    right: 0;
}
.em-nivo-slider-wrapper .nivo-directionNav a:hover {
    background: var(--primary);
    border-color: var(--primary);
    color: #fff;
}
.em-nivo-slider-wrapper:hover .nivo-directionNav a {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    left: 50px;
}

.em-nivo-slider-wrapper:hover .nivo-directionNav .nivo-nextNav {
    left: auto;
    right: 50px;
}
/* controlNav */

.em-nivo-slider-wrapper .nivo-controlNav {
    bottom: 50px;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 9;
    display: none;
}

.em-nivo-slider-wrapper .nivo-controlNav a {
    background: #000 none repeat scroll 0 0;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 25px;
    margin: 0 5px;
    width: 25px;
    color: #fff;
    line-height: 25px;
}

.em-nivo-slider-wrapper .nivo-controlNav a:hover,
.em-nivo-slider-wrapper .nivo-controlNav a.active {
    background: #FFA700 none repeat scroll 0 0;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.cd-headline.clip span {
    display: inline-block;
    padding: 0 0 0;
}
.cd-headline.clip span b{
    display:inline-block;
    color:#fff;
    text-transform:uppercase;
}
/* Appointment Css */
.appointment {
    position: relative;
    z-index:99 !important;
}
.sign-up-form-wrap{
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);

}
.form-control {
    height: 54px;
    background-color: #fff;
    border-color: transparent;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border: 1px solid #e6e6e6;
    padding: 6px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
}
.form-group .btn {
    background: var(--primary);
    border: 0;
    display: inline-block;
    text-align: center;
    border-radius: 7px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    color: #fff;
    width:100%;
    height: 54px;
}
.appointment_title {
    font-weight: 800;
}
.slider_bottom_shape_inner {
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    z-index:-1;
}






/*
<!-- ============================================================== -->
<!-- Techno - Breatcome Area Css -->
<!-- ============================================================== -->*/
.slider{
    position: relative;
    width: 100%;
}
.tp-caption a {
    font-size: 17px !important;
    color: #fff;
    background: var(--primary);
    padding: 12px 32px !important;
    margin-right: 16px;
    display: inline-block;
    border-radius: 5px;
    transition:.5s;
}
.tp-caption a:hover {
    background: #00247e;
}
.normalWraping{
    white-space: normal !important;
}
.tp-bullets.vor_bullet .tp-bullet span{
    display: none;
}





/*
<!-- ============================================================== -->
<!-- Techno - Breatcome Area Css -->
<!-- ============================================================== -->*/
.breatcome_area{
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    height:500px;
    position:relative;
}
.breatcome_area:before{
    position: absolute;
    content: '';
    background-image: linear-gradient(to right, rgba(12,90,219, 0.85), rgba(12,90,219, 0.80), rgba(18,85,194, 0.70), rgba(34,86,172, 0.75), rgba(38,85,163, 0.75));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.breatcome_title {
    text-align: center;
}
.breatcome_title_inner h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
}
.breatcome_content ul li {
    display: inline-block;
}
.breatcome_content ul li a {
    color: #fff;
    opacity: .7;
    transition:.5s;
}
.breatcome_content ul li i {
    color: #fff;
    opacity: .7;
    padding: 0 5px;
}
.breatcome_content ul li span {
    color: #fff;
    opacity: .7;
}
.breatcome_content ul li a:hover {
    opacity:1;
}
/*====================================================
--<	Techno Dark Slider Area Css -->
======================================================*/
.bg-dark{
    background:#232435;
}
.bg-dark2{
    background:#181b2d;
}
.dark1 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.dark2 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 900px;
}
.dark.top_feature .techno_flipbox .techno_flipbox_font {
    background: #181b2d;
}
.dark.top_feature .techno_flipbox .flipbox_title h3 {
    color: #fff;
}
.dark .about_icon .icon {
    color: #fff;
}
.dark .singel-about-content h5 {
    color: #fff;
}
.dark .singel-about-content p {
    color: #fff;
}
.dark .single_work_content_title h4 {
    color: #fff;
}
.dark .single_it_work_content_text p {
    color: #fff;
}
.dark .single_testimonial_content_title h4 {
    color: #fff;
}
.dark .single_blog {
    border: 0;
}
.dark .cntr_bg_up {
    position: relative;
    margin-bottom: -100px;
}
/*====================================================
--<	Techno Landing area Css -->
======================================================*/
.landing .slider_text_desc p {
    margin: auto;
}
.landing .button {
    margin: auto;
}
.landing .big-half-circle.big-half-circle-1 {
    width: 100%;
    margin-bottom: 0px;
    height: 80px;
    position: absolute;
    bottom: 0;
}
/*====================================================
--<	Techno Section Title Css -->
======================================================*/
.section_sub_title h6 {
    letter-spacing: 5px;
    color: var(--primary);
    font-size: 16px;
}
.section_title.text_center {
    text-align:center;
}
.section_main_title h1,
.section_main_title h2 {
    font-weight: 800;
    margin:0;
}
.text_left .section_main_title h1 {
    margin: 0;
    letter-spacing: -1px;
}
.section_main_title h1 span{
    color:var(--primary);
}
.section_content_text p {
    font-size: 18px;
}
.text_center .section_content_text p {
    width: 75%;
    margin: auto;
    font-size: 19px;
}
.section_content_text.bold p {
    font-size: 22px;
    font-weight: 600;
}
.section_title.white .section_main_title h1,
.section_title.white .section_main_title h2 {
    color: #fff;
}
.section_title.white .section_sub_title h6 {
    color: #fff;
}
.section_title.white .section_content_text p {
    color: #fff;
}
/*style two*/
.section_sub_title h6 {
    letter-spacing: 0;
    color: var(--primary);
    font-size: 16px;
}
/*odoo section content*/
.about_area.odoo .section_content_text {
    margin: 36px 0 26px;
}
.about_area.odoo .text_left .section_main_title h1 {
    font-size: 39px;
}

.section_button {
    text-align: right;
}
.phone_number h5 {
    letter-spacing: 1px;
    font-weight: 500;
}
/*style two*/
.about_area .upper.section_content_text {
    margin: 19px 0 26px;
}
.about_area .upper.section_content_text p {
    font-size: 16px;
    padding: 0 0 0 20px;
    border-left: 2px solid var(--primary);
    width: 100%;
}
.upper.section_content_text p {
    font-size: 16px;
    padding: 0 0 0 20px;
    width: 62%;
}
.upper1.section_content_text p {
    font-size: 16px;
    padding: 18px 0 0px;
    width: 100%;
}
.upper.section_main_title h1 {
    font-size: 38px !important;
    letter-spacing: 0;
}
/*section title h5*/
.section_sub_title h5 {
    letter-spacing: 0;
    color: var(--primary);
    font-size: 16px;
    position: relative;
    z-index: 1;
    font-weight: 600;
    text-transform: capitalize;
    padding: 0 0 18px 20px;
}
.section_sub_title h5:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: -12px;
    filter: drop-shadow(0px 5px 15px rgba(152,179,223,0.2));
    background-color: #ffffff;
    width: 145px;
    height: 42px;
    border-radius: 4px;
}
.upper.section_sub_title h5:before {
    right: 0;
    margin: auto;
    left: 15px;
    top: -12px;
}
.feature-area .section_sub_title h5:before {
    width: 174px;
}
.service-area .section_sub_title h5:before {
    width: 174px;
}
.why-choose-us .section_sub_title h5:before {
    width: 161px;
}
.testimonial-area .section_sub_title h5 {;
    color: #fff;
}
.testimonial-area .section_sub_title h5:before {
    filter: drop-shadow(0px 5px 15px rgba(152,179,223,0.2));
    background-color: rgba(255,255,255,0.14901960784313725);
    width: 173px;
}
.blog-area .section_sub_title h5:before {
    width: 123px;
    height: 42px;
}
/*section title style two*/
.section_main_title1 h1 {
    font-size: 32px;
    color: var(--primary);
    line-height: 45px;
}
.section_sub_title1 h6 {
    letter-spacing: 0;
    color: var(--primary);
    font-size: 18px;
    font-weight: 700;
}
.section_content_text1 p {
    font-size: 16px;
    padding: 17px 0 10px;
}
.section_content_text2 p {
    font-size: 16px;
    padding: 10px 0 15px;
}
/*style upper*/
.upper.section_sub_title1 h6 {
    letter-spacing: 0;
    color: var(--primary);
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 40px;
    position: relative;
    z-index: 1;
}
.upper.section_sub_title1 h6:before {
    position: absolute;
    content: "";
    left: -1px;
    top: 11px;
    width: 32px;
    height: 1px;
    background: #0C59DB;
}
/*style three*/
.section_main_title1 h2 {
    font-size: 42px;
    color: #232323;
    line-height: 50px;
}
.section_sub_title1 h5 {
    font-size: 18px;
    color: var(--primary);
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 0 3px 0px;
    transition: .5s;
}

.section_sub_title1 h5:before {
    position: absolute;
    content: "";
    z-index: -1;
    right: -10px;
    top: -2px;
    background: #CEDEF8;
    width: 30px;
    height: 26px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 65% 100%);
    transition: .5s;
}
/*section title three hover*/
.section_sub_title1 h5:hover {
    padding-left: 14px;
}
.section_sub_title1 h5:hover:before {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    width: 103%;
}
/*section title slider18*/

.style-two .section_sub_title h5 {
    letter-spacing: 0;
    color: var(--primary);
    font-size: 18px;
    position: relative;
    z-index: 1;
    font-weight: 600;
    text-transform: capitalize;
    padding: 0 0 16px 24px;
}
.style-two .section_sub_title h5:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 7px;
    top: 8px ;
    right: inherit ;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: var(--primary);
}
.style-two .section_sub_title h5:after {
    position: absolute;
    content: "";
    left: 1px;
    top: 3px;
    right: inherit;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: rgba(35,181,255,0.7019607843137254);
}
.style-two .section_main_title h1 {
    font-weight: 800;
    margin: 0;
    font-size: 38px;
    line-height: 1.3;
}
.style-two .section_content_text p {
    font-size: 16px;
    padding: 16px 0 0;
}
/*section title before center*/
.style-two.up .section_sub_title h5:before {
    left: -104px;
    right: 0;
    margin: auto;
}
.style-two.up .section_sub_title h5:after {
    left: -114px;
    right: 0;
    margin: auto;
}
.section_sub_title span {
    position: relative;
    z-index: 1;
}
.style-two.up .section_sub_title span:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 71px;
    top: 8px;
    right: inherit;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: var(--primary);
}
.style-two.up .section_sub_title span:after {
    position: absolute;
    content: "";
    left: 74px;
    top: 3px;
    right: inherit;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: rgba(35,181,255,0.7019607843137254);
}
/*End section title*/

.em_bar {
    background: #9B50DB none repeat scroll 0 0;
    height: 2px;
    margin: 0 auto 5px;
    width: 62px;

}
.text_left .em_bar {
    margin: 0;
}
.em_bar_bg {
    height: 5px;
    width: 90px;
    background: #cbbded;
    margin: 20px auto;
    position: relative;
    border-radius: 30px;
}
.em_bar_bg:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--primary);
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}
@-webkit-keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(88px);
        transform: translateX(88px);
    }
}

@keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(88px);
        transform: translateX(88px);
    }
}
/*about area style two*/
.about_area {
    position: relative;
}
.up.single_about_thumb {
    position: relative;
    left: -92px;
}
.style-three .single_about_thumb {
    position: relative;
    left: -92px;
}
.style-four .single_about_thumb {
    position: relative;
    left: -92px;
}
.style-five .single_about_thumb {
    position: relative;
    left: -92px;
}
.em-about-thmub-inner {
    position: absolute;
    bottom: -60px;
    left: -90px;
}
.em-about-thmub-inner1 {
    position: absolute;
    top: -45px;
    left: -60px;
}
.em-about-thmub-inner2 {
    position: absolute;
    top: -35px;
    left: -35px;
}
/*about right*/
.em-about-border-box {
    border-top: 1px solid rgba(35,35,35,0.10196078431372549);
    border-bottom: 1px solid rgba(35,35,35,0.10196078431372549);
    padding: 20px 0 28px;
}

.em-about-icon {
    float: left;
    margin-right: 20px;
}
.em-about-title h3 {
    font-size: 20px;
    line-height: 28px;
}
/*about button*/
.abou-button1 a {
    background: linear-gradient(to left, #2475FC, #1129B9 100%);
    padding: 14px 30px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
}
.abou-button1 a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background: #00247E;
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
}
.abou-button1 a:hover:before {
    width: 180%;
}
.abou-button1 a:hover {
    color: #fff;
}
/*icons two*/
.em-about-icons {
    float: left;
    margin-right: 20px;
}
.em-about-icons i {
    font-size: 30px;
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border: 1px solid rgba(35,35,35,0.10196078431372549);
    border-radius: 50%;
    text-align: center;
    color: #1029B9;
}
.em-about-title2 p {
    margin-bottom: 0;
}
.em-about-title2 h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 2px;
}
/*about shape*/
.shape-thumb {
    position: absolute;
    left: 33px;
    top: -164px;
    z-index: -1;
}
/*about bottom atea */

.happy-client-title p {
    font-size: 18px;
    color: #232323;
    font-weight: 700;
    position: relative;
    z-index: 1;
}
.happy-client-title p:before {
    position: absolute;
    content: "";
    right: 5px;
    top: 15px;
    width: 60px;
    height: 2px;
    background-color: rgba(12,90,219,0.25098039215686274);
}
.brand-thumb {
    text-align: right;
}
/*about area style three*/
.style-three.about_area {
    background: #F4F8FD;
    padding: 105px 0 170px;
}

.abou-button2 a {
    background: linear-gradient(to left, #0073ab, var(--primary) 100%);
    padding: 13px 35px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
}
.abou-button2 a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background: var(--primary);
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
}
.abou-button2 a:hover:before {
    width: 180%;
}
.abou-button2 a:hover {
    color: #fff;
}
/*about area four*/
.style-four.about_area {
    padding: 105px 0 105px;
    background: #F4F5FA;
    z-index: 11;
}
.row.contact-shape {
    position: relative;
    z-index: 1;
}
/*em about counter box*/
.em-about-counter {
    border-top: 1px solid var(--primary);
    margin: 45px 0 0;
    padding-top: 35px;
}
/*em dot*/
.em-dot {
    position: relative;
    z-index: 1;
    top: 13px;
}
.em-dot:before {
    position: absolute;
    content: "";
    z-index: 2;
    left: 20px;
    top: 0;
    width: 6px;
    height: 6px;
    background: var(--primary);
    border-radius: 5px;
}
.em-dot:after {
    position: absolute;
    content: "";
    z-index: 1;
    left: 20px;
    top: 15px;
    width: 6px;
    height: 6px;
    background: var(--primary);
    border-radius: 5px;
}
.em-dot span {
    position: relative;
    z-index: 1;
}
.em-dot span:before {
    position: absolute;
    content: "";
    left: 4px;
    top: 0;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: var(--primary);
}
.em-dot span:after {
    position: absolute;
    content: "";
    z-index: -1;
    top: -4px;
    right: -32px;
    width: 32px;
    height: 30px;
    background: #DBE5F6;
    clip-path: polygon(55% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: .5s;
}
/*about counter title*/

.em-about-counter-title {
    padding: 0 0 0 50px;
}
.em-about-counter-title h3 {
    font-size: 42px;
    position: relative;
    top: -15px;
    display: inline-block;
}
.em-about-counter-title span {
    font-size: 42px;
    color: #232323;
    font-weight: 700;
    display: inline-block;
    position: relative;
    top: -15px;
}
.em-about-counter-title h5 {
    font-size: 18px;
    margin: 0 0 0 0;
    font-weight: 500;
    position: relative;
    top: -10px;
}

/*em about service*/
.em-dot span:hover:after {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

/*em shape*/
.em-about-shape {
    position: absolute;
    left: -360px;
    bottom: -26px;
    z-index: -1;
}
.em-about-shepe-1 {
    position: absolute;
    right: -217px;
    bottom: 0;
    animation: moveLeftBounce 3s linear infinite;
}
/*about area style four*/
.about_area.style-five {
    background-repeat: no-repeat;
    background-position: center;
    padding: 115px 0 100px;
    background-size: cover;
}

.style-five .single_about_thumb_inner img {
    width: inherit;
}
/*about right*/
.style-five .em-about-icon-box {
    background: #f6f7ff;
    padding: 15px 30px 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.style-five .em-about-icon-box:before {
    top: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: "";
    z-index: -3;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    transition: 500ms all ease;
    background: linear-gradient(10deg,#1129b9 ,#2474fb ) border-box;
    border-radius: 8px;
}
.style-five .em-about-icon-box:after {
    top: 2px;
    left: 2px;
    content: "";
    z-index: -3;
    width: 98.5%;
    height: 96.5%;
    position: absolute;
    background-color: #F6F7FF;
    border-radius: 5px;
}
.style-five .em-about-icon-box:hover:before {
    width: 100%;
}
.style-five .em-about-icon-box1 {
    background: #FFF3EC;
    padding: 15px 30px 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.style-five .em-about-icon-box1:before {
    top: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: "";
    z-index: -3;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    transition: 500ms all ease;
    background: linear-gradient(10deg,#1129b9 ,#2474fb ) border-box;
    border-radius: 8px;
}
.style-five .em-about-icon-box1:after {
    top: 2px;
    left: 2px;
    content: "";
    z-index: -3;
    width: 98.5%;
    height: 96.5%;
    position: absolute;
    background-color: #FFF3EC;
    border-radius: 5px;
}
.style-five .em-about-icon-box1:hover:before {
    width: 100%;
}
.style-five .em-about-icon {
    float: left;
    margin-right: 20px;
    margin-top: 15px;
}


/*about shape*/
.style-five .em-about-thmub-inner2 {
    position: absolute;
    top: -75px;
    left: -75px;
    z-index: -1;
    animation: moveLeftBounce 3s linear infinite;
}
/*about-area style five upper*/

.about_area.style-five.upper .section_content_text p {
    font-size: 16px;
    padding: 16px 0 0;
    margin-bottom: 5px;
}
/*about right*/
.about_area.style-five.upper .em-about-icon span i {
    width: 23px;
    height: 23px;
    line-height: 23px;
    background: linear-gradient(131deg, #1129b9 0%, #2473fb 100%);
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    font-size: 17px;
}
.about_area.style-five.upper .em-about-icon span h6 {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #232323;
}
/*about special button */
.about_area.style-five.upper .about-special-button {
    padding: 35px 0 0;
}
.about_area.style-five.upper .about-button-text {
    display: inline-block;
}
.about_area.style-five.upper .about-button-text p {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0 0 0 50px;
}
.about_area.style-five.upper .about-button-text p:before {
    position: absolute;
    content: "";
    left: 2px;
    top: 14px;
    background: var(--primary);
    width: 40px;
    height: 2px;
}
.about_area.style-five.upper .about-button2 {
    display: inline-block;
    padding-left: 10px;
}
.about_area.style-five.upper .about-button2 a {
    display: inline-block;
    text-decoration: underline;
}
.about_area.style-five.upper .about-button2 a i {
    display: inline-block;
    font-size: 18px;
    position: relative;
    top: 3px;
}
/*about shape*/
.about_area.style-five.upper .em-about-thmub-inner1 {
    position: absolute;
    top: 13px;
    left: 200px;
}
.about_area.style-five.upper .em-about-thmub-inner2 {
    position: absolute;
    top: -22px;
    left: -22px;
    z-index: -1;
    animation: moveLeftBounce 3s linear infinite;
}
/*====================================================
--<	Techno brand Area Css -->
======================================================*/

.row.brand-bg3 {
    border-radius: 5px;
    filter: drop-shadow(0px 10px 20px rgba(12,90,219,0.07));
    background-color: #ffffff;
    padding: 86px 27px 84px;
    position: relative;
    z-index: 1;
    top: -103px;
}
.brand-img {
    transition: .5s;
}
.brand-img img:hover {
    filter: invert(15%) sepia(32%) saturate(77%) hue-rotate(6deg) brightness(11%) contrast(60%);
}
/*====================================================
--< Techno Counter Area Css -->
======================================================*/
.counter-area {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 70px 0 45px;
}
.row.counter-shap {
    position: relative;
    z-index: 1;
}
/*counter title*/
.counter-title h1 {
    font-size: 48px;
    color: #ffffff;
    font-weight: 800;
    display: inline-block;
}
.counter-title h6 {
    color: #fff;
    font-weight: 600;
}
.counter-title span {
    font-size: 48px;
    color: #fff;
    font-weight: 800;
    display: inline-block;
}
/*em border*/
.ems-border {
    width: 36px;
    height: 36px;
    border: 2px solid #33dbff;
    border-radius: 30px;
    position: relative;
    z-index: -1;
    top: -66px;
    left: -7px;
}
.em-border1 {
    width: 36px;
    height: 36px;
    border: 2px solid #ffcd33;
    border-radius: 30px;
    position: relative;
    z-index: -1;
    top: -66px;
    left: -7px;
}
.em-border2 {
    width: 36px;
    height: 36px;
    border: 2px solid #ff6a33;
    border-radius: 30px;
    position: relative;
    z-index: -1;
    top: -66px;
    left: -7px;
}
/*countner shape images*/
.counter-shape-thumb {
    position: absolute;
    left: -178px;
    top: -108px;
    animation: moveLeftBounce 3s linear infinite;
}
.counter-shape-thumb1 {
    position: absolute;
    right: -77px;
    bottom: -69px;
}

/*====================================================
--< Techno Flipbox Top Feature Area Css -->
======================================================*/
.nagative_margin {
    position: relative;
    margin-top: -100px;
}
.nagative_margin3 {
    position: relative;
    margin-top: -100px;
    z-index: 99;
}
.nagative_margin4 {
    position: relative;
    margin-top: -225px;
}
.nagative_margin5 {
    position: relative;
    margin-top: -70px;
}
.top_feature .techno_flipbox .techno_flipbox_font {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.10);
    border: 0;
}
.top_feature .techno_flipbox .techno_flipbox_back {
    background: var(--primary);
    box-shadow:0 5px 20px rgba(0,0,0,0.10);
    border-radius:8px;
}
.top_feature .techno_flipbox .techno_flipbox_inner {
    padding: 0 30px;
}
.top_feature .techno_flipbox .techno_flipbox_back::before {
    display:none;
}
.top_feature .icon {
    color: var(--primary);
    font-size: 45px;
    display: inline-block;
    line-height: 46px;
}
.top_feature .techno_flipbox .flipbox_title h3 {
    font-size: 20px;
    color: #232323;
    font-weight: 700;
    font-family: Muli;
}
/*feature style two*/
.em-feature-single-box {
    background: #E5EEFF;
    padding: 23px 40px 40px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}
.em-feature-single-box:before {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(to left, #2475FC, #1129B9);
    border-radius: 10px;
    transition: .5s;
}
.em-feature-single-box:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: .5s;
}
/*box inner*/
.feature-single-box-inner {
    position: relative;
    z-index: 1;
}
.feature-single-box-inner:before {
    position: absolute;
    content: "";
    z-index: 1;
    right: -117px;
    top: 19px;
    width: 80%;
    height: 78%;
    background-repeat: no-repeat;
    background-position: center;
}
.feature-single-box-inner1 {
    position: relative;
    z-index: 1;
}
.feature-single-box-inner1:before {
    position: absolute;
    content: "";
    z-index: 1;
    right: -117px;
    top: 19px;
    width: 80%;
    height: 78%;
    background-repeat: no-repeat;
    background-position: center;
}
/*em-feature-title*/
.em-feature-title h2 {
    font-size: 24px;
    font-weight: 700;
    transition: .5s;
}
.em-feature-title p {
    font-size: 16px;
    padding: 17px 0 16px;
    transition: .5s;
}
.em-feature-button a {
    display: inline-block;
    color: #fff;
    font-weight: 400;
    padding: 8px 30px;
    background: linear-gradient(to left, #2475FC, #1129B9);
    border-radius: 5px;
    transition: .5s;
    border: 1px solid transparent;
}
/*feature hover*/
.em-feature-single-box:hover:before {
    left: 0;
    width: 100%;
}
.em-feature-single-box:hover .em-feature-title h2, .em-feature-single-box:hover .em-feature-title p {
    color: #fff;
}
.em-feature-single-box:hover .em-feature-button a {
    background: transparent;
    border: 1px solid #fff;
}
/*em feature style two*/
.feature-extra-single-box {
    position: relative;
    z-index: 1;
    border-radius: 5px;
    filter: drop-shadow(0 0 30px rgba(188,195,208,0.2));
    overflow: hidden;
}
.style-two .feature-extra-single-box:before {
    position: absolute;
    content: "";
    z-index: -1;
    top: 104px;
    left: 9px;
    width: 222px;
    height: 236px;
    border-radius: 5px;
    background: #fff;
    transition: .5s;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 92%);
}
.style-two .em-feature-single-box {
    padding: 68px 25px 1px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    z-index: 222;
    margin-bottom: 30px;
    filter: drop-shadow(0 0 30px rgba(188,195,208,0.2));
    background: #ffffff;
}
.em-feature-single-box:before {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    transition: .5s;
}
.em-feature-single-box:after {
    position: absolute;
    content: "";
    z-index: -1;
    top: -57px;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: .5s;
}
.feature-single-box-inner2 {
    position: relative;
    z-index: 1;
}
.style-two .feature-single-box-inner:before {
    position: inherit;
    content: "";
    z-index: -1;
    right: 25px;
    top: -61px;
    width: 80%;
    height: 78%;
    background-repeat: no-repeat;
    background-position: center;
}
.style-two .feature-single-box-inner2:before {
    position: inherit;
    content: "";
    z-index: -1;
    right: 25px;
    top: -61px;
    width: 80%;
    height: 78%;
    background-repeat: no-repeat;
    background-position: center;
}
.feature-top-icon {
    position: relative;
    left: 66px;
    top: -10px;
}
.style-two .em-feature-title h2 {
    font-size: 22px;
    font-weight: 700;
    transition: .5s;
}
/*feature shape*/
.row.feature-shape {
    position: relative;
    z-index: 1;
}
.feature-shape1 img {
    width: 90%;
}
.feature-shape1 {
    position: absolute;
    right: 152px;
    top: 35px;
}
.feature-shape2 {
    position: absolute;
    bottom: -410px;
    left: -49px;
}
/*all hover*/
.feature-extra-single-box:hover:before {
    background: linear-gradient(to left, #2475FC, #1129B9);
}

/*End feature area*/

/* New Flip Box Style */
.flip-box {
    background-color: transparent;
    width: 100%;
    height: 225px;
    perspective: 1000px;
    margin-bottom: 30px;
}
.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-box-front {
    background-color: var(--primary);
    color: black;
    border-radius:5px;
}
.flipbox-icon {
    padding-top: 22px;
}
.flip-box-content h2 {
    font-size: 23px;
    color: #fff;
}
.flip-box-content p {
    color: #fff;
    padding: 10px 15px;
}
.flipbox-icon .icon {
    font-size: 35px;
    color: #fff;
}

.flip-box-back {
    background-color: #fff;
    color: white;
    transform: rotateY(180deg);
    border-radius: 5px;
    box-shadow: 0px 0px 25px 17px rgb(0 0 0 / 15%);
    padding: 10px 0 0;
}
.flip-box-back-content h2 {
    font-size: 23px;
    color: var(--primary);
}
.flip-box-back-content p {
    color: #232323;
    padding: 10px 15px 3px;
}
.flip-box-back-content a {
    padding: 9px 30px;
    color: #FFFFFF;
    background-color: var(--primary);
    border-radius: 4px 4px 4px 4px;
    display: inline-block;
}

.flipbox_area.odoo .techno_flipbox_font .icon {
    color: #fff;
    font-size: 40px;
    background: transparent;
}
.flipbox_area.odoo .techno_flipbox .flipbox_title h3 {
    margin: 0;
}
.flipbox_area.odoo .techno_flipbox .techno_flipbox_back::before {
    background: var(--primary);
}
.flipbox_area.odoo .techno_flipbox .techno_flipbox_back .flipbox_button a:hover {
    color: #fff;
    border-color: #1f1c51;
    background: #1f1c51;
}
.flipbox_area.odoo .techno_flipbox_back .flipbox_desc p {
    color: #fff;
    margin: 0 0 5px;
}
.flipbox_area.odoo .section_title.white .section_main_title h1, .section_title.white .section_main_title h2 {
    font-size: 39px;
}

/* Top Feature Style Two */

.top_feature.two .techno_flipbox .techno_flipbox_font, .top_feature.two .techno_flipbox .techno_flipbox_back {
    min-height: 223px;
}
.top_feature.two .techno_flipbox .techno_flipbox_back {
    background: #fff;
}
.top_feature.two .techno_flipbox .techno_flipbox_inner {
    padding: 0 24px;
}
.top_feature.two .icon {
    color: #fff;
    font-size: 40px;
    line-height: 90px;
    height: 90px;
    width: 90px;
    background: var(--primary);
    border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
}
.top_feature.two .techno_flipbox .flipbox_title h3 {
    padding-bottom: 0;
    font-size:21px;
}
.top_feature.two .techno_flipbox .techno_flipbox_back .flipbox_title h3 {
    color: #232323;
    font-size:21px;
}
.top_feature.two .techno_flipbox_back .flipbox_desc p {
    color: #616161;
}
/* Feature Style Three */
.feature_style_three {
    position: relative;
    border-radius:10px;
}
.feature_style_three:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(41,55,66,0.6);
    content: "";
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border-radius: 10px;
}
.feature_style_tree_thumb img {
    width: 100%;
    border-radius:10px;
}
.feature_style_tree_content {
    position: absolute;
    left: 0;
    bottom: -67px;
    padding: 0 50px 0 38px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.feature_style_thre_icon .icon {
    color: #fff;
    font-size: 50px;
}
.feature_style_three_title h4 {
    color: #fff;
    font-weight: 700;
}
.feature_style_three_text {
    color: #fff;
    opacity:0;
    transition:.3s;
}
.feature_style_three_butoon a {
    color: #fff;
    text-transform: uppercase;
    opacity:0;
    transition:.3s;
    font-size:14px;
}
.feature_style_three:hover:before {
    background: rgba(12,90,219,0.85);
}
.feature_style_three:hover .feature_style_three_text,
.feature_style_three:hover .feature_style_three_butoon a{
    opacity:1;
}
.feature_style_three:hover .feature_style_tree_content {
    bottom: 45px;
}

/* Feature Style Four */
.feature_style_four {
    background: #fff;
    text-align: center;
    box-shadow: 6px 5px 30px 0px rgba(0,0,0,0.12);
    border-radius: 7px;
    transition: .5s;
    padding:50px 45px;
}
.feature_style_four.active{
    background: var(--primary);
}
.feature_style_four_icon i {
    background: #EDF4FC;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    line-height: 130px;
    font-size: 40px;
    color: var(--primary);
    transition: .4s;
    display: inline-block;
}
.feature_style_four_title h4 {
    font-weight: 700;
    transition:.5s;
}
.feature_style_four_text p{
    transition:.5s;
}
.feature_style_four_button a{
    transition:.5s;
}
.feature_style_four.active .feature_style_four_title h4,
.feature_style_four.active .feature_style_four_text p,
.feature_style_four.active .feature_style_four_button a {
    color:#fff;
}
.feature_style_four_button a i {
    margin-left: 6px;
}
.feature_style_four:hover{
    background: var(--primary);
    transform:translateY(-10px);
}
.feature_style_four:hover .feature_style_four_title h4,
.feature_style_four:hover .feature_style_four_text p,
.feature_style_four:hover .feature_style_four_button a{
    color:#fff;
}
/* Feature Style Five */
.feature_style_five{
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-align: center;
    padding: 35px 30px 10px;
    box-shadow: 0 10px 29.7px 0.3px rgba(225,225,225,.64);
    position: relative;
    border-radius:0px 50px 50px 50px;
    background:#fff;
}
.feature_style_five::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index:1;
}
.feature_style_five_cion i {
    color: var(--primary);
    background-color: #eff2f7;
    border-radius: 0 40px 40px;
    font-size: 25px;
    line-height: 70px;
    height:70px;
    width:70px;
    transition:.5s;
}
.feature_style_five_title h4 {
    font-size: 22px;
    font-weight: 700;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.feature_style_five_text p{
    transition:.5s;
}
.feature_style_five:hover{
    background:var(--primary);
    transform:translateY(-10px);
}
.feature_style_five:hover .feature_style_five_title h4,
.feature_style_five:hover .feature_style_five_text p{
    color:#fff;
}
/* Feature Style Six */
.single_feature_six {
    background: rgba(0,0,0,.67);
    padding: 20px 20px 15px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}
.single_feature_six::before {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: var(--primary);
    content: "";
    transition:.5s;
    z-index:-1;
}
.single_feature_six_icon {
    float: left;
    margin-top: 12px;
}
.single_feature_six_icon i {
    font-size: 40px;
    color: #fff;
}
.single_feature_six_content.white {
    overflow: hidden;
}
.single_feature_six_content p {
    padding-top: 10px;
}
.single_feature_six:hover::before{
    width:100%;
}
/* Feature Style Seven */
.single_feature_seven {
    text-align: center;
    padding: 40px 24px;
    border: 1px solid #e4ecf9;
    position: relative;
    z-index: 1;
    background: #fff;
    margin-bottom:30px;
}

.single_feature_seven:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    content: "";
    background: #adc7f1;
    transition: .3s;
    z-index: -1;
}
.single_feature_six_seven_icon .icon {
    font-size: 55px;
    color: var(--primary);
    transition:.5s;
}
.single_feature_seven_content h4 {
    transition: .5s;
    padding-top: 15px;
}
.single_feature_seven_content p {
    padding-top: 8px;
    transition: .5s;
}
.single_feature_seven:hover:before{
    width: 100%;
    height: 100%;
    background:var(--primary);
}
.single_feature_seven:hover .single_feature_six_seven_icon .icon,
.single_feature_seven:hover .single_feature_seven_content h4,
.single_feature_seven:hover .single_feature_seven_content p{
    color:#fff;
}
/* Feature Style Eight */

.feature_style_eight{
    background: #fff;
    box-shadow: 0 20px 50px 5px #e9eef7;
    text-align: center;
    padding: 60px 40px 45px;
    transition:.5s;
    border-radius:7px;
}
.feature_style_eight:hover{
    box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
}
.feature_style_eight_icon{
    position: relative;
    display: inline-block;
    font-size: 60px;
    width: 130px;
    height: 130px;
    line-height: 120px;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    transition: all 500ms ease;
    background: #fff;
    box-shadow: 0 20px 50px 5px #e9eef7;
    color:var(--primary);
}
.feature_style_eight_icon .icon {
    display: inline-block;
}
.feature_style_eight:hover .feature_style_eight_icon{
    color:#fff;
}
.feature_style_eight:hover .feature_style_eight_icon{
    box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
}
.feature_style_eight_icon:before {
    position: absolute;
    content: '';
    background: rgba(12,90,219,0.10);
    width: 100%;
    height: 100%;
    left: -8px;
    top: -5px;
    z-index: -1;
    border-radius: 50%;
    box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2);
    transition:.5s;
}
.feature_style_eight:hover .feature_style_eight_icon:before{
    background: rgba(12,90,219,0.80);
}
.anim-icon{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    -webkit-animation: service_hexagon 3s infinite linear;
    -moz-animation: service_hexagon 3s infinite linear;
    -o-animation: service_hexagon 3s infinite linear;
    animation: service_hexagon 3s infinite linear;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}
.feature_style_eight:hover .anim-icon{
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    -o-animation-play-state: running;
    animation-play-state: running;
}
.anim-icon .icon-1{
    position: absolute;
    left: -25px;
    top: 60px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-animation: zoom-fade-two 5s infinite linear;
    animation: zoom-fade-two 5s infinite linear;
    background:rgba(99, 119, 238, 0.8);
}
.anim-icon .icon-2{
    position: absolute;
    left: 10px;
    top: -20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-animation: zoom-fade-two 4s infinite linear;
    animation: zoom-fade-two 4s infinite linear;
    background:rgba(99, 119, 238, 0.8);
}
.anim-icon .icon-3{
    position: absolute;
    top: 15px;
    right: 0px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-animation: zoom-fade-two 3s infinite linear;
    animation: zoom-fade-two 3s infinite linear;
    background:rgba(99, 119, 238, 0.8);
}
.feature_style_eight_title a {
    font-size: 24px;
    font-weight: 700;
}
/* Feature Style Nine */
.style_nine .feature_style_eight {
    padding: 45px 24px 25px;
}
.style_nine  .feature_style_eight_icon {
    font-size: 40px;
    width: 100px;
    height: 100px;
    line-height: 95px;
    border-radius: 50%;
}
.style_nine .feature_style_eight_title a {
    font-size: 20px;
}
/*====================================================
--<	Techno Flipbox Area Css -->
======================================================*/
.techno_flipbox_font {
    border: 1px solid var(--primary);
    border-radius: 8px;
}
.flipbox .section_main_title h1 {
    color: #fff;
}
.flipbox .section_sub_title h6 {
    color: #fff;
}
.flipbox .section_title{
    margin-bottom:30px;
}
.techno_flipbox {
    perspective: 1000px;
    position: relative;
    transform-style: preserve-3d;
    -webkit-perspective: 1000px;
    -webkit-transform-style: preserve-3d;
    z-index: 1;
}
.techno_flipbox .techno_flipbox_font,
.techno_flipbox .techno_flipbox_back {
    min-height: 250px;
    height: auto;
    box-shadow: 0px 1px 51.3px 5.7px rgba(137,143,156,.15);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateX(0);
    -webkit-transform: rotateX(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: transform .7s ease, -webkit-transform .7s ease;
    position: relative;
}
.techno_flipbox .techno_flipbox_back::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,transparent -80%,#00247e);
}
.icon {
    font-size: 45px;
    line-height: 45px;
}
.techno_flipbox .techno_flipbox_inner {
    text-align: center;
    padding: 0 25px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    perspective: inherit;
    -webkit-perspective: inherit;
    outline: transparent solid 1px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
    transform: translateY(-50%) translateZ(60px) scale(0.94);
    z-index: 2;
}
.techno_flipbox_icon {
    font-size: 45px;
    text-align: center;
    display: inline-block;
    color: #fff;
    overflow: hidden;
}
.techno_flipbox_font .flipbox_desc p {
    color: #fff;
}
.techno_flipbox .flipbox_title h3 {
    font-size: 22px;
    padding-bottom: 13px;
    text-transform: capitalize;
    -webkit-transition: all 0.7s ease 0s;
    transition: all 0.7s ease 0s;
    color: #fff;
}
.techno_flipbox .techno_flipbox_back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 2px;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.techno_flipbox .techno_flipbox_back .flipbox_button a {
    text-transform: capitalize;
    margin-top: 22px;
    background: #fff;
    color: var(--primary);
    display: inline-block;
    padding: 4px 25px;
    border-radius: 5px;
    border: 2px solid #fff;
    transition: .5s;
}
.techno_flipbox_back .flipbox_desc p{
    color: #fff;
}
.techno_flipbox .techno_flipbox_back .flipbox_button a i {
    display: none;
}
.techno_flipbox .techno_flipbox_back .flipbox_title h3 {
    padding-bottom: 16px;
    padding-top: 0;
    color: #fff;
    margin-bottom: 0;
}
.techno_flipbox .techno_flipbox_font .flipbox_desc,
.techno_flipbox .techno_flipbox_back .flipbox_desc {
    color: #fff;
}


.flipbox_area.top_feature.upper .techno_flipbox_font .icon {
    font-size: 45px;
    color: var(--primary);
    background: transparent;
}



/* Flipbox Hover Css */
.techno_flipbox:hover .techno_flipbox_font {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}
.techno_flipbox:hover .techno_flipbox_back {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
}
.techno_flipbox .techno_flipbox_back .flipbox_button a:hover {
    color: #fff;
    border-color: var(--primary);
    background: var(--primary);
}

.pages .techno_flipbox .techno_flipbox_font .flipbox_title h3 {
    color: #444;
}
.pages .techno_flipbox_font .flipbox_desc p {
    color: #666;
}
/*====================================================
--<	Techno About Area Css -->
======================================================*/
.about_icon {
    float: left;
}
.about_icon .icon {
    font-size: 40px;
    color: var(--primary);
}
.singel-about-content {
    overflow: hidden;
}
.singel-about-content.boder {
    border-left: 1px solid #ddd;
}
.singel-about-content h5 {
    font-weight: 700;
    margin: 0;
}
.single_about_thumb_inner img {
    width: 100%;
}
.about_thumb img {
    width: 100%;
}
.about_skill_thumb img {
    width: 100%;
}
.skill-wrapper h6 {
    margin-bottom: 8px;
}
.pd_gap {
    background: #fff;
    padding: 18px 0 18px;
    border-radius: 8px;
}
.home11 .about_icon .icon {
    font-size: 20px;
    margin-top: -8px;
}
/* Experience Css */

.headingh_experience_number h1 {
    font-size: 136px;
    display: inline;
    font-weight: 800;
    color: var(--primary);
    float: left;
    line-height: 1;
    margin-top: -14px;
    margin-right: 15px;
}
.heading_experience_title h2 {
    font-size: 36px;
    font-weight: 800;
    line-height: 1;
    color: #232323;
    padding-top: 11px;
    display: inline;
}
.heading_experience_text p {
    font-size: 18px;
}
.experience .main_video {
    position: relative;
    margin-top: -285px;
}
/* Data Science About */
.data_science_about_icon .icon {
    height: 90px;
    width: 90px;
    line-height: 90px;
    font-size: 40px;
    color: var(--primary);
    position: relative;
    text-align: center;
    box-shadow: 0 20px 50px 5px #e9eef7;
    border-radius: 50%;
    z-index: 1;
    background: #fff;
}
.data_science_about_icon .icon:before {
    position: absolute;
    content: '';
    background: rgba(12,90,219,0.10);
    width: 100%;
    height: 100%;
    left: -8px;
    top: -5px;
    z-index: -1;
    border-radius: 50%;
    box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2);
    transition: .5s;
}
.singel_about_left_inner:hover .data_science_about_icon .icon:before{
    background: rgba(12,90,219,0.90);
}
.singel_about_left_inner:hover .data_science_about_icon .icon{
    color:#fff;
}
.about_content_machine span {
    display: block;
    margin-bottom: 8px;
}
.about_content_machine span i {
    color: #fff;
    height: 20px;
    width: 20px;
    line-height: 20px;
    background: var(--primary);
    text-align: center;
    font-size: 12px;
    border-radius: 3px;
}
.style_two .singel-about-content h5 {
    font-weight: 800;
    margin-top: 10px;
    font-size: 22px;
}
.style_two .about_icon .icon {
    color: #FFFFFF;
    background: var(--primary);
    border-radius: 4px 4px 4px 4px;
    height: 75px;
    width: 75px;
    font-size: 45px;
    line-height: 75px;
    text-align: center;
}
/*====================================================
--< Techno subscribe area Css -->
======================================================*/
.subscribe-area {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 85px 0 55px;
}
.call-do-action-title p {
    color: #fff;
    padding: 25px 0 37px;
}
.subscribe-area .form_box input {
    width: 100%;
    height: 54px;
    padding-left: 15px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #232323;
    transition: .5s;
}
.subscribe-area .quote_btn button {
    background: #33B9FF;
    color: #fff;
    font-weight: 600;
    border: 0;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    padding: 15px 35px;
    border-radius: 7px;
    transition: .3s;
}
/*subscribe shape*/
.subscribe-area .call-do-thumb1 {
    position: absolute;
    z-index: 1;
    bottom: -258px;
    left: -350px;
}
.subscribe-area .call-do-thumb {
    position: absolute;
    z-index: 1;
    bottom: 55px;
    left: -40px;
}
.subscribe-area .call-do-thumb2 {
    position: absolute;
    right: 6px;
    bottom: -93px;
}
/*====================================================
--< Techno call do action Css -->
======================================================*/
.call-do-action {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/*call do action title*/
.call-do-action-content {
    position: relative;
    z-index: 1;
}
.call-do-action-title h1 {
    font-size: 38px;
    color: #fff;
    line-height: 38px;
    font-weight: 800;
}

/*call do button*/
.call-button a {
    background: #34BBFF;
    padding: 12px 27px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
    margin-top: 45px;
}
.call-button a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background: #00247E;
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
}
.call-button a i {
    font-size: 18px;
    position: relative;
    top: 3px;
}

/*call do all hover*/
.call-button a:hover:before {
    width: 180%;
}
/*call do shape*/
.call-do-thumb {
    position: absolute;
    z-index: 1;
    bottom: 65px;
    left: 85px;
}
.call-do-thumb1 {
    position: absolute;
    z-index: 1;
    bottom: -185px;
    left: -170px;
}
.call-do-thumb2 {
    position: absolute;
    right: 135px;
    bottom: 6px;
}
.call-do-thumb3 {
    position: absolute;
    right: 28px;
    top: -12px;
}
.call-do-thumb4 {
    position: absolute;
    right: -182px;
    top: -50px;
}
/*call do area style two*/
.style-two.call-do-action {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.style-two.call-do-action .section_content_text p {
    font-size: 16px;
    padding: 16px 0 0;
    color: #232323;
}
.style-two .call-do-icon i {
    display: inline-block;
    width: 64px;
    height: 64px;
    line-height: 64px;
    filter: drop-shadow(0px 10px 20px rgba(146,176,225,0.4));
    background-color: #ffffff;
    text-align: center;
    border-radius: 50%;
    font-size: 30px;
    color: var(--primary);
    margin-top: 13px;
}
.style-two .call-do-icon span {
    font-size: 28px;
    color: #232323;
    font-weight: 700;
    padding-left: 15px;
    display: inline-block;
}

/*call do button*/
.style-two .call-button a {
    background: #34BBFF;
    background-image: linear-gradient(131deg, #1129b9 0%, #2473fb 100%);
    padding: 14px 32px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 44;
    border-radius: 5px;
    color: #fff;
    margin-top: 40px;
}

/*call do shape thumb*/
.style-two.call-do-action .call-do-thumb4 {
    position: absolute;
    right: inherit;
    left: -290px;
    top: -50px;
}



/*====================================================
--< Techno prossess ber Css -->
======================================================*/
/*prossess ber*/
.prossess-ber-plugin span {
    color: #232323;
    font-weight: 600;
    font-size: 16px;
}
.barfiller {
    width: 100%;
    height: 8px;
    background: #4b4c59;
    position: relative;
    margin-bottom: 28px;
    margin-top: 11px;
    border-radius: 5px;
    filter: drop-shadow(0px 10px 10px rgba(60,115,206,0.2));
    background-color: rgba(12,90,219,0.2);
}
.barfiller .fill {
    display: block;
    position: relative;
    width: 0px;
    height: 100%;
    background: #333;
    z-index: 1;
}
.barfiller .tipWrap { display: none; }
.barfiller .tip {
    font-size: 16px;
    left: 0px;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
    top: -39px;
}
/*span.tip {
    display: none;
}*/
.stat-bar:nth-of-type(1) .stat-bar-rating {
    animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
}
span.fill {
    background: #0CD0B2 !important;
    border-radius: 5px;
}
span.fill.my-class {
    background: var(--primary) !important;
    border-radius: 5px;
}
span.fill.my-class2 {
    background: #FF6A33 !important;
    border-radius: 5px;
}

/*====================================================
--<	Techno Shape Images Css -->
======================================================*/


.single_slider_shape {
    position: relative;
}
.single_slider_shape_image {
    position: absolute;
    right: -250px;
    top: -499px;
    z-index: 1;
}
.single_slider_shape_image img {
    width: 90%;
}
.single_slider_rot_inner {
    position: absolute;
    bottom: -340px;
    left: 50%;
}
.single_slider_rot.two .single_slider_rot_inner {
    left: -45%;
}
.single_slider_sp {
    position: absolute;
    bottom: 370px;
    left: 79%;
}
.single_slider_sp img {
    width: 100%;
    opacity: 0.8;
}

.single_about_shape{
    position: relative;
}
.single_about_shape_thumb {
    position: absolute;
    bottom: -70px;
    z-index: -1;
    left: -94px;
}
.single_about_shape_thumb img {
    width: 70%;

}
.single_about_signesur_thumb {
    float: left;
}
.single_about_signesur_thumb img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
.border_ift {
    position:relative;
    z-index:1;
}
.border_ift:after {
    content: '';
    width: 536px;
    height: 475px;
    background: 0 0;
    position: absolute;
    bottom: 12px;
    right: -41px;
    z-index: -1;
    border: 15px solid #00000008;
    animation: bannerimage_translate 3s infinite;
    animation-play-state: running;
    animation-direction: alternate;
}
@keyframes bannerimage_translate{
    0%{transform:translateX(0px) translateY( 0px) }
    0%{transform:translateX(-30px) translateY( -12px) }
}

/* Home Three About Shape */
.video_thumb img {
    width: 100%;
}
.video_shape_thumb1 {
    position: absolute;
    top: 80px;
    z-index: -1;
    left: -16px;
}
.video_shape_thumb2 {
    position: absolute;
    top: 16px;
    right: 0;
    z-index: -1;
}
.video_shape_thumb3 {
    position: absolute;
    bottom: 50px;
    left: 0;
    z-index: -1;
}
.video_shape_thumb4 {
    position: absolute;
    bottom: 83px;
    right: -135px;
    z-index: -1;
}
.main_video {
    position: relative;
    margin-top: -175px;
}
.single-panel-thumb .main_video {
    position: relative;
    margin-top: -325px;
}
/* Data Science Shape Css */
.shape_thumb {
    position: relative;
}
.about_shape_thumb {
    position: absolute;
    bottom: 202px;
    right: 157px;
}
.about_shape_thumb2{
    position: absolute;
    right: 155px;
    bottom: 70px;
}
.about_shape_thumb_affiliate1 {
    position: absolute;
    bottom: 65px;
    left: 150px;
}
.about_shape_thumb_affiliate2 {
    bottom: 443px;
    position: absolute;
    left: 77px;
}
.about_shape_thumb_affiliate2 {
    bottom: 490px;
    position: absolute;
    left: 45px;
}
/* Affiliate Intelligent About */
.about_icon_box_inner i {
    height: 30px;
    width: 30px;
    line-height: 30px;
    background: var(--primary);
    text-align: center;
    color: #fff;
    border-radius: 3px;
    font-size: 15px;
    margin-right: 12px;
}
.about_icon_box_inner span {
    font-size: 16px;
    font-weight: 700;
    color: #232323;
}
/*====================================================
--<	Techno Service Area Css -->
======================================================*/
.service_style_one {
    background: #fff;
    border-radius:10px;
}
.service_style_one_icon i {
    width: 75px;
    height: 75px;
    line-height: 75px;
    background: #e8ecfc;
    border-radius: 100%;
    color: var(--primary);
    display: inline-block;
    border-radius: 33% 66% 70% 30%/49% 62% 38% 51%;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-align: center;
    font-size:35px;
}
.service_style_one_title h4 {
    font-weight: 700;
    color: var(--primary);
    position:relative;
}
.service_style_one_title h4:before {
    position: absolute;
    content: "";
    left: 0;
    top: 37px;
    height: 1px;
    width: 100px;
    background: #d1d1d1;
    margin: auto;
    right: 0;
}
.service_style_one_button a {
    color: #616161;
    border: 1px solid #e8ecfc;
    padding: 5px 23px;
    border-radius: 30px;
    display: inline-block;
    font-size: 16px;
}
.service_style_one_button a i {
    margin-left: 7px;
}
.service_style_one:hover .service_style_one_button a {
    background-color: var(--primary);
    color: #fff;
    border-color: var(--primary);
}
.service_style_one:hover .service_style_one_icon i {
    background-color: var(--primary);
    color: #fff;
}

/* Service Style Two */
.service_style_two_number {
    float: left;
}
.service_style_two_content {
    overflow: hidden;
}
.service_style_two_number h5 {
    font-weight: 700;
    color:var(--primary);
    position:relative;
}
.service_style_two_number h5:before {
    position: absolute;
    left: 34px;
    top: 14px;
    height: 2px;
    width: 32px;
    content: "";
    background: var(--primary);
}
.service_style_two_title h4 {
    font-weight: 700;
    transition:.3s;
}
.service_style_two_button a {
    color: var(--primary);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}
.service_style_two_button a i{
    margin-left:5px;
    transition:.3s;
}
.service_style_two_title h4:hover{
    color:var(--primary);
}
/* Service Style Three 8*/
.service_style_three{
    background: #fff;
    border-radius: 7px;
    box-shadow: 2px 10px 50px rgba(158,158,158,.25);
    border: 2px solid transparent;
    transition: .5s;
}
.service_style_three_icon i {
    width: 95px;
    height: 95px;
    line-height: 95px;
    background: var(--primary);
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-align: center;
    font-size: 30px;
}
.service_style_three_title h4 {
    font-size: 22px;
    font-weight: 700;
    transition:.3s;
}
.service_style_three_bt_icon {
    position: relative;
    margin-bottom: -28px;
}
.service_style_three_bt_icon a {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    border: 2px solid var(--primary);
    line-height: 50px;
    color: var(--primary);
    background: #fff;
    transition: .5s;
    display: inline-block;
}
.service_style_three:hover{
    border: 2px solid var(--primary);
}
.service_style_three_title h4:hover{
    color:var(--primary);
}
.service_style_three:hover .service_style_three_bt_icon a{
    background: var(--primary);
    color:#fff;
}

/* Service Style Four */
.service_style_four {
    background: #fff;
    border-radius: 7px;
    transition: .5s;
    box-shadow: 2px 10px 50px rgba(158,158,158,.25);
}
.service_style_four_icon i {
    font-size: 48px;
    display: inline-block;
    color: var(--primary);
    transition: .5s;
}
.service_style_four_title h4 {
    transition: .5s;
    font-size: 22px;
    font-weight:700;
}
.service_style_four_text p{
    transition:.5s;
}
.service_style_four_button a {
    color: var(--primary);
    transition:.5s;
}
.service_style_four_button a i {
    margin-left: 5px;
}
.service_style_four:hover{
    background: var(--primary);
    transform:translateY(-8px);
}
.service_style_four:hover .service_style_four_icon i,
.service_style_four:hover .service_style_four_title h4,
.service_style_four:hover .service_style_four_text p,
.service_style_four:hover .service_style_four_button a{
    color:#fff;
}
/* Service Style Five */
.service_style_five {
    border: 1px solid #d5c7c7;
    border-radius: 8px;
    background:transparent;
    transition:.5s;
}
.service_style_five_title h4 {
    font-size: 22px;
    font-weight: 700;
    transition:.5s;
}
.service_style_five_icon i{
    transition:.5s;
}
.service_style_five_text p{
    transition:.5s;
}
.service_style_five_button a {
    transition:.5s;
}
.service_style_five_button a i {
    margin-left: 5px;
}
.service_style_five:hover {
    border: 1px solid var(--primary);
    background:var(--primary);
    transform:translateY(-8px);
}
.service_style_five:hover .service_style_five_title h4,
.service_style_five:hover .service_style_five_icon i,
.service_style_five:hover .service_style_five_text p,
.service_style_five:hover .service_style_five_button a{
    color:#fff;
}

/* Service Style Six */
.single_service_inner {
    padding: 19px 0 5px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 1;
    transition: .5s;
    border-radius: 0 50px 50px 50px;
    margin-bottom: 30px;
    background: #fff;
}
.single_service_inner::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;
    background: var(--primary);
    content: "";
    z-index: -1;
    border-radius: 0 30px 30px;
    transition:.5s;
    border: 1px transparent;
}
.single_service_icon {
    float: left;
    margin-left: 15px;
    margin-right: 30px;
    margin-top: -5px;
}
.single_service_icon i {
    display: inline-block;
    text-align: center;
    font-size: 25px;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    color: #fff;
}
.single_service_content h4 {
    font-size: 16px;
    text-align: left;
    margin: 0 0 12px;
    transition: .5s;
}
.right .single_service_inner_thumb {
    margin-top: -130px;
    margin-left: 115px;
}
.right .single_service_brg {
    position: relative;
}
.right .single_service_brg_thumb {
    bottom: -66px;
    position: absolute;
    left: 25px;
}
.left .single_service_inner_thumb {
    margin-top: -144px;
    margin-left: 71px;
}
.left .single_service_brg {
    position: relative;
}
.left .single_service_brg_thumb {
    bottom: -66px;
    position: absolute;
    left: -16px;
}
.single_service_inner:hover .single_service_content h4{
    color:#fff;
}
.single_service_inner:hover{
    background:var(--primary);
}
.single_service_inner:hover::before{
    background:#fff;
}
.single_service_inner:hover .single_service_icon i{
    color:var(--primary);
}

/* Service Style Seven */

.service_style_seven {
    box-shadow:0 0 15px rgba(51,51,51,0.1);
    transition:.5s;
}
.service_style_seven_icon {
    float: left;
}
.service_style_seven_icon i {
    color: var(--primary);
    font-size: 40px;
    margin-top: 14px;
}
.service_style_seven_content {
    overflow: hidden;
}
.service_style_seven_title h4 {
    font-weight: 700;
}
.service_style_seven:hover{
    box-shadow:0 0 40px rgba(51,51,51,0.1);
}
/* Service Style Eight */
.service_style_eight {
    border: 1px solid rgba(255, 255, 255, 0.30);
    padding: 35px 25px 33px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    overflow: hidden;
}
.service_style_eight::before {
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    height: 50px;
    width: 50px;
    background: var(--primary);
    border-radius: 0 0 5px 0;
    z-index: -1;
    transition:.5s;
}
.service_style_eight:hover::before{
    height: 100%;
    width: 100%;
}
.service_style_eight_icon .icon i {
    color: #fff;
}
.service_style_eight_content h4 {
    padding-bottom: 10px;
    font-size: 22px;
}
.service_style_eight_content a {
    color: #fff;
}
.service_style_eight_content a i {
    font-size: 20px;
    margin-left: 10px;
}
/* Service Style Nine */

.service_style_nine {
    background: #fff;
    padding: 60px 40px 34px;
    box-shadow: 0 20px 50px 5px #e9eef7;
    border-radius: 10px;
    margin-bottom: 30px;
    transition:.5s;
}
.service_style_nine:hover{
    box-shadow:0 10px 30px 3px rgba(99, 119, 238, 0.4);
}

.services_style_nine_icon .icon {
    height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: 50px;
    color: var(--primary);
    position: relative;
    text-align: center;
    box-shadow: 0 20px 50px 5px #e9eef7;
    border-radius: 50%;
    z-index: 1;
    background: #fff;
    transition:.5s;
}
.services_style_nine_icon .icon:before {
    position: absolute;
    content: '';
    background: rgba(12,90,219,0.10);
    width: 100%;
    height: 100%;
    left: -8px;
    top: -5px;
    z-index: -1;
    border-radius: 50%;
    box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2);
    transition: .5s;
}
.service_style_nine:hover .services_style_nine_icon .icon{
    color:#fff;
}
.service_style_nine:hover .services_style_nine_icon .icon:before{
    background: rgba(12,90,219,0.80);
}
/* Service Style Ten */
.single_service_style_ten {
    box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
    background: #fff;
    padding: 30px 30px 15px;
    position:relative;
    transition:.5s;
    margin-bottom:30px;
}
.single_service_style_ten::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    height: 3px;
    width: 20%;
    background: var(--primary);
    transition:.5s;
}
.single_service_style_ten:hover:before{
    width:100%;
}
.single_service_style_ten:hover{
    transform:translateY(-10px);
}
.single_service_style_ten:hover .service_style_ten_icon .icon {
    background: rgba(12,90,219,0.90);
    color:#fff;
}
.service_style_ten_content h4 a {
    font-size: 22px;
    font-weight: 700;
}
.service_style_ten_icon .icon {
    background: rgba(12,90,219,0.10);
    display: inline-block;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 35px;
    color: var(--primary);
    border-radius: 5px;
    transition:.5s;
}
/* Service Style Eleven */
.single_service_style_eleven {
    box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
    padding: 35px 30px 30px;
    margin-bottom: 30px;
    border-radius: 0 40px;
    position: relative;
    z-index:1;
}
.single_service_style_eleven::before {
    position: absolute;
    left: 0;
    content: "";
    height: 0%;
    width: 100%;
    background: var(--primary);
    bottom: 0;
    z-index: -1;
    border-radius: 0 40px;
    transition:.5s;
}
.service_style_eleven_icon {
    float: left;
    margin-right: 15px;
}
.service_style_eleven_icon .icon {
    background: rgba(12,90,219,0.10);
    height: 58px;
    width: 58px;
    line-height: 58px;
    font-size: 28px;
    text-align: center;
    border-radius: 50%;
    color: var(--primary);
    transition:.5s;
}
.service_style_eleven_title h4 {
    transition: .5s;
    font-size: 21px;
}
.service_style_eleven_text p {
    transition: .5s;
}
.servic_style_eleven_button a {
    transition: .5s;
}
.single_service_style_eleven:hover .service_style_eleven_title h4,
.single_service_style_eleven:hover .service_style_eleven_text p,
.single_service_style_eleven:hover .servic_style_eleven_button a{
    color:#fff;
}
.single_service_style_eleven:hover .service_style_eleven_icon .icon{
    transform:rotate(360deg);
}
.single_service_style_eleven:hover::before{
    height:100%;
}
.single_service_style_eleven:hover .service_style_eleven_icon .icon{
    background:#fff;
}
/* Service Style Twelve */
.service_style_12 {
    overflow: hidden;
    border: 1px solid #eee;
    border-bottom: 3px solid var(--primary);
    border-radius: 5px;
}
.service_style_12_thumb img {
    width: 100%;
}
.service_style12_content {
    padding: 0 20px 20px;
}
.service_style_12_content_inner h2 {
    font-size: 22px;
    font-weight: 700;
}
.service_style_12_icon {
    position: relative;
    margin-top: -40px;
}
.service_style_12_icon .icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: var(--primary);
    color: #fff;
    text-align: center;
    font-size: 40px;
    border-radius: 4px;
    display: block;
}
.service_style_12_content_inner {
    padding-top: 15px;
}
.service_style_12_content_inner a {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
}

.owl-dots {
    text-align: center;
    position: relative;
    right: 0;
    left: 0;
    cursor: pointer;
}
.owl-dot.active {
    background: var(--primary);
    width: 40px;
}
.owl-dot {
    height: 6px;
    width: 30px;
    background: #ccc;
    line-height: 30px;
    display: inline-block;
    margin-right: 12px;
    border-radius: 103px;
    transition: .5s;
}
/*service style thirteen*/
.service-area {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
/*em service box*/
.em-service-single-box {
    height: 320px;
    border-radius: 5px;
    filter: drop-shadow(0px 5px 30px rgba(162,188,231,0.1));
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 30px;
}
.em-service-single-box:after {
    position: absolute;
    content: "";
    z-index: -1;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: .5s;
}
/*box inner*/
.em-service-box-inner {
    position: relative;
    z-index: 1;
}
.em-service-single-box:after {
    position: absolute;
    content: "";
    z-index: -1;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: .5s;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/*service content*/
.em-service-content {
    padding: 40px 40px 0;
}
/*em icon*/
.em-icon {
    float: left;
    margin-right: 10px;
}
.em-icon-title h4 {
    font-size: 15px;
    color: var(--primary);
    display: inline-block;
    font-weight: 500;
    transform: .5s;
}
/*em service title*/
.em-service-title h2 {
    font-size: 24px;
    line-height: 32px;
    padding: 15px 0 15px;
    transition: .5s;;
}
.em-service-text p{
    transition: .5s;
}
/*em service button*/
.em-service-button {
    margin: 40px 0 0;
}
.em-service-button a {
    display: inline-block;
    color: var(--primary);
    text-decoration: underline;
    transition: .5s;
}
.em-service-button a i {
    font-size: 28px;
    position: relative;
    top: 7px;
}
/*service all hover*/
.em-service-single-box:hover:after {
    left: 0;
    width: 100%;
}
.em-service-single-box:hover .em-icon-title h4, .em-service-single-box:hover .em-service-title h2,
.em-service-single-box:hover .em-service-text p, .em-service-single-box:hover .em-service-button a{
    color: #fff;
}
.em-service-single-box:hover .em-service-box-inner:before {
    background-color: rgba(255,255,255,0.1019607843137254)
}


/*service area style three*/
.service-area.style-four {
    background: inherit;
}
.em-service-single-box1 {
    height: 345px;
    border-radius: 5px;
    filter: drop-shadow(0px 5px 30px rgba(162,188,231,0.3));
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 30px;
    text-align: center;
}
.em-service-single-box1:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background: #0B59DB;
    z-index: -1;
    transition: .5s;
}
.em-service-title1 h2 {
    font-size: 22px;
    line-height: 32px;
    padding: 16px 0 10px;
    transition: .5s;
}
.em-service-text1 p {
    transition: .5s;
    line-height: 30px;
}
/*em icon*/
.em-icon1 {
    position: relative;
    z-index: 1;
}
.em-icon1:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
/*upper*/
.upper1.em-service-single-box1:before {
    background: #01CB99;
}
.upper2.em-service-single-box1:before {
    background: #EA599E;
}
.upper3.em-service-single-box1:before {
    background: #FF6A01;
}
.upper4.em-service-single-box1:before {
    background: #764AE5;
}
/*service active box*/
.active.em-service-single-box1 {
    background-color: var(--primary);
}
.active .em-service-title1 h2 {
    color: #fff;
}
.active .em-service-text1 p {
    color: #fff;
}

/*all service hover*/
.em-service-single-box1:hover:before {
    height: 100%;
    top: 0;
}
.em-service-single-box1:hover .em-service-title1 h2, .em-service-single-box1:hover .em-service-text1 p {
    color:#fff;
}
/*style five service area*/
.style-five.service-area {
    background: inherit;
}
/*service content*/
.style-five .em-service-content {
    padding: 35px 30px 15px;
}
.style-five .em-service-single-box1 {
    height: inherit;
    border-radius: 5px;
    filter: drop-shadow(0px 5px 30px rgba(162,188,231,0.3));
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 30px;
    text-align: center;
}
/*service icon*/
.style-five .em-icon1:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.style-five .upper.em-icon1:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.style-five .upper1.em-icon1:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.style-five .em-icon1 i {
    display: inline-block;
    height: 85px;
    line-height: 100px;
    font-size: 35px;
    color: #fff;
    margin-left: -7px;
}
/*service title*/
.style-five .em-service-title1 h2 {
    font-size: 20px;
    line-height: 32px;
    padding: 15px 0 6px;
    transition: .5s;
    color: #333c4e;
}
/*service five all hover*/
.style-five .upper1.em-service-single-box1:before {
    background-image: linear-gradient(to bottom right, #ff9b07 0%, #fd4f2f 90%);
}
.style-five .upper2.em-service-single-box1:before {
    background-image: linear-gradient(to bottom right, #0b59db 0%, #00237d 90%);
}
.style-five .upper3.em-service-single-box1:before {
    background-image: linear-gradient(to bottom right, #CA30FB 0%, #8C3FFD 90%);
}
/*====================================================
--<	Techno Service Details Area Css -->
======================================================*/
.service-details-pn-list ul li {
    margin-bottom: 2px;
    transition: .5s;
    list-style: none;
}
.service-details-pn-list ul li a {
    position: relative;
    display: block;
    padding: 14px 15px 14px 18px;
    background-color: #F3F6F9;
    font-weight: 600;
    font-size: 17px;
    transition: .5s;
    color: #232323;
    font-family: Muli;
}
.service-details-pn-list ul li a:hover {
    background: var(--primary) !important;
    color: #fff !important;
}
.service-details-pn-list ul li a i {
    float: right;
    font-size: 17px;
    font-weight: 600;
    position: relative;
    margin-top: 5px;
}
.service-details-big-button a {
    color: #ffffff;
    background: var(--primary);
    font-family: Open Sans;
    font-weight: 500;
    text-transform: capitalize;
    border: 0px solid #ffffff;
    border-radius: 5px;
    padding: 14px 35px 14px 35px;
    display: block;
}
.service-details-big-button a:hover {
    background: #00247e;
}

.details-big-content {
    text-align: center !important;
}
.service-details-big-button a span i {
    margin-left: 20px;
}
.service-details-pn-about{
    position:relative;
    z-index:1;
}
.service-details-pn-about::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: rgba(12,90,219,.85);
    z-index: -1;
}
.service-details-pn-about-content{
    text-align: center;
}
.service-main-details-content-title h3 {
    font-weight: 800;
}
.service-details-pn-about-content-title h4 {
    color: #fff;
    font-weight:700;
}
.service-details-pn-about-content-text p {
    color: #fff;
}
.service-details-pn-about-content-button a {
    display: inline-block;
    padding: 10px 30px;
    background: #fff;
    color: var(--primary);
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
}
/*-- Main Service Details --*/
.service-main-details-inner-thumb img {
    width: 100%;
}
.service-details-video-thumb img {
    width: 100%;
}
.service-details-video .single-video{
    position:relative;
}
.service-details-video .video-icon {
    position: absolute;
    left: 0;
    bottom: 265px;
    right: 0;
}
.service-details-research-button a {
    color: #ffffff;
    background: var(--primary);
    font-family: Open Sans;
    font-weight: 500;
    text-transform: capitalize;
    border: 0px solid #ffffff;
    border-radius: 5px 5px 5px 5px;
    padding: 14px 35px 14px 35px;
    margin-top: 15px;
    transition:.5s;
}
.service-details-research-button a:hover {
    background: #00247e;
}
.service-details-research-title h4 {
    font-weight: 800;
}

/*====================================================
--<	Techno Team Area Css -->
======================================================*/
.single_team {
    overflow: hidden;
    border-radius: 5px;
    transition:.5s;
}
.single_team_thumb{
    position: relative;
}
.single_team_thumb:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(12,90,219,0.85);
    content: "";
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.single_team_icon {
    position: absolute;
    top: 50%;
    right: -50px;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: #fff;
    width: 45px;
    padding: 13px 0;
    opacity:0;
}
.single_team_icon a {
    display: block;
    font-size: 16px;
    color: var(--primary);
    text-align: center;
    margin: 4px 0px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.single_team_thumb img {
    width: 100%;
}
.single_team_content {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    background: #fff;
    padding: 12px 20px 23px;
    text-align: center;
}
.single_team_content h4 {
    font-size: 20px;
    font-weight: 700;
    transition: .3s;
    display: block;
}
.single_team_content span {
    color:var(--primary);
    padding-top:2px;
}
/* Team Hover Css */
.single_team:hover{
    transform:translateY(-10px);
}
.single_team:hover .single_team_icon{
    opacity:1;
    right: -1px;
}
.single_team_content h4:hover{
    color:var(--primary);
}
.single_team:hover .single_team_thumb:before{
    opacity:1;
}
/* Team Style Three */

.team_style_three {
    position: relative;
    transition: .5s;
}
.team_style_three-thumb {
    position: relative;
    z-index:1;
    transition:.5s;
}
.team_style_three_thumb_inner {
    position: relative;
    z-index:1;
    transition:.5s;
    overflow:hidden;
}
.team_style_three_thumb_inner:before {
    position: absolute;
    left: -70px;
    bottom: -59px;
    height: 120px;
    width: 133%;
    background: #fff;
    content: "";
    -webkit-transform: skew(-30deg);
    -khtml-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    transform: skew(-98deg);
}
.team_style_three-thumb:after {
    position: absolute;
    left: 0;
    bottom: 4px;
    height: 100%;
    width: 100%;
    background: var(--primary);
    content: "";
    -webkit-transform: skew(1deg);
    -khtml-transform: skew(69deg);
    -moz-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    opacity: 0;
    transition: .5s;
    z-index: -1;
}
.team_style_three_thumb_inner img {
    width: 100%;
}
.team_style_three_content {
    position: absolute;
    bottom: -21px;
    left: 0;
    right: 18px;
    z-index: 1;
    padding: 15px 10px 35px 50px;
    transition: .5s;
}
.team_style_three_content:before {
    background:var(--primary);
    transition:.5s;
}

.team_style_three_content:before, .team_style_three_content:after {
    content: '';
    top: 0;
    left: 0;
    right: 50px;
    bottom: 0;
    position: absolute;
    z-index: -2;
    -webkit-transform: skew(-30deg);
    -khtml-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    transform: skew(-30deg);
    transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
    -webkit-transition: all .2s linear 0ms;
    -khtml-transition: all .2s linear 0ms;
    -moz-transition: all .2s linear 0ms;
    -ms-transition: all .2s linear 0ms;
    -o-transition: all .2s linear 0ms;
    transition: all .2s linear 0ms;
}

.team_style_three_title h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}
.team_style_three_sub_title {
    transition: .5s;
    position: absolute;
    left: 51px;
    top: 40px;
}
.team_style_three_sub_title span {
    font-size: 14px;
    color: #fff;
    transition: .5s;
}
.team_style_three_icon a {
    color: #fff;
    display: inline-block;
    margin: 5px;
}
.team_style_three_icon {
    opacity: 0;
    transition: .5s;
    position: absolute;
    left: 47px;
    top: 50px;
}
.team_style_three:hover .team_style_three_icon {
    opacity: 1;
    top: 35px;
}
.team_style_three:hover .team_style_three-thumb:after{
    opacity:1;
    -webkit-transform: rotate(-10deg);
    -webkit-transform: skew(1deg);
    transform: rotate(-10deg);
}
.team_style_three:hover .team_style_three_sub_title {
    margin-top: -20px;
    opacity: 0;
}
.team_style_three:hover .team_style_three_content:before {
    background:#00247e;
    transition:.5s;
}
/* Team Style Three */
.single_team_style_three{
    margin-bottom:30px;
}
.single_team_style_three_thumb {
    position: relative;
}
.single_team_style_three_thumb img {
    width: 100%;
}
.single_team_style_three_icon {
    position: absolute;
    top: 90%;
    right: 0;
    left: 0;
    text-align: center;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    width: 83%;
    background: var(--primary);
    margin: 0 30px;
    margin: auto;
    padding:12px 0;
    transition:.5s;
    opacity:0;
}
.single_team_style_three_content {
    text-align: center;
    padding: 10px 0 22px;
    -webkit-box-shadow: 0 16px 32px rgba(207,228,255,.8);
    box-shadow: -1 16px 32px rgba(207,228,255,.8);
    -webkit-transform: translateY(0px);
    z-index:1;
}
.single_team_style_three:hover .single_team_style_three_icon{
    top: 82%;
    opacity:1;
}
.single_team_style_three_icon i {
    color: #fff;
    margin: 0 5px;
}
/*team area style four*/
.team_area.style-four {
    background-repeat: no-repeat;
    background-size: cover;
}
.style-four .single_team {
    overflow: inherit;
    border-radius: 5px;
    transition: .5s;
    filter: drop-shadow(0px 5px 15px rgba(137,166,198,0.2));
    position: relative;
    z-index: 222;
}
.style-four .single_team:before {
    position: absolute;
    content: "";
    z-index: -1;
    bottom: -21px;
    left: 13px;
    width: 223px;
    height: 95px;
    border-radius: 5px;
    background: #fff;
    clip-path: polygon(4% 18%,100% 21%,100% 100%, 0 80%);
    transition: .6s;
}

/*team content*/
.style-four .single_team_content {
    border-bottom: 0;
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    filter: drop-shadow(0px 5px 15px rgba(137,166,198,0.2));
}

.style-four .single_team_content span {
    color: #616161;
    padding-top: 7px;
    display: inline-block;
}
.style-four .single_team_icon1 a i {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    filter: drop-shadow(0px 10px 30px rgba(197,207,223,0.4));
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
    color: var(--primary);
    font-size: 14px;
    margin-right: 10px;
}
.style-four .single_team_icon1 {
    position: absolute;
    bottom: -18px;
    left: 23%;
    right: 0;
    margin: auto;
    opacity: 0;
    transition: .5s;
}
a.team-color2 i {
    color: #07C1F1 !important;
}
a.team-color3 i {
    color: #E8200A !important;
}
/* team all hover*/
.style-four .single_team:hover .single_team_thumb1:before {
    width: 100%;
    left: 0;
}
.style-four .single_team:hover .single_team_icon1 {
    bottom: 25px;
    opacity: 1;
}
.style-four .single_team:hover .single_team:before {
    background-image: linear-gradient(109deg, #4152c3 0%, #508df8 100%);
}
.style-four .single_team:hover:before {
    background: linear-gradient(109deg, #CBD2EA 0%, #D2DEF6 100%);
    transition: .5s;
}
.style-four .single_team:hover .single_team_content h4 {
    color: var(--primary);
}
/*section title */
.team_area.style-four .style-two.up .section_sub_title span:before {
    left: 84px;
    top: 8px;
}
.team_area.style-four .style-two.up .section_sub_title span:after {
    left: 86px;
    top: 2px;
}
.team_area.style-four .style-two.up .section_sub_title h5:before {
    left: -128px;
    right: 0;
}
.team_area.style-four .style-two.up .section_sub_title h5:after {
    left: -137px;
    right: 0;
}
/*owl carousel*/
.feature-area.owl-nav3.style-two .owl-dots {
    text-align: center;
    position: relative;
    right: 0;
    left: 0;
    cursor: pointer;
    top: 72px;
}
.owl-nav3 .owl-dots {
    text-align: center;
    position: relative;
    bottom: -90px;
    right: 0;
    left: 0;
    cursor: pointer;
    height: 2px;
    background-color: rgba(35,35,35,0.10196078431372549);
}
.owl-nav3 .owl-dot {
    height: 2px;
    width: 215px;
    background-color: transparent;
    line-height: 30px;
    display: inline-block;
    margin-right: 12px;
    border-radius: 103px;
    transition: .5s;
    position: relative;
    top: -17px;
}
.owl-nav3 .owl-dot.active {
    background: var(--primary);
    width: 218px;
    height: 6px;
}
.owl-nav3 .owl-prev {
    position: absolute;
    left: -60px;
    top: inherit;
    bottom: -36%;
    transform: translateY(-50%);
    opacity: 1;
    transition: .3s;
}
.owl-nav3 .owl-next {
    position: absolute;
    right: -60px;
    top: inherit;
    bottom: -36%;
    transform: translateY(-50%);
    opacity: 1;
    transition: .3s;
}

.owl-nav3 .owl-next i, .owl-prev i {
    background-image: linear-gradient(109deg, #1129b9 0%, #2473fb 100%);
    border-radius: 100%;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 53px;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.21, 1.1, 0.25, 1) 0s;
    line-height: 53px;
    color: #fff;
    border: 2px solid #FFF;
    text-align: center;
    display: inline-block;
    font-size: 18px;
}
/*====================================================
--<	Techno Software Area Css -->
======================================================*/
.single_software_box{
    margin-bottom:20px;
}
.single_software_box_content {
    background: #eff2f7;
    padding: 8px 15px 20px 17px;
    position: relative;
    z-index: 1;
    border-radius: 3px;
}
.single_software_box_content::before {
    position: absolute;
    left: 0;
    content: "";
    bottom: 0;
    height: 0%;
    width: 100%;
    background: var(--primary);
    z-index: -1;
    transition:.5s;
    border-radius:3px;
}
.single_software_box:hover .single_software_box_content::before{
    height: 100%;
}
.single_software_box_content h5 {
    font-size: 17px;
}
.single_software_box:hover .single_software_box_content h5{
    color:#fff;
}
.software_thumb_inner img {
    width: 100%;
}
.software_shape_thumb_inner1 {
    position: absolute;
    bottom: 0;
    left: 45px;
}
.software_shape_thumb_inner1 img {
    width: 40%;
}
.software_shape_thumb_inner2 {
    position: absolute;
    bottom: -35px;
    left: 340px;
}
.software_shape_thumb_inner2 img {
    width: 85%;
}
.software_shape_thumb_inner3 {
    position: absolute;
    top: -49px;
    right: 0;
}
.software_shape_thumb_inner4 {
    position: absolute;
    top: -74px;
    left: 104px;
}





/*====================================================
--<	Techno Case Study Area Css -->
======================================================*/

.single_case_study {
    overflow: hidden;
    margin-bottom: 30px;
    position:relative;
    transition:.5s;
}
.single_case_study_thumb {
    transform: scale(1.01);
    transition:.5s;
}
.pdn_0 {
    padding: 0;
}
.single_case_study_thumb::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background:rgba(12,90,219,0.85);
    opacity: 0;
    transition: .5s;
    border: 3px solid #fff;
    right: 0;
    bottom: 0;
    margin: auto;
}
.single_case_study_thumb img {
    width: 100%;
}
.single_case_study_content {
    position: absolute;
    left: 0;
    top: 70%;
    right: 0;
    text-align: center;
    background: #fff;
    width: 80%;
    margin: auto;
    border-radius: 5px;
    padding: 20px 10px;
    opacity: 0;
    transition: .5s;
    transform: translateY(-50%);
    z-index: 1;
}
.single_case_study_icon {
    text-align: center;
    padding-bottom: 10px;
}
.single_case_study_content_inner {
    text-align: center;
}
.single_case_study_content_inner h2 {
    font-size: 22px;
}
.single_case_study_content_inner h2 a {
    display: inline-block;
    overflow: hidden;
    font-weight: 600;
    font-size: 20px;
    transition:.5s;
}
.single_case_study_icon i {
    background: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    border-radius: 5px;
    color: var(--primary);
    transition: .5s;
}
/* Portfolio Hover */
.single_case_study:hover .single_case_study_thumb {
    transform: scale(1.03);
}
.single_case_study:hover .single_case_study_content {
    opacity: 1;
    top: 50%;
}
.single_case_study:hover .single_case_study_thumb::before{
    opacity:1;
}
.single_case_study_content_inner h2 a:hover {
    color: var(--primary);
}
.single_case_study_icon i:hover{
    background: var(--primary);
    color:#fff;
}

/* Case Study Style Two */
.case_study_style_two{
    position:relative;
}
.case_study_style_two_thumb {
    position: relative;
    z-index: 1;
}
.case_study_style_two_thumb:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background:rgba(12,90,219,0.65);
    content: "";
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border-radius: 10px;
}
.case_study_style_two_thumb img {
    width: 100%;
    border-radius: 10px;
}
.case_study_style_two_content {
    position: absolute;
    left: 0;
    top: 80%;
    right: 0;
    background:transparent;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    padding: 0 30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    text-align: left;
}
.case_study_style_two_title h4 a {
    color: #fff;
    font-weight: 700;
    display: block;
}
.case_study_style_two_title span {
    color: #fff;
    font-weight:600;
}
.case_study_style_two_button a {
    color: #fff;
}
.case_study_style_two:hover .case_study_style_two_content{
    top: 70%;
}
/* Style Three */
.three .case_study_style_two_content {
    top: 75%;
}
.three .case_study_style_two:hover .case_study_style_two_content{
    top: 60%;
}
.three .case_study_style_two_title h4 {
    font-size: 20px;
}
/* Style Four */
.four .single_case_study {
    overflow: inherit !important;
    margin-bottom: 65px;
}
.four .single_case_study_inner {
    overflow: hidden;
}
.four .single_case_study_content {
    bottom: -100px !important;
    background: #00247E;
    opacity: 1;
    transition: .5s;
    top: inherit;
}
.four .single_case_study:hover .single_case_study_content{
    bottom:-100px !important;
    top:inherit;
}
.four .single_case_study_inner {
    overflow: hidden;
}
.four .single_case_study_content_inner h2 a {
    color: #fff;
}
.four .single_case_study_content_inner span{
    color: #fff;
}
/*====================================================
--<	Techno Case Study Details Area Css -->
======================================================*/
.single-case-study-details {
    box-shadow: 0 0 30px rgba(0,0,0,0.10);
    padding: 50px;
}
.case-study-details-title h3 {
    font-weight: 800;
}
.case-study-details-content-inner ul li span {
    font-size: 20px;
    margin-right: 10px;
    font-weight:700;
}
.case-study-details-content-inner ul li {
    border-bottom: 1px solid #ddd;
    font-size: 18px;
    font-weight: 300;
    padding: 13px 0;
    text-transform: capitalize;
    list-style: none;
}
.case-study-details-content-inner ul li:last-child{
    border-bottom:none;
}
.case-study-details-icon-text span {
    font-size: 20px;
    margin-right: 10px;
    font-weight: 700;
    float:left;
}
.case-study-details-icon-inner a {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: var(--primary);
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    margin-right: 10px;
    line-height: 35px;
}
.case-study-details-full-title h3 {
    font-weight: 800;
}
.case-study-details-thumb img {
    width: 100%;
}
/*====================================================
--<	Techno Portfolio Area Css -->
======================================================*/

/* Portfolio Nav */
.portfolio_nav {
    margin-bottom: 50px;
}
.portfolio_menu ul {
    text-align: center;
    list-style: none;
}
.portfolio_menu ul li {
    display: inline-block;
    margin: 0 14px;
    cursor: pointer;
}
.portfolio_menu ul li a{
    display: block;
    color:#fff;
    text-transform:uppercase;
    position:relative;
    transition:.5s;
}
.portfolio_menu ul li a:before {
    position: absolute;
    content: "";
    left: 0;
    top: 24px;
    height: 2px;
    width: 0%;
    background: #fff;
    transition:.5s;
}
.portfolio_nav ul li {
    background: #f2f4ff;
    border: medium none;
    font-size: 16px;
    line-height: 1;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: capitalize;
    padding: 12px 27px;
    font-weight: 500;
    letter-spacing: 2px;
    position: relative;
    margin: 0 3px;
    margin-bottom: 7px;
    display: inline-block;
    border-radius: 5px;
}
.portfolio_nav ul li:hover, .portfolio_nav ul li.current_menu_item {
    color: #fff;
    background: var(--primary);
}

/* Single Portfolio */
.single_portfolio {
    overflow: hidden;
    margin-bottom: 30px;
    position:relative;
    transition:.5s;
}
.single_portfolio_thumb {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    transition:.5s;
}
.single_portfolio_content {
    position: absolute;
    text-align: center;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    right: 20px;
    bottom: 20px;
    left: 20px;
    opacity: 0;
    background: var(--primary);
    margin: auto;
    text-align: left;
    padding: 30px 20px 30px;
    border-radius: 5px;
}
.single_portfolio_icon {
    text-align: center;
    padding-bottom: 10px;
}
.single_portfolio_content_inner {
    text-align: center;
}
.single_portfolio_content_inner h2 {
    font-size: 22px;
    margin: 0;
}
.single_portfolio_content_inner h2 a {
    display: inline-block;
    overflow: hidden;
    font-weight: 600;
    color: #fff;
}
.single_portfolio_icon i {
    background: #fff;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    border-radius: 5px;
    color: #135ef3;
    transition:.5s;
}
.single_portfolio_thumb img {
    width: 100%;
}
.single_portfolio_content_inner span {
    color: #fff;
}
/* Portfolio Hover */
.single_portfolio:hover .single_portfolio_thumb {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.portfolio_menu ul li a:hover:before {
    width: 100%;
}
.single_portfolio:hover .single_portfolio_content{
    opacity: 1;
    bottom:35px;
}
.single_portfolio_icon i:hover{
    background: #fff;
    color:#135ef3;
}
/* Portfolio Style Two */
.style_two .single_portfolio_thumb {
    position: relative;
    z-index: 1;
}
.style_two .single_portfolio_thumb:after {
    content: "";
    background: rgba(12,90,219,.85);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    transform-origin: top;
    -moz-transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    -ms-transition: all .5s ease-out 0s;
    -o-transition: all .5s ease-out 0s;
    transition: all .5s ease-out 0s;
    -moz-transform: perspective(15em) rotateX(-90deg);
    -o-transform: perspective(15em) rotateX(-90deg);
    -ms-transform: perspective(15em) rotateX(-90deg);
    -webkit-transform: perspective(15em) rotateX(-90deg);
    transform: perspective(15em) rotateX(-90deg);
    z-index: 1;
    left: 0;
}
.style_two .single_portfolio_content_inner h2 a {
    font-weight: 700;
    color: #232323;
}
.style_two .single_portfolio_content_inner span {
    color: #135ef3;
}
.style_two .single_portfolio_icon i {
    background: #135ef3;
    color: #fff;
}
.style_two .single_portfolio_content {
    z-index: 1;
    background:#fff;
}
.style_two .single_portfolio:hover .single_portfolio_thumb:after {
    -moz-transform: perspective(0) rotateX(0);
    -o-transform: perspective(0) rotateX(0);
    -ms-transform: perspective(0) rotateX(0);
    -webkit-transform: perspective(0) rotateX(0);
    transform: perspective(0) rotateX(0);
}
/* Portfolio Style Three */

.style_three .single_portfolio_thumb {
    position: relative;
}
.style_three .single_portfolio_thumb::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 0%;
    width: 100%;
    background: rgba(12,90,219,0.75);
    transition:.5s;
}
.style_three .single_portfolio_content {
    right: 0;
    left: 0;
    opacity: 0;
    background: transparent;
    text-align: left;
    padding: 0;
}
.style_three .single_portfolio:hover .single_portfolio_thumb::before{
    height: 100%;
}
/*fortfolio style four*/
.portfolio_area.style-four {
    overflow: hidden;
}
/*portfolio nav*/
.style-four .portfolio_nav ul li {
    background: #f2f4ff;
    border: medium none;
    font-size: 16px;
    line-height: 1;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    text-transform: capitalize;
    padding: 8px 15px;
    font-weight: 500;
    letter-spacing: 2px;
    position: relative;
    margin: 78px 3px;
    margin-bottom: 7px;
    display: inline-block;
    border-radius: 2px;
    filter: drop-shadow(0px 5px 15px rgba(152,179,223,0.2));
    background-color: #ffffff;
}
.style-four .portfolio_nav ul li:hover, .portfolio_nav ul li.current_menu_item {
    color: #fff;
    background: linear-gradient(to left, #2475FC, #1129B9);
}
/*portfolio box*/
.style-four .single_portfolio {
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;
    transition: .5s;
    border-radius: 10px;
    filter: drop-shadow(0 0 20px rgba(170,192,228,0.3));
    background-color: #ffffff;
    padding: 20px 20px 22px;
}
/*portfolio thumb*/
.single_portfolio_thumb1 {
    position: relative;
    z-index: 1;
}
.single_portfolio_thumb1:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    border-radius: 10px;
    background-color: rgba(4,96,248,0.6);
    transition: .8s;
}
.style-four .single_portfolio_thumb1 img {
    width: 100%;
}
/*portfolio content*/
.style-four .single_portfolio_content_inner {
    text-align: left;
    padding: 20px 0 0;
}
/*portfolio icon*/
.style-four .single_portfolio_icon {
    text-align: center;
    padding-bottom: 10px;
    position: absolute;
    top: 0%;
    left: 33%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
    opacity: 0;
    transition: .5s;
}
.style-four .single_portfolio_icon a i {
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    color: #132FBF;
}
/*portfolio title*/
.style-four .single_portfolio_content_inner h2 a {
    display: inline-block;
    overflow: hidden;
    font-weight: 700;
    color: #232323;
    line-height: 30px;
    padding: 7px 0 0px;
}
/*port icon thumb*/
.style-four .port-icon-thumb {
    float: left;
    margin-right: 9px;
}
.style-four .port-icon-title p {
    color: var(--primary);
    font-weight: 600;
    margin: 0;
}
/* portfolio hover */

.style-four .single_portfolio:hover .single_portfolio_content_inner h2 a {
    color: var(--primary);
    text-decoration: underline;
}
.style-four .single_portfolio:hover .single_portfolio_thumb1:before {
    height: 100%;
    top: 0;
}
.style-four .single_portfolio:hover .single_portfolio_icon {
    opacity: 1;
    top: 39%;
}
/*====================================================
--<	Techno  How It Work Area Css -->
======================================================*/
.single_it_work {
    text-align: center;
}
.single_it_work_content_list{
    position:relative;
}
.single_it_work_content_list::before {
    position: absolute;
    right: -75px;
    top: 26px;
    height: 1px;
    width: 130px;
    content: "";
    background: #ddd;
}
.single_it_work_content_list.three::before {
    display:none;
}
.single_it_work_content_list span {
    background: var(--primary);
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    position:relative;
    height:45px;
    width:45px;
    line-height:45px;
    display:inline-block;
    transition:.3s;
}
.single_work_content_title h4 {
    font-size: 20px;
    font-weight: 700;
}
.single_it_work_content_list span:after {
    position: absolute;
    left: -10px;
    top: -10px;
    height: 65px;
    width: 65px;
    content: "";
    background: var(--primary);
    z-index: -1;
    border-radius: 50%;
    right: 0;
    margin: auto;
    opacity: 0.4;
    transition:.3s;
}
.single_it_work_content_list span:before {
    position: absolute;
    left: -20px;
    top: -20px;
    height: 85px;
    width: 85px;
    content: "";
    background: var(--primary);
    z-index: -1;
    border-radius: 50%;
    right: 0;
    margin: auto;
    opacity: 0.2;
    transition:.3s;
}
.single_it_work:hover .single_it_work_content_list span,
.single_it_work:hover .single_it_work_content_list span:before,
.single_it_work:hover .single_it_work_content_list span:after{
    transform: scale(1.1);
}
/*odoo work process*/

.how_it_work.odoo .custom-container {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
}
.how_it_work.odoo .single_it_work {
    text-align: center;
    padding: 0 11px;
    margin-bottom: 30px;
}
.how_it_work.odoo .work_progress_icon i {
    background: linear-gradient(90deg, rgba(30,85,189,1) 0%, rgba(67,126,235,1) 100%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    font-size: 50px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    width: 120px;
    z-index: 1;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
}
.how_it_work.odoo .work_progress_icon {
    position: relative;
    z-index: 1;
    margin-bottom: 127px;
}
.how_it_work.odoo .work_progress_icon::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    width: 15px;
    height: 15px;
    border: 2px solid #ddd;
    border-radius: 100%;
    bottom: -32px;
}
.how_it_work.odoo .work_progress_icon::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    width: 2px;
    height: 40px;
    border-left: 2px dashed #ddd;
    border-radius: 100%;
    bottom: -74px;
}
.how_it_work.odoo .single_it_work_content_list span:before,
.how_it_work.odoo .single_it_work_content_list span:after{
    display: none;
}
.how_it_work.odoo .single_it_work_content_list span {
    border-radius: 50%;
    font-weight: 600;
    position: absolute;
    top: 0;
    width: 45px;
    line-height: 45px;
    display: inline-block;
    transition: .3s;
    background: #fff;
    border-radius: 50%;
    color: var(--primary);
    box-shadow: 0 0 10px #cccccc;
}
.how_it_work.odoo .single_it_work_content_list {
    position: absolute;
    top: 0;
    right: 0;
    left: 53%;
    transform: translate(-50%);
}
.how_it_work.odoo .single_it_work::after {
    position: absolute;
    right: 0;
    top: 10px;
    content: "";
    height: 40px;
    width: 200px;
    background-repeat: no-repeat;
    margin-right: -119px;
    background-size: contain;
}
.how_it_work.odoo .single_it_work_content_list::before {
    display: none;
}
.how_it_work.odoo .single_it_work.last:after{
    display: none;
}
.how_it_work.odoo .section_main_title h1, .section_main_title h2 {
    font-size: 39px;
}
/* How It Work Style Tow */
.it_work_style_two .techno_flipbox .techno_flipbox_font, .it_work_style_two .techno_flipbox .techno_flipbox_back {
    min-height: 410px;
}
.it_work_style_two .it_work_style_two .techno_flipbox .techno_flipbox_inner {
    top: 50%;
}
.techno_flipbox_font .icon {
    color: #fff;
    font-size: 30px;
    line-height: 80px;
    height: 80px;
    width: 80px;
    background: var(--primary);
    border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
    display:inline-block;
}
.it_work_style_two .techno_flipbox_font {
    background: #ffffff;
    border: 2px solid #e8ecfc;
    border-radius: 7px 7px 7px 7px;
}
.it_work_style_two .techno_flipbox_font .flipbox_title h3 {
    color: #444;
    font-weight:700;
    font-size:24px;
}
.it_work_style_two .techno_flipbox_font .flipbox_desc p {
    color: #666;
}
.it_work_style_two .techno_flipbox .techno_flipbox_back .flipbox_button a {
    background:inherit;
    color: #fff;
    display: inline-block;
    padding: 0;
    border-radius: inherit;
    border:inherit;
    font-size:18px;
    margin-top:5px;
    font-weight:600;
}
.it_work_style_two .techno_flipbox .techno_flipbox_back::before {
    background:inherit;
}
/* How IT Work Style Three */
.style_three .single_how_it_work {
    position: relative;
}
.style_three .how_it_work_number {
    position: absolute;
    top: -50px;
    left: 56px;
}
.style_three .how_it_work_icon .icon {
    color: var(--primary);
    font-size: 65px;
    z-index: 1;
    transition:.5s;
}
.style_three .how_it_work_number h4 {
    height: 45px;
    width: 45px;
    line-height: 45px;
    background: var(--primary);
    color: #fff;
    border-radius: 50%;
    font-size: 20px;
}
.style_three .how_it_work_icon_bg_thumb_inner {
    position: absolute;
    top: -48px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: auto;
}
.style_three .how_it_work_bg_thumb_inner_hv {
    position: absolute;
    top: -18px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: auto;
    opacity:0;
    transition:.5s;
}
.style_three .how_it_work_title a {
    font-weight: 700;
    font-size: 22px;
}
.style_three .single_how_it_work:hover .how_it_work_bg_thumb_inner_hv{
    top: -48px;
    opacity:1;
}
.style_three .single_how_it_work:hover .how_it_work_icon .icon{
    color:#fff;
}
/*odoo contact section*/
.odoo_contact_section .row.upper {
    box-shadow: 0px 0px 37px 0px rgb(0 0 0 / 20%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-bottom: 0px;
    padding: 40px 40px 40px 40px;
    z-index: 1;
    border-radius: 10px;
    position: relative;
    margin-top: -203px;
    background: #fff;
}

.odoo-left-side {
    text-align: center;
}
.odoo-contact-thumb img {
    width: 100%;
}
.odoo-contact-content {
    padding: 18px 0px 23px;
    border-radius: 7px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.odoo-contact-content h2 {
    margin: 0 0 16px;
    font-size: 20px;
}
.odoo-social-icon ul li {
    display: inline-block;
    height: 32px;
    width: 32px;
    background: #256BDF;
    border-radius: 4px;
    line-height: 32px;
    margin-right: 1px;
}
.odoo-social-icon ul li a{
    color: #fff;
    display: inline-block;
}
/*odoo contact right side*/
.odoo_contact_section .form_box input {
    height: 54px;
    background-color: #fff;
    border-color: transparent;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border: 1px solid #e6e6e6;
    padding: 6px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
}

.odoo_contact_section .quote_wrapper {
    background: #fff;
    padding: 32px 50px 9px;
    border-radius: 7px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.odoo_contact_section .apointment-form-title {
    text-align: center;
    margin-bottom: 30px;
}
.odoo_contact_section .apointment-form-title h2 {
    margin: 0 0 10px;
    font-size: 25px;
}

.odoo_contact_section select, .form-control {
    height: 54px;
    background-color: #fff;
    border-color: transparent;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border: 1px solid #e6e6e6;
    padding: 6px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
    width: 100%;
}
.odoo_contact_section .quote_btn button {
    background: var(--primary);
    border: 0;
    display: inline-block;
    text-align: center;
    padding: 14px 39px;
    border-radius: 7px;
    transition: .3s;
    width: 100%;
    color: #fff;
}
.odoo_contact_section .button:hover {
    background: #00247E;
    color: #fff;
}

.odoo_contact_section .form_box input:focus, .odoo_contact_section .form_box textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
/*process area style four*/
.process-area {
    position: relative;
    z-index: 1;
}
.row.process-bg {
    position: relative;
    z-index: 1;
}
.process-single-box {
    text-align: center;
    margin-bottom: 30px;
}
.process-contant {
    border-radius: 5px;
    filter: drop-shadow(0px 10px 20px rgba(35,50,83,0.07));
    background-color: #ffffff;
    padding: 22px 22px 15px;
    position: relative;
    border-radius: 5px;
}
.process-contant:before {
    position: absolute;
    content: "";
    z-index: -1;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-image: linear-gradient(-45deg, #00237d 0%, #0b59db 100%);
    transition: .5s;
    border-radius: 5px;
}
.upper1.process-contant:before {
    background-image: linear-gradient(-45deg, #FE542D 0%, #FF950B 100%);
}
.upper2.process-contant:before {
    background-image: linear-gradient(-45deg, #8B3FFE 0%, #D02FFB 100%);
}
.upper3.process-contant:before {
    background-image: linear-gradient(-45deg, #FF4F2B 0%, #FF47A6 100%);
}
/*process icon*/
.proces-icon {
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0 0 53px;
}
.proces-icon:before {
    position: absolute;
    content: "";
    z-index: -1;
    top: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.proces-icon i {
    display: inline-block;
    font-size: 35px;
    height: 92px;
    line-height: 90px;
    color: #fff;
    padding-left: 15px;
}
.upper2.proces-icon i {
    font-size: 55px;
}
/*process title*/
.process-title h3 {
    font-size: 16px;
    padding: 0 0 14px;
    transition: .5s;
}
.process-text p {
    transition: .5s;
}
/*process shape*/
.process-shape {
    position: absolute;
    left: 127px;
    top: -6px;
}
.process-shape-2 {
    position: absolute;
    top: -135px;
    right: -6px;
    z-index: -1;
}
/*process all hover*/
.process-single-box:hover .process-contant:before {
    width: 100%;
    left: 0;
}
.process-single-box:hover .process-title h3, .process-single-box:hover .process-text p{
    color:#fff;
}
/*process area style two*/
.process-area.style-two {
    background: #F7F8FF;
}
.style-two .process-contant1 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: drop-shadow(0px 10px 20px rgba(35,50,83,0.07));
    padding: 60px 22px 15px;
    position: relative;
    z-index: 1;
    border-radius: 6px;
}
.process-contant1:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    border-radius: 5px;
    transition: .5s;
}
.style-two .process-number {
    position: absolute;
    top: -34px;
    left: 98px;
}
.style-two .process-number-inner {
    position: relative;
    z-index: 1;
}
.style-two .process-number-inner:before {
    position: absolute;
    content: "";
    left: -9px;
    top: -12px;
    width: 46px;
    height: 47px;
    background: #1c53de;
    border-radius: 30px;
    z-index: -1;
    transition: .5s;
}
.style-two .process-number-inner span {
    font-size: 27px;
    font-weight: 700;
    color: #fff;
    width: 70px;
    height: 70px;
    line-height: 67px;
    background-color: #23b5ff;
    border: 3px solid #ffffff;
    display: inline-block;
    border-radius: 50px;
    transition: .5s;
}
/*process title*/
.style-two .process-title h3 {
    font-size: 20px;
    color: #fff;
    padding: 0 0 8px;
    line-height: 28px;
    margin-top: 0;
    transition: .5s;
}
.style-two .process-text p {
    transition: .5s;
    color: #fff;
}
/*upper box content*/
.style-two .upper .process-contant1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.style-two .upper .process-contant1:before {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    border-radius: 5px;
    transition: .6s;
}
.style-two .upper .process-number-inner:before {
    left: 37px;
    top: -9px;
    background: #23b5ff;
}
.style-two .upper .process-number-inner span {
    background-color: #1c53de;
}
.style-two .upper .process-title h3 {
    color: #232323;
}
.style-two .upper .process-text p {
    color: #616161;
}
/*process all hover*/
.style-two .process-contant1:hover:before{
    transform: scale(1);
}
.style-two .process-contant1:hover .process-title h3 {
    color: #232323;
}
.style-two .process-contant1:hover .process-text p {
    color: #616161;
}
.style-two .upper .process-contant1:hover:before{
    transform: scale(1);
}
.style-two .upper .process-contant1:hover .process-title h3 {
    color: #fff;
}
.style-two .upper .process-contant1:hover .process-text p {
    color: #fff;
}
.style-two .process-contant1:hover .process-number-inner span {
    background-color: #1c53de;
}
.style-two .process-contant1:hover .process-number-inner:before {
    background-color: #23B5FF;
}
.style-two .upper .process-contant1:hover .process-number-inner span {
    background-color: #23B5FF;
}
.style-two .upper .process-contant1:hover .process-number-inner:before {
    background-color: #1c53de;
}
/*process shape*/
.style-two .process-shape {
    position: absolute;
    left: 127px;
    top: -53px;
}
.process-shape-thumb {
    position: absolute;
    top: -471px;
    right: -140px;
    animation: moveLeftBounce 3s linear infinite;
}
/*====================================================
--<	Techno  Pricing Area Css -->
======================================================*/
.single_pricing {
    padding: 51px 40px 60px;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    transition: .5s;
}
.single_pricing.active {
    border: 1px solid var(--primary);
}
.pricing_title h3 {
    font-size: 30px;
    color: var(--primary);
    font-weight: 700;
}
.pricing_body {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    transition:.5s;
}
.single_pricing.active .pricing_body {
    border: 1px solid var(--primary);
}
.pricing_body .featur ul li {
    margin-bottom: 13px;
    display: block;
}
.curencyp {
    font-size: 29px;
    font-weight: 500;
    position: relative;
    top: -15px;
    color: var(--primary);
}
.pricing_tk h2 {
    display: inline-block;
    font-size: 55px;
    font-weight: 700;
    color: var(--primary);
}
.pricing_tk h2 span {
    font-size: 19px ;
    font-weight: 500 ;
}
.order_now a {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 42px;
    color: #616161;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    transition:.5s;
    background:transparent;
}
.single_pricing.active .order_now a {
    color: #fff;
    border: 1px solid var(--primary);
    background:var(--primary);
}
.single_pricing:hover {
    border: 1px solid var(--primary);
}
.single_pricing:hover .pricing_body{
    border: 1px solid var(--primary);
}
.single_pricing:hover .order_now a{
    background:var(--primary);
    color:#fff;
    border: 1px solid var(--primary);
}

/* Pricing Style Two */

.pricing_style2 {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    padding: 0 0 32px;
    transition: .5s;
    overflow: hidden;
    box-shadow: 0px 8px 14.72px 1.28px rgba(207, 207, 207, 0.3);
    border-radius: 10px;
}
.pricing_head_style2 {
    padding: 12px 0 21px;
    background: #0762f8;
}
.pricing_title_style2 h3 {
    font-size: 25px;
    color: #fff;
    text-transform: capitalize;
    padding-bottom: 5px;
}
.pricing_tk_style2 h2 {
    display: inline-block;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    color: #fff;
    transition:.5s;
}
.pricing_tk_style2 span {
    font-size: 14px ;
    color: #fff ;
    font-weight: 400;
}
.pricing_body_style2 {
    margin-top: 40px;
}
.pricing_body_style2 .featur_style2 ul li {
    display: block;
    font-size: 15px;
    margin-bottom: 18px;
}
.pricing_body_style2 .deactive {
    text-decoration: line-through;
    color: #CCDCEA;
}
.pricing_style2_button {
    margin-top: 33px;
}
.order_style2_now a {
    background: #f5f5f5;
    color: #0762f8;
    padding: 18px 55px;
    font-size: 18px;
    font-weight: 500;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;
    display: inline-block;
}
.active .order_style2_now a {
    background:#0762f8;
    color:#fff;
}
.order_style2_now a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background:#0762f8;
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
    transition:.5s;
}
.pricing_style2:hover{
    transform:translateY(-10px);
}
.pricing_style2:hover .order_style2_now a:before {
    width: 180%;
}
.pricing_style2:hover .order_style2_now a {
    color:#fff;
}
/* Pricing Style Three */
.single_pricing_three {
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0,0,0,.2);
    text-align:center;
    border-radius:5px;
    overflow:hidden;
}
.pricing_head_three {
    position: relative;
    background: var(--primary);
    padding: 36px 0 50px;
    z-index: 1;
}
.pricing_head_three:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: auto;
    z-index: -1;
}
.pricing_thumb {
    position: relative;
    display: inline-block;
    border: 1px solid var(--primary);
    border-radius: 50%;
    z-index: 1;
    background: #fff;
    top: -50px;
}
.pricing_body_three .featur ul li {
    list-style: none;
    padding: 6px 0;
}
.pricing_tk_three h2 {
    font-size: 70px;
    color: #fff;
}
.pricing_tk_three span {
    font-size: 20px;
}
.pricing_head_three .curencyp {
    font-size: 30px !important;
    font-weight: 700;
    position: relative;
    top: -37px;
    color: #fff;
    right: -18px;
}
.order_now a {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 42px;
    color: #616161;
    border: 1px solid var(--primary);
    border-radius: 5px;
    transition: .5s;
    background: transparent;
}
.single_pricing_three:hover .order_now a {
    background:var(--primary);
    color:#fff;
}
/* Pricing Style Four */

.pricing_style_four {
    background: #fff;
    margin-bottom: 30px;
    box-shadow: 0 20px 50px 5px #e9eef7;
}
.pricing_style_four_content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 25px 0 77px;
}
.active .pricing_style_four_content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 25px 0 77px;
}

.pricing_style_four_content_inner {
    text-align: right;
    padding-right: 50px;
}
.pricing_style_four_title h4 {
    font-weight: 500;
    text-transform: uppercase;
}
.pricing_style_four_tk h2 {
    font-weight: 500;
}
.pricing_style_four_tk h2 span {
    font-size: 20px;
}
.pricing_style_four_body {
    padding: 55px 28px 40px;
}
.pricing_style_four_body ul {
    list-style: none;
}
.pricing_style_four_body ul li {
    font-size: 18px;
    margin-bottom: 18px;
}
.pricing_style_four_body ul li i {
    color: var(--primary);
    margin-right: 10px;
}
.pricing_style_four_button a {
    background: #211e3b;
    display: block;
    text-align: center;
    padding: 20px 0;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    z-index: 1;
}
.pricing_style_four_button a span {
    font-size: 20px;
    margin-left: 5px;
}
.pricing_style_four_button a:before {
    position: absolute;
    left: 0;
    content: "";
    width: 10%;
    height: 100%;
    background: var(--primary);
    top: 0;
    transition: .5s;
    z-index: -1;
}
.pricing_style_four:hover .pricing_style_four_button a:before{
    width: 100%;
}
.single_price_icon {
    position: relative;
}
.single_price_icon_inner {
    position: absolute;
    bottom: 0;
    left: 74px;
}
.single_price_icon_inner .icon {
    color: #fff;
    font-size: 50px;
    height: 100px;
    width: 100px;
    background: #00247e;
    line-height: 95px;
    display: inline-block;
    text-align: center;
    border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
    border: 5px solid #fff;
}

/*====================================================
--<	Techno Process Area Css -->
======================================================*/

.single_process {
    text-align: center;
    position:relative;
    transition:.5s;
    margin-bottom:30px;
}
.single_process_thumb {
    display: inline-block;
    border-radius: 100%;
    animation: checkAnim 2s infinite;
    height: 230px;
    width: 230px;
    line-height: 230px;
}
.single_process_thumb img {
    border-radius: 100%;
    border: 10px solid #fff;
    width: 100%;
}
.single_process_number {
    position: relative;
}
.single_process_number_inner {
    position: absolute;
    left: 0;
    bottom: -25px;
    margin: auto;
    transition: .5s;
    content: "";
    right: 0;
}
.single_process_number span {
    height: 50px;
    width: 50px;
    background: var(--primary);
    display: inline-block;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
}
.single_process_content {
    padding-top: 30px;
}
.single_process_content h2 {
    font-size: 22px;
    font-weight: 700;
}
.single_process:hover .single_process_number_inner {
    bottom:50px;
}
/* Process Style Two */

/*====================================================
--<	Techno Counter Area Css -->
======================================================*/
.cntr_bg_up{
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 11px 11px 38px rgba(0,0,0,0.2);
}
.countr_text h1 {
    font-size: 40px;
    font-weight: 800;
}
.counter_desc h5 {
    margin-top: 6px;
    color:var(--primary);
}
.single_counter_icon i {
    font-size: 30px;
    color: #fff;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: inline-block;
    width: 80px;
    height: 80px;
    background: var(--primary);
    border-radius: 100%;
    line-height: 80px;
}
.white .countr_text h1 {
    color: #fff;
}
.white .counter_desc h5 {
    color: #fff;
}
.border_bottom_lin {
    position: relative;
}
.border_bottom_lin::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    height: 90px;
    width: 3px;
    background: var(--primary);
    content: "";
    margin: auto;
}
.single_counter_content {
    overflow: hidden;
}
.single_counter_icon_two .icon {
    float: left;
    margin-right: 20px;
    margin-top: 12px;
}
.single_counter_icon_two .icon i {
    font-size: 35px;
    background: var(--primary);
    height: 70px;
    width: 70px;
    line-height: 70px;
    display: inline-block;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}
/* Counter Style Three */
.counter_style_three {
    text-align: center;
    border: 2px solid #686666;
    padding: 0 20px 40px;
    border-radius: 10px;
    transition: .5s;
    margin-bottom: 40px;
}
.color2 .counter_style_three_icon .icon {
    background: #fd8424;
}
.color3 .counter_style_three_icon .icon {
    background: #d83030;
}
.counter_style_three.color2:hover{
    border-color:#fd8424;
}
.counter_style_three.color3:hover{
    border-color:#d83030;
}

.counter_style_three_icon .icon {
    background: var(--primary);
    display: inline-block;
    height: 85px;
    width: 85px;
    line-height: 80px;
    color: #fff;
    font-size: 36px;
    border-radius: 50% 15%;
    position: relative;
    margin-top: -50px;
}
.counter_style_three:hover{
    border: 2px solid var(--primary);
}
/* Counter Style Four */
.counter_style_four{
    margin-bottom:30px;
}
.counter_style_four_icon .icon {
    font-size: 42px;
    color: #fff;
}
.counter_style_four_icon .icon:before {
    content: '';
    position: absolute;
    left: -23px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -8px;
    width: 85px;
    height: 85px;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
    transform: rotate(-70deg);
    background-repeat: no-repeat;
    background-size: cover;
}
.counter_style_four_text h1 {
    color: #fff;
    font-size: 35px;
}
.counter_style_four_text h5 {
    color: #fff;
    font-size: 18px;
}
.counter_style_four:hover .counter_style_four_icon .icon:before{
    transform:rotate(-130deg);
}
/*odoo counter section*/
.counter_area.odoo .countr_text h1 {
    margin-bottom: 11px;
}
.counter_area.odoo .text_center {
    text-align: center;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #A4C2F3;
}
.counter_area.odoo .text_center.last{
    border:none;
    border-color:inherit;
}
/*erpsection*/
.erp_area .section_content_text p, .section-inner-content p {
    font-size: 16px;
    line-height: 24px;
}
.erp_area .text_left .section_main_title h1 {
    font-size: 39px;
}
/*odoo section*/

.odoo_section.pt-70.pb-100 {
    background: var(--primary);
    padding: 81px 0 224px;
    position: relative;
    z-index: 1;
}
.odoo_section.pt-70.pb-100 .shape_img {
    position: absolute;
    top: 70px;
    right: 20px;
    z-index:-1;
}

/*====================================================
--<	Techno Testimonial Area Css -->
======================================================*/
.single_testimonial_content_text {
    box-shadow: 0 18px 17px 0 rgba(0,0,0,.1);
    padding: 32px 43px 20px;
    position: relative;
    background: #fff;
}
.single_testimonial_content_text:before {
    content: '';
    bottom: -20px;
    top: auto;
    border-color: #fff transparent transparent #fff;
    display: block;
    position: absolute;
    left: 0;
    margin-left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px;
}
.single_testimonial_thumb {
    float: left;
}
.single_testimonial_thumb img {
    width: 100%;
    border-radius: 50%;
    margin: auto;
}
.single_testimonial_content_title h4 {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 700;
}
.single_testimonial_content_title span {
    color: var(--primary);
    font-weight: 500;
    font-size: 16px;
    display: block;
    padding-top: 4px;
}
.single_testimonial_content_title.white span {
    color: #fff;
}
.experience_thumb img {
    width: 100%;
}
/*odoo */
.testimonial_area.odoo .single_testimonial_content_text {
    position: relative;
    padding: 52px 30px 35px;
    -webkit-box-shadow: 0 25px 40px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 40px;
}
.testimonial_area.odoo .single_testimonial_content_title h4 {
    font-size: 20px;
    margin-top: 0;
    font-weight: 700;
}
.testimonial_area.odoo .testi-star i.active {
    color: var(--primary);
    font-size: 21px;
    margin: 0 1px;
}
.testimonial_area.odoo .single_Testimonial_inner_content {
    position: relative;
    top: 8px;
}
/* Testimonial Style Two */
.single_testimonial_two {
    box-shadow: 0px 0px 27px 0px rgba(0,0,0,.17);
    text-align: center;
    background: #fff;
    padding: 40px 20px 30px;
}
.single_testimonial_thumb_two img {
    border-radius: 50%;
    margin: auto;
}
.single_testimonial_content_title_two h4 {
    font-size: 22px;
}
.single_testimonial_content_title_two span {
    color: var(--primary);
}
@keyframes checkAnim {
    0%,
    100% {
        box-shadow:0 0 0 5px rgba(12,90,219, 0.8)
    }
    50% {
        box-shadow:0 0 0 8px rgba(12,90,219, 0.8)
    }
}

/* Tesimonial Style Two */
#section-quote{width:100%;position:relative;overflow:hidden;text-align:center}
#section-quote > h2{color:#0e1c2a;font-family:OpenSans-Regular;font-size:36px;font-weight:normal}
#section-quote > p{color:#767676;font-family:OpenSans-Light;font-size:18px;text-align:center;margin-top:10px}
#section-quote .container-quote{position:relative;width:100%;margin:55px 0 150px;height:135px;-webkit-perspective:10000px;-moz-perspective:10000px;-ms-perspective:10000px;perspective:10000px}
#section-quote .container-quote .quote{width:520px;height:100%;position:absolute;top:0px;left:50%;cursor:pointer;text-align:center;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:all 1s cubic-bezier(0.5,-0.01, 0, 1.005);-moz-transition:all 1s cubic-bezier(0.5,-0.01, 0, 1.005);-o-transition:all 1s cubic-bezier(0.5,-0.01, 0, 1.005);transition:all 1s cubic-bezier(0.5,-0.01, 0, 1.005)}
#section-quote .container-quote .quote.hide-top{opacity:0;-webkit-transform:translateX(-50%) translateY(-100%) rotateX(70deg);-moz-transform:translateX(-50%) translateY(-100%) rotateX(70deg);-ms-transform:translateX(-50%) translateY(-100%) rotateX(70deg);-o-transform:translateX(-50%) translateY(-100%) rotateX(70deg);transform:translateX(-50%) translateY(-100%) rotateX(70deg)}
#section-quote .container-quote .quote.show{opacity:1;-webkit-transform:translateX(-50%) translateY(0%) rotateX(0deg);-moz-transform:translateX(-50%) translateY(0%) rotateX(0deg);-ms-transform:translateX(-50%) translateY(0%) rotateX(0deg);-o-transform:translateX(-50%) translateY(0%) rotateX(0deg);transform:translateX(-50%) translateY(0%) rotateX(0deg)}
#section-quote .container-quote .quote.hide-bottom{opacity:0;-webkit-transform:translateX(-50%) translateY(100%) rotateX(-70deg);-moz-transform:translateX(-50%) translateY(100%) rotateX(-70deg);-ms-transform:translateX(-50%) translateY(100%) rotateX(-70deg);-o-transform:translateX(-50%) translateY(100%) rotateX(-70deg);transform:translateX(-50%) translateY(100%) rotateX(-70deg)}
#section-quote .container-quote .quote p{line-height:28px;display:block}
#section-quote .container-quote .quote .container-info{display:inline-block;padding-left:40px;position:relative;margin-top:20px;text-align:left}
#section-quote .container-quote .quote .container-info .pp{height:30px;width:30px;border-radius:100%;position:absolute;left:0px;top:50%;border:2px solid #fff;box-shadow:0 2px 3px rgba(0,0,0,0.1);background-repeat:no-repeat;background-position:center center;background-size:120%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%)}
#section-quote .container-quote .quote .container-info .name {color: #444;font-size: 22px;font-weight: 600;}
#section-quote .container-quote .quote .container-info .job{color:#666;font-size:16px}
#section-quote .container-pe-quote{position:absolute;height:100%;top:0px;z-index:1}
#section-quote .container-pe-quote.left{left:110px}
#section-quote .container-pe-quote.right{right:80px}
#section-quote .container-pe-quote .pp-quote{cursor:pointer;position:absolute;border-radius:100%;box-shadow:0 10px 30px rgba(0, 0, 0, 0.15);animation-name:floating;-webkit-animation-name:floating;animation-iteration-count:infinite;-webkit-animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-transform-origin:50% 1px;-moz-transform-origin:50% 1px;-ms-transform-origin:50% 1px;-o-transform-origin:50% 1px;transform-origin:50% 1px;-webkit-transition:all 2s ease;-moz-transition:all 2s ease;-ms-transition:all 2s ease;-o-transition:all 2s ease;transition:all 2s ease}
#container-general .anim-section-quote #section-quote .container-pe-quote .pp-quote{opacity:1}
#section-quote .container-pe-quote .pp-quote .img{overflow:hidden;border-radius:100%;height:100%;width:100%;background-repeat:no-repeat;background-position:center center;background-size:120%;position:relative;z-index:1}

#section-quote .container-pe-quote .pp-quote:after{content:'';position:absolute;top:50%;left:50%;height:calc(100% + 5px);width:calc(100% + 5px);border-radius:100%;background-color:#fff;-webkit-transform:translate(-50%,-50%) scale(1);-moz-transform:translate(-50%,-50%) scale(1);-ms-transform:translate(-50%,-50%) scale(1);-o-transform:translate(-50%,-50%) scale(1);transform:translate(-50%,-50%) scale(1);-webkit-transition:all .25s ease;-moz-transition:all .25s ease;-ms-transition:all .25s ease;-o-transition:all .25s ease;transition:all .25s ease}
#section-quote .container-pe-quote .pp-quote:hover:after,
#section-quote .container-pe-quote .pp-quote.active:after{background:var(--primary);-webkit-transform:translate(-50%,-50%) scale(1.05);-moz-transform:translate(-50%,-50%) scale(1.05);-ms-transform:translate(-50%,-50%) scale(1.05);-o-transform:translate(-50%,-50%) scale(1.05);transform:translate(-50%,-50%) scale(1.05);-webkit-transition:all .25s ease;-moz-transition:all .25s ease;-ms-transition:all .25s ease;-o-transition:all .25s ease;transition:all .25s ease;}
@keyframes floating{from{-webkit-transform:rotate(0deg) translate(-10px) rotate(0deg);-moz-transform:rotate(0deg) translate(-10px) rotate(0deg);-ms-transform:rotate(0deg) translate(-10px) rotate(0deg);-o-transform:rotate(0deg) translate(-10px) rotate(0deg);transform:rotate(0deg) translate(-10px) rotate(0deg)}
    to{-webkit-transform:rotate(360deg) translate(-10px) rotate(-360deg);-moz-transform:rotate(360deg) translate(-10px) rotate(-360deg);-ms-transform:rotate(360deg) translate(-10px) rotate(-360deg);-o-transform:rotate(360deg) translate(-10px) rotate(-360deg);transform:rotate(360deg) translate(-10px) rotate(-360deg)}
}
@-webkit-keyframes floating{from{-webkit-transform:rotate(0deg) translate(-10px) rotate(0deg);-moz-transform:rotate(0deg) translate(-10px) rotate(0deg);-ms-transform:rotate(0deg) translate(-10px) rotate(0deg);-o-transform:rotate(0deg) translate(-10px) rotate(0deg);transform:rotate(0deg) translate(-10px) rotate(0deg)}
    to{-webkit-transform:rotate(360deg) translate(-10px) rotate(-360deg);-moz-transform:rotate(360deg) translate(-10px) rotate(-360deg);-ms-transform:rotate(360deg) translate(-10px) rotate(-360deg);-o-transform:rotate(360deg) translate(-10px) rotate(-360deg);transform:rotate(360deg) translate(-10px) rotate(-360deg)}
}
#section-quote .container-pe-quote .li-quote-1{height:90px;width:90px;left:40px;top:30px;animation-duration:10s;-webkit-animation-duration:10s;-webkit-transition-delay:0.6s;transition-delay:0.6s}
#section-quote .container-pe-quote .li-quote-2{height:63px;width:63px;left:150px;top:150px;animation-duration:12s;-webkit-animation-duration:12s;-webkit-transition-delay:0s;transition-delay:0s}
#section-quote .container-pe-quote .li-quote-3{height:82px;width:82px;left:-70px;top:180px;animation-duration:11s;-webkit-animation-duration:11s;-webkit-transition-delay:0.6s;transition-delay:0.6s}
#section-quote .container-pe-quote .li-quote-4{height:48px;width:48px;left:80px;top:250px;animation-duration:6s;-webkit-animation-duration:6s;-webkit-transition-delay:0.2s;transition-delay:0.2s}
#section-quote .container-pe-quote .li-quote-5{height:73px;width:73px;left:-60px;top:320px;animation-duration:7s;-webkit-animation-duration:7s;-webkit-transition-delay:0.7s;transition-delay:0.7s}
#section-quote .container-pe-quote .li-quote-6{height:63px;width:63px;left:65px;top:380px;animation-duration:5s;-webkit-animation-duration:5s;-webkit-transition-delay:0.1s;transition-delay:0.1s}
#section-quote .container-pe-quote .li-quote-12{height:63px;width:63px;left:-15px;top:460px;animation-duration:8s;-webkit-animation-duration:8s;-webkit-transition-delay:0.3s;transition-delay:0.3s}
#section-quote .container-pe-quote .li-quote-7{height:73px;width:73px;right:-15px;top:30px;animation-duration:5s;-webkit-animation-duration:5s;-webkit-transition-delay:0.7s;transition-delay:0.7s}
#section-quote .container-pe-quote .li-quote-8{height:73px;width:73px;right:50px;top:150px;animation-duration:8s;-webkit-animation-duration:8s;-webkit-transition-delay:0.4s;transition-delay:0.4s}
#section-quote .container-pe-quote .li-quote-9{height:48px;width:48px;right:150px;top:90px;animation-duration:7s;-webkit-animation-duration:7s;-webkit-transition-delay:0.3s;transition-delay:0.3s}
#section-quote .container-pe-quote .li-quote-10{height:90px;width:90px;right:225px;top:250px;animation-duration:8s;-webkit-animation-duration:8s;-webkit-transition-delay:0.4s;transition-delay:0.4s}
#section-quote .container-pe-quote .li-quote-11{height:52px;width:52px;right:40px;top:320px;animation-duration:7s;-webkit-animation-duration:7s;-webkit-transition-delay:0.5s;transition-delay:0.5s}
#section-quote .container-pe-quote .li-quote-13{height:42px;width:42px;right:128px;top:380px;animation-duration:9s;-webkit-animation-duration:9s;-webkit-transition-delay:0.2s;transition-delay:0.2s}
#section-quote .container-pe-quote .li-quote-14{height:48px;width:48px;right:108px;top:470px;animation-duration:7s;-webkit-animation-duration:7s;-webkit-transition-delay:0.2s;transition-delay:0.2s}

/* Testimonial Style Three */
.testimonial_style_three_content {
    position: relative;
    z-index: 1;
    padding: 50px 50px 50px 50px;
}
.testimonial_style_three_content::before {
    position: absolute;
    content: "";
    left: -100px;
    top: 0;
    height: 100%;
    width: 123%;
    background: var(--primary);
    z-index: -1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 17% 0%);
}

.testimonial_style_three_title {
    border-left: 2px solid #fff;
    padding-left: 25px;
}
.testimonial_style_three_title h4 {
    color: #fff;
    font-weight: 800;
}
.testimonial_style_three_title span {
    color: #fff;
    font-weight: 600;
}
.testimonial_style_three_text p {
    color: #fff;
    font-size: 20px;
    font-style: italic;
}
.testimonial_style_three_reviwer_star i {
    color: #fff;
    font-size: 20px;
    display: inline-block;
    margin-right: 2px;
}
.testimonial_style_three_quote i {
    font-size: 50px;
    color: #fff;
}
/*testimonial area style two*/
.testimonial-area {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/*testi counter box*/
.testimonial-single-box1 {
    position: relative;
    z-index: 1;
}
.testi-counter-box {
    position: absolute;
    z-index: 1;
    top: 180px;
    left: -70px;
}
.testi-counter-box-inner {
    width: 155px;
    height: 155px;
    text-align: center;
    background: #FF6A32;
    border-radius: 50%;
    padding: 10px 0 0;
    border: 12px solid #EBC7C4;
}
/*testi counter title*/
.testi-counter-title h3 {
    font-size: 26px;
    font-weight: 800;
    line-height: 27px;
    color: #fff;
}
.testi-counter-icon i {
    display: inline-block;
    font-size: 13px;
    color: #ffffff;
    padding: 5px 0 0;
}
.testi-counter-text span {
    color: #fff;
    font-size: 15px;
    position: relative;
    top: -4px;
}
/*testimonial right box*/

.em-testimonial-title p {
    padding: 15px 0 15px;
}
.people-thumb {
    float: left;
    margin-right: 20px;
}
.people-title {
    display: inline-block;
}
.people-title h4 {
    font-size: 18px;
}
.people-title p {
    font-size: 15px;
    padding: 3px 0 0;
}
/*testimonial owl carousel*/
.testimonial_list3 .owl-dots {
    text-align: center;
    position: absolute;
    right: -460px;
    bottom: 22px;
    left: 0;
    cursor: pointer;
    animation: 1.5s fadeInRight;
}
.testimonial_list3 .owl-dot {
    height: 12px;
    width: 12px;
    background: #548DF7;
    line-height: 30px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 103px;
    transition: .5s;
}
.testimonial_list3 .owl-dot.active {
    background: #FF6933;
    width: 12px;
    height: 12px;
}
/*testimonial style three*/
.testimonial-area.style-two {
    background: #F7F9FD;
    padding: 290px 0 170px;
}
.style-two .people-thumb {
    float: left;
    margin-right: 32px;
    margin-top: 80px;
    position: relative;
}
.style-two .testimonial-content {
    overflow: hidden;
    padding: 0px 32px 30px;
    border-right: 12px solid #f0f2f1;
    border-bottom: 12px solid #f0f2f1;
    transition: .5s;
}
.style-two .em-testimonial-title h6 {
    font-size: 17px;
}
.style-two .em-testimonial-title h6 span {
    text-align: right;
    font-size: 16px;
    color: #828fa0;
    padding: 0 0 0 26px;
}
.style-two .em-testimonial-text p {
    color: #828fa0;
    padding: 5px 0 10px;
}
.quote-icon i {
    font-size: 80px;
    color: #0B59DB;
}
/*testimonial all hover*/
.testimonial-content:hover {
    border-bottom: 12px solid #0B59DB;
    border-right: 12px solid #0B59DB;
}

/*owl carsousel*/
.testimonial-area.style-two .owl-dots {
    text-align: center;
    position: relative;
    right: 0;
    left: 0;
    cursor: pointer;
    top: 65px;
}
/*testimonial area style three*/
.style-three.testimonial-area {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 950px;
    padding: 120px 0 140px;
}
.style-three .em-testimoinal-single-box {
    padding: 81px 0 0;
}
/*testimonial content*/
.style-three .testimonial-content1 {
    border-radius: 5px;
    background-color: #ffffff;
    text-align: center;
    padding: 80px 35px 45px;
    position: relative;
    z-index: 1;
}
.style-three .testimonial-content1::before {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    filter: drop-shadow(0px 10px 20px rgba(35,50,83,0.07));
    background-image: linear-gradient(-45deg, #00237d 0%, #0b59db 100%);
    content: "";
    transition: .5s;
    width: 0%;
    border-radius: 5px;
    z-index: -1;
}
.style-three .testimonial-content1:after {
    position: absolute;
    content: "";
    left: -15px;
    top: 18px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    animation: moveLeftBounce 3s linear infinite;
}
/*quote icon*/
.style-three .single-quote-icon {
    position: absolute;
    top: -80px;
    right: 0;
    left: 0;
    margin: auto;
}
.style-three .quote-icon {
    position: relative;
    z-index: 1;
    filter: drop-shadow(0 0 15px rgba(35,50,83,0.07));
}
.style-three .quote-icon i {
    width: 123px;
    height: 115px;
    line-height: 115px;
    display: inline-block;
}
.style-three .quote-icon:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .4s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;
    opacity: 0;
}
.style-three .quote-icon:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .4s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;
}
/*testi icon*/
.style-three .em-testi-start-icon i {
    font-size: 16px;
    color: #FFC107;
    display: inline-block;
    padding: 5px 9px 0 0;
}
.style-three .em-testimonial-title h6 {
    font-size: 18px;
    font-weight: 600;
    transition: .5s;
}
.em-testimonial-text p {
    transition: .5s;
}
/*testimonial active box*/
.style-three .active.testimonial-content1::before {
    left: 0;
    width: 100%;
}
.style-three .active.quote-icon:before {
    opacity: 1;
}
.style-three .active.quote-icon:after {
    opacity: 0;
}
.style-three .active.quote-icon i {
    color: #fff;
}
.style-three .active.em-testimonial-text p {
    color: #fff;
}
.style-three .active.em-testimonial-title h6 {
    color: #fff;
}


/*testimonial hover*/
.style-three .testimonial-content1:hover:before {
    width: 100%;
    left: 0;
}
.style-three .testimonial-content1:hover .quote-icon:after {
    opacity: 0;
}
.style-three .testimonial-content1:hover .quote-icon:before {
    opacity: 1;
}
.style-three .testimonial-content1:hover .em-testimonial-title h6,
.style-three .testimonial-content1:hover .em-testimonial-text p, .style-three .testimonial-content1:hover .quote-icon i  {
    color:#fff;
}
/*testi5 owl nav*/
.owl-nav1 .owl-carousel .owl-nav.disabled {
    display: inline-block;
    position: absolute;
    bottom: -70px;
    left: 50%;
}
.owl-nav1 .owl-next i {
    background-color: transparent;
    border-radius: 100%;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 40px;
    z-index: 1;
    transition: all 0.3s ease 0s;
    line-height: 40px;
    color: var(--primary);
    border: 2px solid var(--primary);
    text-align: center;
    margin-right: 25px;
    display: inline-block;
    font-size: 18px;
}
.owl-nav1 .owl-prev i {
    background-color: var(--primary);
    border-radius: 100%;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 40px;
    z-index: 1;
    transition: all 0.3s ease 0s;
    line-height: 40px;
    color: #fff;
    border: 2px solid var(--primary);
    text-align: center;
    display: inline-block;
    font-size: 18px;
}
/*====================================================
--<	Techno Tab Area Css -->
======================================================*/
.nagative_margin2 {
    position: relative;
    margin-top: -108px;
}
.nav-tabs .nav-item {
    margin: 0 5px;
}
.nav-tabs .nav-link {
    border: 1px solid var(--primary) !important;
    background: var(--primary);
    padding: 22px 50px;
    font-size: 20px;
    color: #fff;
}
.nav-tabs .nav-item.active .nav-link {
    border: 1px solid #fff !important;
    background: #fff;
    color: var(--primary);
}
.nav-tabs {
    border-bottom: inherit !important;
}
.nav-tabs .nav-link i {
    font-size: 30px;
    display: block;
    text-align: center;
    padding-bottom:5px;
}
.tab_thumb img {
    width: 100%;
}
.tab_content_title h4 {
    font-weight: 700;
}
.tab_content_sub_text ul {
    display: block;
}
.tab_content_sub_text ul li {
    list-style: none;
    margin-bottom: 5px;
}
/*====================================================
--<	Techno Choose Us Area Css -->
======================================================*/
.choose_us .techno_flipbox .techno_flipbox_font, .choose_us .techno_flipbox .techno_flipbox_back {
    min-height: 410px;
}
.choose_us .techno_flipbox .techno_flipbox_font{
    position:relative;
    border:0 !important;
}
.choose_us .techno_flipbox .techno_flipbox_font::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg,black 0%,rgba(255,255,255,0) 100%) !important;
}
.choose_us .techno_flipbox .techno_flipbox_inner {
    top: 72%;
}
.choose_us .techno_flipbox .techno_flipbox_back .techno_flipbox_inner {
    top: 50%;
}

/* Style Two */
.choose_icon_lft{
    float:left;
}
.choose_icon_lft i {
    color: var(--primary);
    height: 70px;
    width: 70px;
    line-height: 68px;
    border: 1px solid #307cff;
    border-radius: 4px 4px 4px 4px;
    font-size:30px;
    text-align:center;
    background:transparent;
    transition:.5s;
}
.choose_content_title_lft h5 {
    font-weight: 700;
}
.choose_us_content:hover .choose_icon_lft i{
    background:var(--primary);
    color:#fff;
}
.choose_content_text_bold p {
    font-size: 19px;
    font-weight: 600;
    color: #232323;
}
/* New Choose Us */
.single_choose_us_thumb img {
    width: 100%;
}
.single_choose_us_icon .icon {
    height: 70px;
    width: 70px;
    background: var(--primary);
    line-height: 68px;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 35px;
    float: left;
    margin-right: 20px;
    border:1px solid var(--primary);
    transition:.5s;
}
.single_choose_us_content:hover .single_choose_us_icon .icon{
    background:transparent;
    color:var(--primary);


}
/*style three*/
.why-choose-us.style-two {
    background: #F7F9FD;
}
.choose-icon h6 {
    font-size: 18px;
    color: #1d2124;
    font-weight: 600;
    display: inline-block;
    margin-left: 10px;
}
.choose-icon span i {
    display: inline-block;
    font-size: 14px;
}
span.chose-icon {
    width: 20px;
    height: 20px;
    line-height: 19px;
    text-align: center;
    background: #ff6900;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
}
span.chose-icon1 {
    width: 20px;
    height: 20px;
    line-height: 19px;
    text-align: center;
    background: #0B59DB;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
}
span.chose-icon2 {
    width: 20px;
    height: 20px;
    line-height: 19px;
    text-align: center;
    background: #E73D8D;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
}
.choose-icon-text p {
    padding: 7px 0 0;
    margin: 0 0 11px;
    color: #828fa0;
}
/*why choose us right thumb*/
.why-choose-us-thumb {
    position: relative;
    z-index: 1;
}
.why-choose-shape-thumb {
    position: absolute;
    top: 177px;
    left: -15px;
}
.why-choose-main-thumb {
    position: relative;
}
.why-choose-main-thumb1 {
    position: relative;
    left: -75px;
}
/*why choose area style four*/
.why-choose-us.style-four {
    background: #F4F5FA;
}
.style-four span.chose-icon {
    width: 27px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    filter: drop-shadow(0px 3px 10.5px rgba(33,184,255,0.35));
    background-image: linear-gradient(0deg, #615dff 0%, #19c3ff 100%);
    border-radius: 15px 0px 15px 15px;
    color: #fff;
    display: inline-block;
}
.style-four span.chose-icon1 {
    width: 27px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    filter: drop-shadow(0px 3px 10.5px rgba(254,94,40,0.35));
    background-image: linear-gradient(0deg, #fd4f2f 0%, #ff9b07 100%);
    border-radius: 15px 0px 15px 15px;
    color: #fff;
    display: inline-block;
}
.style-four span.chose-icon2 {
    width: 27px;
    height: 27px;
    line-height: 27px;
    text-align: center;
    background-image: linear-gradient(0deg, #ff4f25 0%, #ff47ad 100%);
    filter: drop-shadow(0px 3px 10.5px rgba(255,72,163,0.35));
    border-radius: 15px 0px 15px 15px;
    color: #fff;
    display: inline-block;
}


.style-four .choose-icon span i {
    display: inline-block;
    font-size: 20px;
}
/*choose shape*/

.choose-shape {
    position: absolute;
    top: 5px;
    left: -340px;
    z-index: -1;
}
/*why choose area style five*/
.why-choose-us.style-five {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.style-five p.upper-text {
    font-size: 16px;
    padding: 18px 0 0px;
}
.style-five .choose-us-number {
    position: relative;
    z-index: 1;
    float: left;
    margin-right: 25px;
}
.style-five .choose-us-number:before {
    position: absolute;
    content: "";
    left: -7px;
    top: 4px;
    width: 34px;
    height: 34px;
    background-image: linear-gradient(131deg, #1129b9 0%, #2473fb 100%);
    border-radius: 30px;
    z-index: -1;
}
.style-five .choose-us-number span {
    font-size: 20px;
    font-weight: 700;
    width: 56px;
    height: 56px;
    line-height: 50px;
    border: 3px solid #ffffff;
    background: #23B5FF;
    display: inline-block;
    text-align: center;
    border-radius: 30px;
    color: #fff;
    margin-top: 12px;
}
/*upper*/
.style-five .upper .choose-us-number:before {
    background: #23b5ff;
}
.style-five .upper .choose-us-number span {
    background-image: linear-gradient(131deg, #1129b9 0%, #2473fb 100%);
}
.style-five .choose-us-title {
    overflow: hidden;
}
.style-five .choose-us-title h3 {
    font-size: 22px;
    padding: 0 0 10px;
    display: inline-block;
    margin: 0;
}
/*choose us right*/


.style-five .why-choose-us-thumb {
    position: relative;
    z-index: 1;
    left: 0;
}
.style-five .why-choose-us-thumb img {
    width: 100%;
}

/*choose us thumb*/
.choose-us-thumb {
    position: absolute;
    top: 100px;
    right: -148px;
    z-index: 1;
}
.choose-us-thumb1 {
    position: absolute;
    top: 338px;
    right: -148px;
}

/*====================================================
--<	Techno Fun Faq Area Css -->
======================================================*/
.single_fun_faq {
    padding: 20px 30px;
    margin-bottom:30px;
}
.single_fun_faq_number h1 {
    font-size: 45px;
    font-weight: 800;
}
/*====================================================
--<	Techno Accordion Area Css -->
======================================================*/
.main-accordion-lt {
    background: #2d2d2d;
}
.acd-items.acd-arrow {
    margin-left: 160px;
}
.panel-heading .panel-title a {
    background: #393939;
    display: block;
    font-weight: 600;
    padding: 10px 15px;
    position: relative;
    color: #fff;
    font-size: 18px;
}
.faq .panel-heading .panel-title a {
    background: #eff2f7;
    color:#232323;
    transition:.5s;
}
.faq .panel-heading .panel-title a:hover {
    color:var(--primary);
}

.faq .panel-body p {
    color: #8a8a8a;
}
.acd-items .panel-heading {
    padding: 0;
}

.panel.panel-default {
    border-color: #e7e7e7;
    border-radius: inherit;
    box-shadow: inherit;
}
.panel-body p {
    color: #cccccc;
}
.default .panel-body p {
    color: #666;
}
.accordion-area .absod {
    padding: 0;
}
.panel-heading.mb-3 a i {
    font-size: 15px;
    margin-right: 10px;
}
.panel-group.symb .panel-heading a::after {

    content:"-";
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 7px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.panel-group.symb .panel-heading a.collapsed:after {
    content: "+";
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.single_faq_title h4 {
    font-weight: 700;
}

/*style two*/
.style-two .panel-heading .panel-title a {
    background: var(--primary) !important;
    color: #fff;
    border: 1px solid #d4d4d4 !important;
    padding: 20px 15px;
    font-size: 20px;
}

.style-two .panel-heading.mb-3 a i {
    font-size: 20px;
    margin-right: 10px;
}

.style-two .panel-group.symb .panel-heading a.collapsed:after {
    top: 19px;
}

.style-two .panel-group.symb .panel-heading a::after {
    top: 17px;
}








/*====================================================
--<	Techno Contact Area Css -->
======================================================*/
.form_box input {
    width: 100%;
    height: 50px;
    padding-left: 15px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    transition:.5s;
}

.form_box textarea {
    width: 100%;
    background: transparent;
    padding-left: 15px;
    padding-top: 20px;
    height: 250px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    transition:.5s;
}
.quote_btn button {
    background: #fff;
    border: 0;
    display: inline-block;
    text-align: center;
    padding: 14px 39px;
    border-radius: 7px;
    transition: .3s;
}
.quote_btn button:hover {
    background: #00247e;
    color:#fff;
}
.appointment_form_inner{
    background: #ffffff;
    padding: 70px 70px 70px 70px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0 5px 30px rgba(0,0,0,0.15);
}
.appointment_title h1 {
    text-align: center;
    font-weight: 800;
}
.appointment_form .form_box input {
    border: 1px solid #e6e6e6;
    color: #495057;
}
.appointment_form .form_box textarea {
    border: 1px solid #e6e6e6;
    color: #495057;
}
.appointment_form .quote_btn button {
    background: var(--primary);
    color:#fff;
}
.appointment_form  .form_box input:focus,
.appointment_form  .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.main_contact_area .form_box input {
    border: 1px solid #e6e6e6;
    color: #495057;
    background-color: #fff;
}
.main_contact_area .form_box textarea {
    border: 1px solid #e6e6e6;
    color: #495057;
    background-color: #fff;
}
.main_contact_area .quote_btn button {
    background: var(--primary);
    color:#fff;
}
.main_contact_area  .form_box input:focus,
.main_contact_area  .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.map {
    width: 100%;
    margin: 0;
    height: 410px;
    border: 1px solid #ddd;
    border-radius: 10px;
}
.google_map_area .col-lg-12{
    padding: 0;
}
/* Contact Style Three */
.style_three .contact_address_company ul li {
    list-style: none;
    display: block;
    margin-bottom: 12px;
}
.style_three .contact_address_company ul li i {
    font-size: 25px;
    color: #fff;
    margin-right: 15px;
    height: 55px;
    width: 55px;
    line-height: 55px;
    background: var(--primary);
    text-align: center;
    border-radius: 50%;
}
.style_three .contact_address_company ul li a {
    color: #616161;
}
.style_three .contact_from_box {
    box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4);
    background: #fff;
    padding: 35px 50px 40px;
    border-top: 8px solid var(--primary);
    border-radius: 5px;
}
.style_three .form_box textarea {
    height: 180px;
}
.style_three .footer-bottom-content-copy p {
    color: #232323;
}
.style_three .footer-bottom-right-text a {
    color: #232323;
}
/*contact seo area */
.techno-seo-area {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 80px 0 85px;
}
.techno-seo-title h3 {
    font-size: 30px;
    color: #fff;
}
.techno-seo-title p {
    padding: 22px 0 17px;
    color: #fff;
}
.upper1 .subscribe_form input {
    font-size: 14px;
    height: 70px;
    padding: 5px 25px;
    position: relative;
    background: #f8f9fa;
    margin: auto;
    border: 10px solid #ffffff;
    border-radius: 10px;
    width: 620px;
}
.upper1 .subscribe_form_send button {
    transition: all 0.4s ease 0s;
    border: medium none;
    border-radius: 5px;
    background: var(--primary);
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    margin: auto;
    padding: 13px 28px;
    position: absolute;
    right: 270px;
    bottom: -3%;
    top: inherit;
    display: inline-block;
    transform: translateY(-50%);
    z-index: 11;
}
/*seo thumb*/
.seo-shape-thumb {
    position: absolute;
    left: -224px;
    bottom: 0px;
}
.seo-shape-thumb1 {
    position: absolute;
    top: 101px;
    left: 199px;
}
.seo-shape-thumb2 {
    position: absolute;
    right: 40px;
    top: 85px;
}
.seo-shape-thumb2 img {
    width: 75%;
}
.seo-shape-thumb3 {
    position: absolute;
    right: -155px;
    bottom: 0;
}
/*right text*/
p.seo-text {
    padding: 19px 0 2px;
    color: #828fa0;
}
p.seo-text1 {
    padding: 0px 0 8px;
    color: #828fa0;
}
/*====================================================
--<	Techno Contact Address Area Css -->
======================================================*/
.single_contact_address {
    border: 1px solid #d0e5fb;
    border-radius: 5px 5px 5px 5px;
    padding: 30px 25px 15px;
}
.contact_address_icon i {
    font-size: 40px;
    color: var(--primary);
}
.contact_address_title h4 {
    font-weight: 700;
}
.single_contact_address_two {
    background: #fff;
    box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.2);
    padding: 30px 22px 37px;
    margin-bottom: 30px;
}
.single_contact_address_two_content h4 {
    font-size: 22px;
}
.single_contact_address_two_icon .icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-size: 35px;
    display: inline-block;
    background: var(--primary);
    text-align: center;
    border-radius: 50%;
    color: #fff;
    float: left;
    margin-right: 15px;
}
.single_contact_address_two_content span {
    color: var(--primary);
    text-transform: uppercase;
}
.single_contact_rt_thumb img {
    width: 100%;
}
.cnt_box {
    background: #fff;
    box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.2);
}
.contact_sm_area .form_box input {
    border: 1px solid #ddd;
}
.contact_sm_area .form_box textarea {
    border: 1px solid #ddd;
    height: 180px;
}
.contact_sm_area .quote_btn button {
    background: var(--primary);
    color: #fff;
}
.contact_sm_area .form_box input:focus,
.contact_sm_area .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.single_contact_abs_thumb img {
    width: 100%;
}
.app .form_box textarea {
    height: 170px;
}
/* Contact Address Style Two */
.single_contact_now {
    background: #fff;
    padding: 25px;
    text-align: center;
    border-radius: 5px;
    margin-bottom:30px;
}
.single_contact_now_inner {
    border: 2px solid #ddd;
    padding: 30px 20px 15px;
    border-radius: 5px;
    transition:.5s;
}
.single_contact_now_icon i {
    color: var(--primary);
    font-size: 40px;
}
.single_contact_now:hover .single_contact_now_inner{
    border-color: var(--primary);
}
/*style three*/


/*====================================================
--<	Techno Blog Area Css -->
======================================================*/
.single_blog {
    border: 1px solid #ebebeb;
    overflow: hidden;
    transition:.5s;
}
.single_blog_content{
    background: #fff;
}
.single_blog:hover {
    border-color: transparent;
    box-shadow:0 5px 30px rgba(0,0,0,0.2);
}
.blog_page_title h3 {
    font-size: 21px;
    transition: .5s;
}
.blog_page_title a {
    font-weight: 700;
}
.single_blog_thumb img {
    width: 100%;
}
.blog_page_button a {
    font-size: 15px;
    transition: .5s;
    color: #616161;
}
.blog_page_button.style_two a {
    font-size: 15px;
    transition: .5s;
    color: var(--primary);
    border: 1px solid var(--primary);
    display: inline-block;
    padding: 8px 30px;
    border-radius: 30px;
}
.blog_page_button a i {
    margin-left: 6px;
}
.blog_page_button a:hover {
    color:var(--primary);
}
.blog_page_title a:hover {
    color:var(--primary);
}
.techno_blog_meta a {
    color: #616161;
    transition:.3s;
    position:relative;
}
.techno_blog_meta a:before {
    position: absolute;
    right: -6px;
    height: 14px;
    width: 1px;
    background: #666;
    content: "";
    top: 5px;
}
.techno_blog_meta a:hover{
    color:var(--primary);
}

/*odoo blog*/
.blog_area.oddo {
    background: #EFF2F7;
}

/* Style Blog All */
.techno_blog_meta.shado_bg {
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.11);
    padding: 10px 12px;
    position: relative;
    margin-top: -26px;
    background: #fff;
    border-radius: 5px;
}
.single_blog_thumb.el_thumb {
    overflow: hidden;
}
.single_blog_thumb.el_thumb img{
    transition:.5s;
    overflow:hidden;
}
.single_blog:hover .single_blog_thumb.el_thumb img{
    transform: scale(1.2);
}
.single_blog_date {
    position: absolute;
    right: 0;
    top: 0;
}
.single_blog_date_inner {
    background: var(--primary);
    display: inline-block;
    padding: 2px 15px 10px;
}
.color2 .single_blog_date_inner {
    background: #fd8424;
}
.color3 .single_blog_date_inner {
    background: #d83030;
}
.single_blog_date_inner h3 {
    color: #fff;
}
.single_blog_date_inner span {
    display: block;
    color: #fff;
}
/*blog area style two*/
.single-blog-box {
    overflow: hidden;
    filter: drop-shadow(0 0 40px rgba(125,149,188,0.1));
    background-color: #ffffff;
    border-radius: 5px;
    transition: .5s;
    margin-bottom: 30px;
}
/*blog thumb*/
.single-blog-thumb {
    transition: .5s;
    overflow: hidden;
}
.single-blog-thumb img {
    width: 100%;
    overflow: hidden;
    transition: .5s;
}
/*blog content*/
.em-blog-content {
    padding: 12px 30px 25px;
    border-radius: 5px;
}

/*meta blog text*/
.meta-blog-text p {
    padding: 0 0 0 25px;
    margin: 0;
    position: relative;
    z-index: 1;
}
.meta-blog-text p:before {
    position: absolute;
    content: "";
    left: 0;
    top: 7px;
    width: 55%;
    height: 55%;
    background-repeat: no-repeat;
    background-size: contain;
}
/*em blog title*/
.em-blog-title h2 {
    font-size: 22px;
    padding: 4px 0 0;
}
.em-blog-title h2 a {
    font-size: 22px;
    color: #232323;
    display: inline-block;
    font-weight: 700;
    transition: .5s;
}
.em-blog-text p {
    display: inline-block;
    padding: 14px 0 22px;
    border-bottom: 1px solid  rgba(125,149,188,0.1);
    margin-bottom: 11px;
}
/*em blog icon*/
.em-blog-icon {
    display: inline-block;
    float: left;
}
.em-blog-thumb {
    float: left;
}
.em-blog-icon-title {
    display: inline-block;
}
.em-blog-icon-title h6 {
    font-size: 16px;
    margin-left: 10px;
}
/*blog button*/
.blog-button {
    text-align: right;
    padding: 6px 0 0;
}
.blog-button a {
    display: inline-block;
    color: #232323;
    text-decoration: underline;
    transition: .5s;
}
.blog-button a i {
    display: inline-block;
    font-size: 16px;
    padding: 0 0 0 5px;
    position: relative;
    top: 2px;
    color: #153AC8;
}
/*blog all hover*/
.single-blog-box:hover .em-blog-title h2 a {
    color: var(--primary);
    text-decoration: underline;
}
.single-blog-box:hover .blog-button a {
    color: var(--primary);
}
.single-blog-box:hover .single-blog-thumb img {
    transform: scale(1.1);
}
.single-blog-box:hover {
    filter: drop-shadow(0 0 13px rgba(125,149,188,0.2));
}
/*blog owl carousel*/

.blog-area.blog-new .owl-carousel .owl-dots.disabled, .blog-area.blog-new .owl-carousel .owl-nav.disabled {
    display: inline-block;
}
.blog-area.blog-new .owl-prev i {
    background-image: linear-gradient(to left, #2475FC, #1129B9);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    z-index: 1;
    transition: all 0.3s ease 0s;
    line-height: 50px;
    color: #fff;
    border: 2px solid #FFF;
    text-align: center;
    font-size: 25px;
}
.blog-area.blog-new .owl-next i {
    background-image: linear-gradient(to left, #2475FC, #1129B9);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    z-index: 1;
    transition: all 0.3s ease 0s;
    line-height: 50px;
    color: #fff;
    border: 2px solid #FFF;
    text-align: center;
    font-size: 25px;
}
.blog-area.blog-new .owl-next {
    position: absolute;
    top: -83px;
    right: 15px;
}
.blog-area.blog-new .owl-prev {
    position: absolute;
    top: -83px;
    right: 80px;
    left: inherit;
}
.blog-area.blog-new .owl-carousel:hover .owl-prev {
    left: inherit;
}
/*blog area style two*/
.blog-area .contact_from {
    background: var(--primary);
    padding: 30px 37px 5px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}
.blog-area .form_box input {
    width: 100%;
    height: 52px;
    padding-left: 15px;
    background: #3D7BE2;
    border: 0;
    border-radius: 5px;
    color: #fff;
    transition: .5s;
}
.blog-area .form_box input::placeholder {
    color: #fff;
}
/*contact title*/
.blog-area .contact-form-title h2 {
    font-size: 35px;
    color: #ffffff;
}
.blog-area .contact-form-title p {
    font-size: 18px;
    color: #fff;
    padding: 18px 0 20px;
}
.contact-form-icon {
    padding: 0 0 20px;
}
.blog-area .contact-form-icon i {
    font-size: 48px;
    color: #fff;
    display: inline-block;
    line-height: 136px;
    width: 123px;
    height: 115px;
    padding: 0 0 0 23px;
    position: relative;
    z-index: 1;
}
.blog-area .contact-form-icon i:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
}
/*contact form shape*/
.contact-form-shape {
    position: absolute;
    top: 75px;
    right: 60px;
}
/*blog box*/
.style-two .em-blog-content {
    padding: 32px 30px 25px;
    border-radius: 5px;
}

/*blog post*/
.style-two .meta-blog-post {
    background: #0B59DB;
    padding: 16px 18px 16px;
    border-radius: 3px;
    position: absolute;
    z-index: 11;
    bottom: 178px;
    left: 2px;
    right: 0;
    margin: auto;
    width: 87%;
}
.style-two .meta-blog-text1 {
    display: inline-block;
}
.style-two .meta-blog-text1 p {
    margin: 0;
    font-size: 14px;
    color: #fff;
}
.style-two .meta-blog-text1 p i {
    color: #fff;
    font-size: 14px;
    padding-right: 8px;
}
.style-two .meta-blog-comment {
    display: inline-block;
    padding: 0 0 0 30px;
}
.style-two .meta-blog-comment span {
    font-size: 14px;
    color: #fff;
}
.style-two .meta-blog-comment span i {
    margin-right: 8px;
    display: inline-block;
}
.style-two .em-blog-title h2 a {
    line-height: 1.5;
}
.style-two .em-blog-text p {
    display: inline-block;
    padding: 10px 0 0px;
    border-bottom: 0;
    margin-bottom: 0;
}
.style-two .single-blog-box:hover .em-blog-title h2 a {
    color: var(--primary);
    text-decoration: inherit;
}
/*blog area style three */
.up.blog-area.blog-new {
    background: #F8F8FF;
}
/*====================================================
--<	Techno Footer Subscribe Area Css -->
======================================================*/
.subscribe_content_title h2 {
    font-size: 32px;
    font-weight: 700;
}
.subscribe_form input {
    font-size: 14px;
    height: 65px;
    padding: 5px 25px;
    position: relative;
    width: 100%;
    background: #F6F6FC;
    border: 0;
    border-radius: 0;
    margin-bottom: 14px;
    margin: auto;
    border: 0;
    border-radius: 15px;
}
.subscribe_form_send button {
    text-align: center;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    border: medium none;
    border-radius: 15px;
    background: var(--primary);
    display: block;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    margin: auto;
    padding: 15px 43px;
    position: absolute;
    right: 20px;
    top: 74%;
    display: inline-block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.subscribe_form_send button:hover{
    color: #fff;
}
/* Style Two*/
.style_two .row.sbc_bg_box {
    background: var(--primary);
    border-radius: 15px;
    padding: 25px 0 55px;
    position: relative;
    margin-bottom: -140px;
    z-index: 1;
}
.style_two.tp-mg .row.sbc_bg_box {
    margin-bottom: 0;
    margin-top: -145px;
}
.style_two .subscribe_bg_box form {
    background: #fff;
    padding: 30px 40px 25px;
    border-radius: 80px;
}
.style_two .subscribe_form input {
    font-size: 14px;
    height: 65px;
    padding: 5px 25px;
    position: relative;
    width: 75%;
    background: #F6F6FC;
    border: 0;
    border-radius: 0;
    margin-bottom: 14px;
    margin: 0;
    border: 0;
    border-radius: 40px;
}
.style_two .subscribe_form_send button {
    border-radius: 40px;
    background: var(--primary);
    font-weight: 600;
    margin: auto;
    padding: 17px 55px;
    right: 55px;
    top: 68%;
    color:#fff;
}
.style_two .subscribe_form_send button:hover{
    color:#fff;
}
/*subsctibe style three*/
.style_three .row.sbc_bg_box {
    border-radius: 15px;
    padding: 42px 45px 50px;
    background-repeat: no-repeat;
}
/*content title*/
.style_three .subscribe_content_title h2 {
    font-size: 34px;
    font-weight: 800;
    line-height: 50px;
}
.style_three .subscribe_content_title p {
    font-size: 20px;
    padding: 6px 0 0px;
}

.subscribe_form {
    position: relative;
    z-index: 1;
}
.style_three .subscribe_form input {
    font-size: 14px;
    height: 68px;
    width: 640px !important;
    padding: 5px 25px;
    position: relative;
    width: 100%;
    background: #ffffff;
    border: 0;
    margin-bottom: 14px;
    border-radius: 10px;
}

.style_three .subscribe_form_send button {
    transition: all 0.4s ease 0s;
    border: medium none;
    border-radius: 10px;
    background: var(--primary);
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    margin: auto;
    padding: 15px 30px;
    position: absolute;
    right: 37px;
    top: 159px;
    display: inline-block;
    transform: translateY(-50%);
    z-index: 1;
}

.subscribe-thumb {
    position: relative;
    z-index: 1;
    top: 37px;
    left: 120px;
    animation: moveLeftBounce 3s linear infinite;
}
/*animation*/
@keyframes moveLeftBounce {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

/*style four*/
.upper .subscribe_form input {
    font-size: 14px;
    height: 70px;
    padding: 5px 25px;
    position: relative;
    width: 100%;
    background: #f8f9fa;
    margin-bottom: 14px;
    margin: auto;
    border: 10px solid #ffffff;
    border-radius: 10px;
}
.upper .subscribe_form_send button {
    transition: all 0.4s ease 0s;
    border: medium none;
    border-radius: 5px;
    background: var(--primary);
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    margin: auto;
    padding: 13px 28px;
    position: absolute;
    right: 12px;
    bottom: -4%;
    top: inherit;
    display: inline-block;
    transform: translateY(-50%);
    z-index: 11;
}

/*====================================================
--< Techno Footer Middle Area Css -->
======================================================*/

.footer-middle {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}
.widget-title {
    color: #fff;
    font-size: 22px;
}
.company-info-desc p {
    color: #fff;
}
.menu-quick-link-container ul li a {
    color: #fff;
    position:relative;
    transition:.5s;
}
.menu-quick-link-container ul li a:before {
    position: absolute;
    content: "";
    left: -24px;
    top: 10px;
    height: 1px;
    width: 13px;
    background: #fff;
}
.menu-quick-link-container ul li {
    padding-bottom: 10px;
    list-style: none;
}
.recent-post-text a {
    color: #fff;
    font-weight:500;
    transition:.5s;
}
.footer-middle .recent-post-image {
    float: left;
    margin-right: 20px;
}
.follow-company-text a {
    font-size: 16px;
    transition: .5s;
}
.follow-company-text a p {
    color:#fff;
    transition:.5s;
}
.follow-company-text {
    float: left;
}
.follow-company-icon a {
    font-size: 16px;
    color: #fff;
    margin: 0 5px;
    font-weight: 500;
    display: inline-block;
    transition: .5s;
}

.footer-social-info p span {
    font-weight: 600 !important;
    color: #fff;
}
.footer-social-info p {
    color: #fff;
}
.recent-post-text span {
    color: #adb0be;
}
.recent-post-item.active {
    border-bottom: 1px solid #adb0be;
}
.follow-company-icon a:hover{
    color:var(--primary);
}
.follow-company-text a p:hover {
    color:var(--primary);
}
.menu-quick-link-container ul li a:hover{
    color:var(--primary);
}
.recent-post-text a:hover{
    color:var(--primary);
}
/* Footer Style Two */
.footer_middle_area{
    overflow: hidden;
}
.footer_middle_social_icon{
    text-align:center;
}
.footer_middle_social_icon a {
    height: 65px;
    width: 65px;
    line-height: 65px;
    display: inline-block;
    background: var(--primary);
    border-radius: 50%;
    font-size: 25px;
    color: #fff;
    text-align:center;
    margin: 0 5px;
    transition:.5s;
}
.footer_middle_social_icon a.color1{
    background: #ef6330;
}
.footer_middle_social_icon a.color2{
    background: #43baff;
}
.footer_middle_social_icon a.color3{
    background: #ff9000;
}
.footer_middle_social_icon a.color4{
    background:#e60023;
}
.footer_middle_social_icon a.color5{
    background: #00247e;
}
.footer_middle_social_icon a.color6{
    background: #6659d4;
}
.footer_middle_social_icon a.color7{
    background: #5eb414;
}

.footer_middle_social_icon a:hover{
    background: var(--primary);
}
.footer_bottom_menu_inner {
    text-align: center;
}
.footer_bottom_menu_inner ul {
    list-style: none;
}
.footer_bottom_menu_inner ul li {
    display: inline-block;
}
.footer_bottom_menu_inner ul li a {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 0 12px;
    transition:.5s;
}
.footer_bottom_menu_inner ul li a:hover {
    color: var(--primary); ;
}
.footer_bg_shape {
    position: relative;
}
.footer_bg_shape:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 80%;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    left: 0;
    right: 0;
    margin: auto;
    opacity: .6;
}
/* Footer Style Three */

.style_three .widget-title {
    color: #232323;
    font-size: 22px;
}
.style_three .menu-quick-link-container ul li a {
    color: #232323;
}
.style_three .menu-quick-link-container ul li a:hover{
    color: var(--primary);
}
.style_three .company-info-desc p {
    color: #232323;
}
.style_three .footer-social-info p {
    color: #232323;
}
.style_three .recent-post-text a {
    color: #232323;
}
.style_three .follow-company-icon a {
    color: var(--primary);
}
.style_three .follow-company-text a p {
    color: #232323;
}
.style_three .footer-social-info p span {
    color: #232323;
}
.style_three .menu-quick-link-container ul li a:before {
    background: var(--primary);
}
/*footer style four*/
.style-four.footer-middle {
    background-repeat: no-repeat;
    background-size: cover;
}

.style-four .follow-company-text {
    float: inherit;
}
.style-four .follow-company-text a {
    font-size: 20px;
    transition: .5s;
    font-weight: 700;
}
a.social-icon-color {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #187DFF;
    border-radius: 5px;
}
a.social-icon-color1 {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #229FF9;
    border-radius: 5px;
}
a.social-icon-color2 {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: linear-gradient(to top left, #E94D5A, #C327A2);
    border-radius: 5px;
}
a.social-icon-color3 {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #F70102;
    border-radius: 5px;
}
.style-four .follow-company-icon a:hover {
    color: #fff;
}
/*quick links*/
.style-four .menu-quick-link-container ul li {
    padding-bottom: 15px;
    list-style: none;
}
.style-four .menu-quick-link-container ul li a:hover {
    color: #fff;
}
/*popular post*/
.style-four .recent-post-item.active {
    border-bottom: 1px solid rgba(255,255,255,.23);
}
.style-four .recent-post-text a {
    color: #fff;
    font-weight: 700;
    transition: .5s;
    font-size: 16px;
    line-height: 25px;
}
.style-four .recent-post-text span {
    color: #9EB3EF;
}
/*contact info*/
.style-four .company-info-title h6 {
    margin: 3px 0 3px;
    font-size: 18px;
    color: #fff;
}
.style-four .company-info-title p {
    color: #9EB3EF;
    padding: 0 0 7px;
}
.style-four .company-icon {
    float: left;
    margin-right: 20px;
}
.style-four .company-icon a i {
    display: inline-block;
    font-size: 17px;
    color: #fff;
    width: 38px;
    height: 38px;
    line-height: 40px;
    background: #3D65DE;
    text-align: center;
    border-radius: 50%;
}
/* footer style upper*/

.upper.style-four.footer-middle {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.upper.style-four .company-icon a i {
    display: inline-block;
    font-size: 17px;
    color: #fff;
    width: 38px;
    height: 38px;
    line-height: 40px;
    background: #33337B;
    text-align: center;
    border-radius: 50%;
}
/*====================================================
--<	Techno Footer Bottom Area Css -->
======================================================*/
.footer-bottom {
    border-top: 1px solid rgba(255,255,255,.23);
}
.footer-bottom-content-copy p {
    color: #fff;
}
.footer-bottom-right-text {
    text-align: right;
}
.footer-bottom-right-text a {
    color: #fff;
    display: inline-block;
}
.footer-bottom-right-text .absod {
    margin-right: 30px;
    position: relative;
}
.footer-bottom-right-text .absod::before {
    position: absolute;
    content: "";
    right: -17px;
    top: 6px;
    height: 14px;
    width: 1px;
    background: #fff;
    transform: skew(-20deg);
}

/*====================================================
--<	Techno Blog Details Area Css -->
======================================================*/
.blog-details-area {
    background: #fff;
}
.blog_dtl_top_bs span {
    background: var(--primary);
    padding: 8px 30px;
    color: #fff;
    border-radius: 30px;
}
.techno-blog-thumb img {
    transform: scale(1.01);
    transition: .5s;
    width: 100%;
}
.techno-single-blog.admin:hover .techno-blog-thumb img {
    transform: scale(1.2);
}
/* Blog Details  Content CSS */
.blog_dtl_content {
    padding: 38px 45px 45px;
    -webkit-box-shadow: 0 3px 19px rgba(0,0,0,.08);
    box-shadow: 0 3px 19px rgba(0,0,0,.08);
    border-radius: 0 0 10px 10px;
}
.recent_post_dtls {
    padding: 38px 45px 45px;
    -webkit-box-shadow: 0 3px 19px rgba(0,0,0,.08);
    box-shadow: 0 3px 19px rgba(0,0,0,.08);
    border-radius: 0 0 10px 10px;
}

.blog_dt_title h4 {
    font-weight: 700;
}
.blog_dtl_thumb img {
    width: 100%;
}
.blog_dtl_content h2 {
    color: #0d1218;
    font-size: 35px;
    padding-top: 20px;
    font-weight: 700;
}
.techno-blog-meta-left {
    padding: 12px 0 35px;
}
.techno-blog-meta-left span {
    color: #0d1218;
    margin-right: 40px;
}
.techno-blog-meta-left i {
    margin-right: 10px;
}
.blog_details_dtn_icon a i {
    border: 1px solid #e6e6e6;
    color: #565872;
    display: inline-block;
    font-size: 15px;
    height: 40px;
    line-height: 41px;
    margin: 0 9px 0 0;
    text-align: center;
    width: 40px;
    border-radius: 5px;
}
.blog_details_dtn_icon a i:hover {
    background: var(--primary);
    border-color: var(--primary);
    color: #fff;
}
.blog_dtl_content h3 {
    color: #0d1218;
    padding: 10px 0 20px;
    font-size: 24px;
    font-weight: 700;
}
.blog_details blockquote {
    margin: 37px 0 36px;
    font-size: 14px;
    border-left: 7px solid var(--primary);
    background: #f7fafd;
    font-style: italic;
    font-weight: 500;
    padding: 25px 35px 15px;
}
.signatures {
    text-align: right;
    font-weight: 600;
    font-style: italic;
    font-size: 15px;
}
/* POST REPLY CSS */
.blog_comments_section {
    padding: 38px 45px 45px;
    -webkit-box-shadow: 0 3px 19px rgba(0,0,0,.08);
    box-shadow: 0 3px 19px rgba(0,0,0,.08);
    border-radius: 0 0 10px 10px;
}
.comments_ttl>h3 {
    font-size: 24px;
    color: #0d1218;
    font-weight: 700;
}
.comments_ttl {
    margin-bottom: 37px;
}
.commentst_content p {
    color: #0d1218;
}
.comments_thumb {
    float: left;
    margin-right: 20px;
    overflow: hidden;
}
.commentst_content {
    overflow: hidden;
}
.blog_comments_section .post_meta {
    margin-bottom: 6px;
}
.blog_comments_section .post_meta span {
    font-weight: 400;
    padding-right: 15px;
    color: #6d6d6d;
}
.blog_comments_section .post_meta span:hover {
    color: #03a9f4;
}
.blog_comments_section .post_meta span:last-child:before {
    display: none;
}
.commentst_meta_reply {
    float: right;
}
.commentst_meta_reply i {
    margin-right: 10px;
}
.commentst_meta_reply:hover {
    color: #03a9f4;
}
.single_commentst_inner {
    margin-bottom: 44px;
    margin-top: 46px;
    padding-left: 63px;
}
.badmin i {
    margin-right: 3px;
}

/* COMMENT FORM CSS */
.blog_reply {
    overflow: hidden;
    margin-top: 50px;
    padding: 20px 15px 37px;
    box-shadow: 0 7px 16px rgba(0, 0, 0, 0.15);
}
.reply_ttl>h3 {
    font-size: 24px;
    color: #0d1218;
    font-weight: 700;
}
.reply_ttl {
    margin-bottom: 36px;
}
.blog_reply .em_contact_form {
    margin-bottom: 0;
}
.blog_reply .contact_bnt button:hover {
    background: #0D2352;
    color: #fff;
}
/* contact title css */
.request_form_title h2 {
    color: rgba(221, 221, 221, 0.79);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 47px 0 7px;
}
.request_form_title h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 40px;
    text-transform: uppercase;
    margin: 0;
}

/* techno Form Css */
.blog_reply_coment_dtl .form_box input {
    border: 1px solid #e6e6e6;
    color: #495057;
    background-color: #fff;
}
.blog_reply_coment_dtl .form_box textarea {
    border: 1px solid #e6e6e6;
    color: #495057;
    background-color: #fff;
}
.blog_reply_coment_dtl .quote_btn button {
    background: var(--primary);
    color:#fff;
}
.blog_reply_coment_dtl .form_box input:focus,
.blog_reply_coment_dtl .form_box textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}


.paginations {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 28px;
}
.page-numbers li {
    display: inline-block;
}
.paginations a, .page-numbers span.current {
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: auto 5px;
    border: 1px solid #ddd;
    color: #888;
}
.paginations a:hover, .paginations a.current, .page-numbers span.current {
    background: var(--primary);
    border-color: var(--primary);
    color: #fff;
}
.wedget_sideber_thumb img {
    width: 100%;
}
.widget_about_thumb{
    text-align:center;
}
.widget_about_content{
    text-align:center;
}
.widget_about_content h5 {
    font-weight: 700;
    padding-bottom: 3px;
}
.widget_about_icon{
    text-align:center;
}
.widget_about_icon a {
    display: inline-block;
    margin: 0 3px;
}


/*=============================
   techno Team Details Area Css
 =============================*/
.single_team_details {
    text-align: center;
    padding: 32px 0 18px;
    box-shadow: 0 10px 30px 0 rgba(17,12,79,.1);
    background:#fff;
    margin-bottom:30px;
}
.single_team_details_content_inner {
    padding: 10px 0 15px;
}
.single_team_details_content_inner h2 {
    font-size: 24px;
}
.single_team_details_content_inner span{
    color: var(--primary);
}
.single_team_details_icon a {
    font-size: 16px;
    background: var(--primary);
    height: 32px;
    width: 32px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    color: #fff;
    border-radius: 5px;
    margin:3px;
}
.single_team_details_phone {
    padding-top: 20px;
}
.single_team_details_phone_icon i {
    font-size: 30px;
    color: var(--primary);
}
.single_team_details_phone .number p {
    font-size: 18px;
}
.single_team_details_overview {
    margin-left: 30px;
}
.single_team_details_overview_content_text .overview_text {
    font-size: 18px;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 5px;
}

/* Team Style Two */
.team_style_two {
    position: relative;
    transition:.5s;
}
.team_style_two:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(12,90,219,0.85);
    content: "";
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-radius: 100%;
}
.team_style_two_thumb img {
    width: 100%;
    border-radius: 100%;
}
.team_style_two_content {
    position: absolute;
    left: 0;
    top: 62%;
    transform: translateY(-50%);
    text-align: center;
    right: 0;
    transition: .5s;
    transition-delay: .3s;
    opacity:0;
}
.team_style_two_title h5 {
    color: #fff;
    font-weight: 700;
    display:block;
    padding-bottom:5px;
}
.team_style_two_title span {
    color: #fff;
}
.team_style_two_icon a {
    display: inline-block;
    color: #fff;
    margin: 0 5px;
    font-size: 14px;
}
.team_style_two:hover .team_style_two_content{
    top: 50%;
    opacity:1;
}
.team_style_two:hover:before {
    opacity: 1;
}

/*team style three*/

/*team thumb*/
.single_team_thumb1 {
    position: relative;
    z-index: 1;
}

.single_team_thumb1:hover:before {
    width: 100%;
    left: 0;
}
.single_team_thumb1 img {
    width: 100%;
}
/*team content*/
.style-three .single_team_content {
    transition: .5s;
    background: #fff;
    padding: 12px 20px 30px;
    text-align: center;
}
.style-three .single_team_content span {
    color: var(--primary);
    padding-top: 2px;
    display: inline-block;
}
.single_team_icon1 {
    padding: 11px 0 0;
}
.single_team_icon1 a i {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #0056B3;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
    margin-right: 10px;
}


/*==========================
    techno vedio Area Css
 ===========================*/
.row.vedio-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    border-radius: 5px;
    padding: 50px 0 65px;
    position: relative;
    margin-bottom: -175px;
}
.techno-vedio-title h2 {
    font-size: 30px;
    color: #fff;
}
.techno-vedio-title p {
    color: #fff;
    padding: 20px 0 15px;
    line-height: 30px;
}


/*==========================
    techno faq Area Css
 ===========================*/
/*faq section*/

.faq-thumb {
    position: relative;
    left: -106px;
}
.faq-thumb img {
    width: 100%;
}

/*accordion*/
ul.accordion {
    height: 510px;
}
.accordion li {
    position: relative;
    list-style: none;
    padding: 2px 0px 15px;
}
.accordion li a {
    width: 100%;
    display: block;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    color: #232323;
}
.accordion li p {
    display: none;
    font-size: 16px;
    color: #828fa0;
    padding-top: 20px;
    margin: 0;
}
.accordion a:before {
    width: 2px;
    height: 12px;
    background: #232323;
    position: absolute;
    right: 43px;
    content: " ";
    top: 12px;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
}
.accordion a:after {
    width: 12px;
    height: 2px;
    background: #232323;
    position: absolute;
    right: 38px;
    content: " ";
    top: 17px;
    transition: all 0.2s ease-in-out;
}
.accordion a.active:after {
    transform: rotate(0deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background: #1E54E7 !important;
}
.accordion a.active:before{
    display: none;
}
.accordion a.active {
    color: #1E54E7 !important;
}
/* faq area style two*/

/*faq thumb*/
.up.faq-thumb {
    position: relative;
    left: -155px;
}
.up.faq-thumb img {
    width: inherit;
}
.up.faq-thumb .data_science_video1 {
    position: absolute;
    left: 52%;
    top: 28%;
}
/*accordion start*/
.tab_container {
    position: relative;
}
ul.accordion.upper {
    height: 365px;
}
.upper.accordion li {
    position: relative;
    list-style: none;
    padding: 2px 0px 22px;
}
.upper.accordion li:before {
    position: absolute;
    content: "";
    z-index: -1;
    right: 27px;
    top: 0px;
    width: 33px;
    height: 33px;
    line-height: 33px;
    border-radius: 50%;
    filter: drop-shadow(0px 3px 10.5px rgba(254,94,40,0.35));
    background-image: linear-gradient(0deg, #fd4f2f 0%, #ff9b07 100%);
}
.upper.accordion a:before {
    background: #fff;
}
.upper.accordion a:after {
    height: 2px;
    background: #fff;
}
.upper a.active:after {
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    background: #fff!important;
}
.upper a.active {
    color: #FF8812!important;
}
/*tab shape*/
.tab-shape {
    position: absolute;
    top: 0;
    right: -185px;
    animation: moveLeftBounce 3s linear infinite;
}
/*==========================
    techno Skill Area Css
 ===========================*/
.skill_area{
    padding: 80px 0 70px;
}
.our_skill{
    margin-bottom:30px;
}
.skill-wrapper h5 {
    font-size: 18px;
    padding: 15px 0 12px;
}

/*=========================
   techno New Update Css
 =========================*/
.call_do_thumb img {
    width: 100%;
}

/*images box*/

.element_blog_content h4 {
    font-size: 22px;
    margin: 0 0 10px;
}

/*list item*/

.section-sub-title h4 {
    font-size: 35px;
    border-bottom: 1px solid #DDDDDD;
    padding: 0 0 30px;
    margin-bottom: 40px;
}

.list-icon span {
    display: block;
}

.list-icon span i {
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: linear-gradient(131deg, #1129b9 0%, #2473fb 100%);
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    font-size: 15px;
}

.list-icon span h6 {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #232323;
}

.upper.list-icon span i {
    background: transparent;
    color: var(--primary);
    font-size: 20px;
}

.upper1 ul.accordion {
    height: 350px;
}

/*=========================
   techno Error Page Area Css
 =========================*/
.error_page_area {
    padding: 65px 0 98px;
}
.single_error_inner {
    text-align: center;
}
.single_error_inner h1 {
    font-size: 150px;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 30px;
}
.single_error_inner h1 span{
    color:var(--primary);
}
.single_error_inner h2 {
    padding-bottom: 16px;
    font-size: 28px;
}
.single_error_button {
    margin-top: 30px;
}
.single_error_button a {
    background: #fff;
    color: var(--primary);
    padding: 11px 26px;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;
    border:2px solid var(--primary);
}
.single_error_button a:before {
    position: absolute;
    content: "";
    top: 0px;
    height: 100%;
    left: -25%;
    width: 0%;
    background: var(--primary);
    transform: skew(50deg);
    transition-duration: .6s;
    z-index: -1;
}
.single_error_button a:hover {
    color: #fff;
}
.single_error_button a:hover:before {
    width: 180%;
}
.input-group.error-page form {
    width: 64%;
}
.input-group.error-page input:focus {
    border: 2px solid var(--primary) !important;
    background: transparent;
}
#scrollUp {
    background: var(--primary) none repeat scroll 0 0;
    bottom: 30px;
    color: #fff;
    font-size: 22px;
    height: 40px;
    line-height: 40px;
    right: 100px;
    text-align: center;
    width: 40px;
    border-radius: 50%;
}
.intro__bg,
.intro__bg canvas {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* bounce-animate */
.bounce-animate {
    animation-name: float-bob;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
.bounce-animate2 {
    animation-name: float-bob2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob2;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob2;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob2;
    -o-animation-duration: 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
.bounce-animate3 {
    animation-name: float-bob3;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob3;
    -moz-animation-duration: 4s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob3;
    -ms-animation-duration: 4s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob3;
    -o-animation-duration: 4s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
.bounce-animate4 {
    animation-name: float-bob4;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob4;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob4;
    -ms-animation-duration: 5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob4;
    -o-animation-duration: 5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
.bounce-animate5 {
    animation-name: float-bob5;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -moz-animation-name: float-bob5;
    -moz-animation-duration: 6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob5;
    -ms-animation-duration: 6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob5;
    -o-animation-duration: 6s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear; }
@-webkit-keyframes float-bob {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); }
    50% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px); }
    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); } }
@-webkit-keyframes float-bob2 {
    0% {
        -webkit-transform: translateY(-60px);
        transform: translateY(-60px); }
    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px); }
    100% {
        -webkit-transform: translateY(-60px);
        transform: translateY(-60px); } }
@-webkit-keyframes float-bob3 {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px); }
    50% {
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px); }
    100% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px); } }
@-webkit-keyframes float-bob4 {
    0% {
        -webkit-transform: translateY(-70px);
        transform: translateY(-70px); }
    50% {
        -webkit-transform: translateY(-35px);
        transform: translateY(-35px); }
    100% {
        -webkit-transform: translateY(-70px);
        transform: translateY(-70px); } }
@-webkit-keyframes float-bob5 {
    0% {
        -webkit-transform: translateY(-75px);
        transform: translateY(-75px); }
    50% {
        -webkit-transform: translateY(-35px);
        transform: translateY(-35px); }
    100% {
        -webkit-transform: translateY(-75px);
        transform: translateY(-75px); } }
@-webkit-keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}


/*Banner video animation*/
#spinnerbtn {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0.7;
    position: relative;
    height: 100px;
    width: 100px;
    background: none;
    border-radius: 100px;
    border-top: 10px solid var(--primary);
    border-bottom: 10px solid var(--primary);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    -webkit-animation: spin 10s infinite alternate linear, glow 5s infinite alternate linear;
    -moz-animation: spin 10s infinite alternate linear, glow 5s infinite alternate linear;
    -ms-animation: spin 10s infinite alternate linear, glow 5s infinite alternate linear;
    -o-animation: spin 10s infinite alternate linear, glow 5s infinite alternate linear;
    animation: spin 10s infinite alternate linear, glow 5s infinite alternate linear;
}

#spinnerbtn:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px;
    background: none;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: -1;
    border-radius: 115px;
    border: 15px solid var(--primary);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;

    -webkit-animation: spin2 5s infinite alternate linear, glow 3s infinite alternate linear;
    -moz-animation: spin2 5s infinite alternate linear, glow 3s infinite alternate linear;
    -ms-animation: spin2 5s infinite alternate linear, glow 3s infinite alternate linear;
    -o-animation: spin2 5s infinite alternate linear, glow 3s infinite alternate linear;
    animation: spin2 5s infinite alternate linear, glow 3s infinite alternate linear;
}
.white-color #spinnerbtn {
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.white-color #spinnerbtn:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px;
    background: none;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: -1;
    border-radius: 115px;
    border: 15px solid #fff;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}
.white-color .data_science_video_inner a i {
    color: #fff;
}


@keyframes "spin" {
    0% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-webkit-keyframes "spin" {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-ms-keyframes "spin" {
    0% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-o-keyframes "spin" {
    0% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    100% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes "spin2" {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spin2 {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes "spin2" {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-ms-keyframes "spin2" {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes "spin2" {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes "glow" {
    0% {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    50% {
        -webkit-box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary);
        box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary);
    }
    100% {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
@-moz-keyframes glow {
    0% {
        box-shadow: none;
    }
    50% {
        box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary), inset 10px -10px 30px var(--primary), inset -10px 10px 30px var(--primary);
    }
    100% {
        box-shadow: none;
    }

}
@-webkit-keyframes "glow" {
    0% {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    50% {
        -webkit-box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary), inset 10px -10px 30px var(--primary), inset -10px 10px 30px var(--primary);
        box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary), inset 10px -10px 30px var(--primary), inset -10px 10px 30px var(--primary);
    }
    100% {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

}
@-ms-keyframes "glow" {
    0% {
        box-shadow: none;
    }
    50% {
        box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary), inset 10px -10px 30px var(--primary), inset -10px 10px 30px var(--primary);
    }
    100% {
        box-shadow: none;
    }

}
@-o-keyframes "glow" {
    0% {
        box-shadow: none;
    }
    50% {
        box-shadow: 10px -10px 30px var(--primary), -10px 10px 30px var(--primary), inset 10px -10px 30px var(--primary), inset -10px 10px 30px var(--primary);
    }
    100% {
        box-shadow: none;
    }

}

.data_science_video1 {
    position: relative;
    left: 44%;
}
.data_science_video_inner1 a i {
    position: absolute;
    left: 52px;
    font-size: 32px;
    color: #fff;
    top: 50%;
    transform: translate(-50% , -50%);
    text-align: center;
}
.white-color1 #spinnerbtn {
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    border-left: 10px solid #00247E;
    border-right: 10px solid #00247E;
}
.white-color1 #spinnerbtn:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px;
    background: none;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: -1;
    border-radius: 115px;
    border: 15px solid #fff;
    border-top: 15px solid #00247E;
    border-bottom: 15px solid #00247F;
}









/*particles js*/
div#particles {
    position: relative;
}

#webcoderskull {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 20px;
    width: 100%;
}
div#particles .slider16 {
    background: inherit;
}
div#particles .slider18 {
    background: inherit;
}

